import { ref, set } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

export const setLogicGridCompletedTrue = (grid, gameCode) => {
  const dbRefGameCompleted = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room1/logicGrid${grid}/levelCompleted`,
  );
  set(dbRefGameCompleted, true);
};

export const setLogicGridResult = (grid, result, gameCode) => {
  const dbRefGameCompleted = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room1/logicGrid${grid}/result`,
  );
  set(dbRefGameCompleted, result);
};

export const setLogicGridScore = (grid, score, gameCode) => {
  const dbRefGameCompleted = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room1/logicGrid${grid}/score`,
  );
  set(dbRefGameCompleted, score);
};

export const setPlayerInControlFirebaseLogicGrid = (id, gameCode) => {
  const dbRefInControl = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room1/inControl`,
  );
  set(dbRefInControl, id);
};
