import React, { useRef, useState, useEffect } from "react";
import mainHUD from ".././../../assets/gameAssets/mainHUD.mp4";
import "../assets/css/style.css";

import { ref, set, onValue } from "firebase/database";
import { database } from "../../../firebase/firebaseConfig";

import {
  setLogicGridCompletedTrue,
  setLogicGridResult,
  setLogicGridScore,
  setPlayerInControlFirebaseLogicGrid,
} from "../LogicPuzzlesFirebase";

import { Redirect } from "react-router-dom";
import { checkGameCodeExists } from "../PuzzleUtils";
import InfoBtn from "../../infoBtn/infoBtn.component";
import { calculateLogicPuzzlePoints } from "../../../Utils/commonFunctions";

const Puzzle1 = () => {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const playerId = JSON.parse(localStorage.getItem("PLAYERID"));

  const [showControlUpdate, setshowControlUpdate] = useState(false);

  const [playerInControl, setplayerInControl] = useState("");

  const [allPlayers, setallPlayers] = useState([]);

  const [timerRed, settimerRed] = useState(false);

  const [logicGridOneScore, setlogicGridOneScore] = useState(0);

  const [gameWithoutCode, setgamewithoutCode] = useState(false);

  const [GameCompleted, setGameCompleted] = useState(false);

  // Puzzle Data
  const [situationReport] = useState({
    desc: `Spies, we sent five of our top Scientists on a trip to test new mission equipment. Each of the Scientists was paired with one of our Special Agents and flown to a hidden island off the coast of Morocco. Trouble is, their plane hit turbulence during landing and all the equipment got mixed up! We managed to recover the following intelligence but need you and your team to help us identify which Scientists and Special Agents were paired together, and which equipment they were responsible for.`,
    list: [
      "Scientists: Dr. Suvi, Dr. Marisa, Dr. Leon, Dr. Anwar, Dr. Martin​​",
      "Special Agents: Natalia, Zach, Cyrus, Tyson, Lydia​​",
      "Equipment: Smart Watch, Navigator, Thermal Detector, Transponder, Drone​​",
    ],
  });

  const [ourIntelligence] = useState([
    "The Spy with the Drone was neither Cyrus nor Zach​​.",
    "The male Spy that came with Dr. Marisa was to test the Navigator, and it wasn’t Tyson.",
    "Dr. Leon’s Spy is female, shares a first initial with him, and they were to test the Smart Watch.​​",
    `The Thermal Detector belongs to Dr. Anwar’s Spy, who is female.​​`,
    "Zach was to field test equipment with Dr. Martin.",
  ]);

  const [puzzleLabels] = useState({
    topLeft: ["Natalia", "Zach​​", "Cyrus", "Tyson", "Lydia"],
    topRight: [
      "Navigator",
      "Smart Watch",
      "Thermal Detector",
      "Transponder​",
      "Drone",
    ],
    left: ["Dr. Suvi", "Dr. Marisa", "Dr. Leon", "Dr. Anwar", "Dr. Martin"],
  });

  // Puzzle answers
  const [answers] = useState({
    answer1: ["Dr. Suvi", "Tyson", "Drone"],
    answer2: ["Dr. Marisa", "Cyrus", "Navigator"],
    answer3: ["Dr. Leon", "Lydia", "Smart Watch"],
    answer4: ["Dr. Anwar", "Natalia", "Thermal Detector"],
    answer5: ["Dr. Martin", "Zach​​", "Transponder​"],
  });

  // Refs of the ladels of the puzzle
  const puzzleAnswersRefs = useRef([]);

  // Interval ref
  const intervalRef = useRef();

  // number of move
  const movesHistory = useRef([]);

  // Puzzle Answers
  const [answersTable, setAnswersTable] = useState({
    answer1: [],
    answer2: [],
    answer3: [],
    answer4: [],
    answer5: [],
  });

  // initialise squares
  const initialSquaresValues = {
    C5B3: 1,
  };

  // Squares values
  const [squares, setSquares] = useState({});

  // confirm submit
  const [confirmSubmit, setConfirmSubmit] = useState({
    isComplete: false,
    showModal: false,
  });

  // Store submit result
  const [result, setResult] = useState(null);

  // Store the last checked square
  const [lastSquareChecked, setLastSquareChecked] = useState({
    id: null,
    crossedSquares: [],
  });

  const insertSquareMark = (id) => {
    // empty square
    if (squares[id] === 0) return null;

    // X square
    if (squares[id] === 1)
      return [
        <svg
          fill="none"
          stroke="#58caff"
          viewBox="0 0 24 24"
          key={`svg${id}`}
          className="square-x"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>,
      ];

    // Check mark square
    if (squares[id] === 2)
      return [
        <svg
          fill="none"
          stroke="#58caff"
          viewBox="0 0 24 24"
          key={`svg${id}`}
          className="square-check"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>,
      ];
  };

  const handleSquareClick = (e) => {
    const id = e.target.id;

    if (squares[id] === 0) {
      setSquares({
        ...squares,
        [id]: 1,
      });
      registerMove([id], "cross");
    } else if (squares[id] === 1) {
      const r = getCrossedSquaresIds(id);

      if (r) {
        setSquares({
          ...squares,
          [id]: 2,
        });

        r.forEach((cell) => {
          setSquares((prevState) => ({
            ...prevState,
            [cell]: 1,
          }));
        });

        let answerBeforeUpdate;
        // get answers
        if (id.charAt(0) === "A") {
          if (id.charAt(2) === "B") {
            puzzleAnswersRefs.current.forEach((elem1) => {
              //get label string
              if (elem1.id === `labelLeftA${id.charAt(1)}`) {
                // get child name
                puzzleAnswersRefs.current.forEach((elem2) => {
                  if (elem2.id === `labelTopB${id.charAt(3)}`) {
                    Object.keys(answersTable).forEach((answerNbr) => {
                      if (
                        answersTable[answerNbr][0].toLowerCase() ===
                        elem1.children[0].innerText.toLowerCase()
                      ) {
                        let answerToUpdate = [...answersTable[answerNbr]];
                        answerBeforeUpdate = {
                          [answerNbr]: [...answerToUpdate],
                        };
                        answerToUpdate[1] =
                          elem2.children[0].innerText.toLowerCase();
                        setAnswersTable((state) => ({
                          ...state,
                          [answerNbr]: [...answerToUpdate],
                        }));
                      }
                    });
                  }
                });
              }
            });
          }

          if (id.charAt(2) === "C") {
            puzzleAnswersRefs.current.forEach((elem1) => {
              //get mother name
              if (elem1.id === `labelLeftA${id.charAt(1)}`) {
                // get toy name
                puzzleAnswersRefs.current.forEach((elem2) => {
                  if (elem2.id === `labelTopC${id.charAt(3)}`) {
                    Object.keys(answersTable).forEach((answerNbr) => {
                      if (
                        answersTable[answerNbr][0].toLowerCase() ===
                        elem1.children[0].innerText.toLowerCase()
                      ) {
                        let answerToUpdate = [...answersTable[answerNbr]];
                        answerBeforeUpdate = {
                          [answerNbr]: [...answerToUpdate],
                        };
                        answerToUpdate[2] =
                          elem2.children[0].innerText.toLowerCase();
                        setAnswersTable((state) => ({
                          ...state,
                          [answerNbr]: [...answerToUpdate],
                        }));
                      }
                    });
                  }
                });
              }
            });
          }
        }
        registerMove(
          r,
          "check",
          { checkedSquareId: id },
          { answerBeforeUpdate: answerBeforeUpdate },
        );
      }
    } else {
      setSquares({
        ...squares,
        [id]: 0,
      });

      let affectedSquares = [id];
      if (id === lastSquareChecked.id) {
        // remove X marked squares
        if (lastSquareChecked.crossedSquares) {
          lastSquareChecked.crossedSquares.forEach((cell) => {
            affectedSquares.push(cell);
            setSquares((prevState) => ({
              ...prevState,
              [cell]: 0,
            }));
          });
        }
      }

      // Reset last square checked
      setLastSquareChecked({
        id: null,
        crossedSquares: [],
      });

      let answerBeforeUpdate;
      // reset answers
      if (id.charAt(0) === "A") {
        if (id.charAt(2) === "B") {
          puzzleAnswersRefs.current.forEach((elem1) => {
            if (elem1.id === `labelLeftA${id.charAt(1)}`) {
              Object.keys(answersTable).forEach((answerNbr) => {
                if (
                  answersTable[answerNbr][0].toLowerCase() ===
                  elem1.children[0].innerText.toLowerCase()
                ) {
                  let answerToUpdate = [...answersTable[answerNbr]];
                  answerBeforeUpdate = {
                    [answerNbr]: [...answerToUpdate],
                  };

                  answerToUpdate[1] = "";
                  setAnswersTable((state) => ({
                    ...state,
                    [answerNbr]: [...answerToUpdate],
                  }));
                }
              });
            }
          });
        }

        if (id.charAt(2) === "C") {
          puzzleAnswersRefs.current.forEach((elem1) => {
            if (elem1.id === `labelLeftA${id.charAt(1)}`) {
              Object.keys(answersTable).forEach((answerNbr) => {
                if (
                  answersTable[answerNbr][0].toLowerCase() ===
                  elem1.children[0].innerText.toLowerCase()
                ) {
                  let answerToUpdate = [...answersTable[answerNbr]];
                  answerBeforeUpdate = {
                    [answerNbr]: [...answerToUpdate],
                  };
                  answerToUpdate[2] = "";
                  setAnswersTable((state) => ({
                    ...state,
                    [answerNbr]: [...answerToUpdate],
                  }));
                }
              });
            }
          });
        }
      }

      registerMove(
        affectedSquares,
        "blank",
        null,
        { blankSquareId: id },
        { answerBeforeUpdate: answerBeforeUpdate },
      );
    }
  };

  const getCrossedSquaresIds = (id) => {
    const cell_x = id.slice(0, -1);
    let cell_y = id;
    let cellsToCross = [];

    for (let i = 1; i <= 5; i++) {
      if (id !== `${cell_x}${i}`) {
        if (squares[`${cell_x}${i}`] !== 2) {
          if (squares[`${cell_x}${i}`] === 0)
            cellsToCross.push(`${cell_x}${i}`);
        } else {
          cellsToCross = false;
          return false;
        }
      }

      cell_y = cell_y.split("");
      cell_y[1] = i;
      cell_y = cell_y.join("");

      if (id !== cell_y) {
        if (squares[`${cell_y}`] !== 2) {
          if (squares[`${cell_y}`] === 0) cellsToCross.push(cell_y);
        } else {
          cellsToCross = false;
          return false;
        }
      }
    }

    setLastSquareChecked({
      id,
      crossedSquares: cellsToCross,
    });

    return cellsToCross;
  };

  const squareHoverOn = (e) => {
    let id = e.target.id;

    // Highlight Adjacent Labels
    if (id.charAt(0) === "A") {
      if (id.charAt(2) === "B" || id.charAt(2) === "C") {
        puzzleAnswersRefs.current.forEach((elem) => {
          if (elem.id === `labelLeftA${id.charAt(1)}`) {
            elem.className = `${elem.className} puzzle-label--hover`;
          }
        });
      }

      if (id.charAt(2) === "B" || id.charAt(2) === "C") {
        puzzleAnswersRefs.current.forEach((elem) => {
          if (elem.id === `labelTop${id.charAt(2)}${id.charAt(3)}`) {
            elem.className = `${elem.className} puzzle-label--hover`;
          }
        });
      }
    }

    if (id.charAt(0) === "C") {
      puzzleAnswersRefs.current.forEach((elem) => {
        if (elem.id === `labelTopB${id.charAt(3)}`) {
          elem.className = `${elem.className} puzzle-label--hover`;
        }
        if (elem.id === `labelLeftC${id.charAt(1)}`) {
          elem.className = `${elem.className} puzzle-label--hover`;
        }
      });
    }
  };

  const squareHoverOut = (e) => {
    let id = e.target.id;

    if (id.charAt(0) === "A") {
      if (id.charAt(2) === "B" || id.charAt(2) === "C") {
        puzzleAnswersRefs.current.forEach((elem) => {
          if (elem.id === `labelLeftA${id.charAt(1)}`) {
            elem.className = elem.className.replace(" puzzle-label--hover", "");
          }
        });
      }

      if (id.charAt(2) === "B" || id.charAt(2) === "C") {
        puzzleAnswersRefs.current.forEach((elem) => {
          if (elem.id === `labelTop${id.charAt(2)}${id.charAt(3)}`) {
            elem.className = elem.className.replace(" puzzle-label--hover", "");
          }
        });
      }
    }

    if (id.charAt(0) === "C") {
      puzzleAnswersRefs.current.forEach((elem) => {
        if (elem.id === `labelTopB${id.charAt(3)}`) {
          elem.className = elem.className.replace(" puzzle-label--hover", "");
        }
        if (elem.id === `labelLeftC${id.charAt(1)}`) {
          elem.className = elem.className.replace(" puzzle-label--hover", "");
        }
      });
    }
  };

  const generateSquares = (squaresPosition) => {
    let grid = [];
    if (squaresPosition === "topLeft") {
      for (let i = 1; i <= 5; i++) {
        for (let j = 1; j <= 5; j++) {
          grid.push(
            <div
              onClick={handleSquareClick}
              onMouseOver={squareHoverOn}
              onMouseOut={squareHoverOut}
              id={`A${i}B${j}`}
              key={`A${i}B${j}`}
            >
              {insertSquareMark(`A${i}B${j}`)}
            </div>,
          );
        }
      }
    }

    if (squaresPosition === "topRight") {
      for (let i = 1; i <= 5; i++) {
        for (let j = 1; j <= 5; j++) {
          grid.push(
            <div
              onClick={handleSquareClick}
              onMouseOver={squareHoverOn}
              onMouseOut={squareHoverOut}
              id={`A${i}C${j}`}
              key={`A${i}C${j}`}
            >
              {insertSquareMark(`A${i}C${j}`)}
            </div>,
          );
        }
      }
    }

    if (squaresPosition === "bottomLeft") {
      for (let i = 1; i <= 5; i++) {
        for (let j = 1; j <= 5; j++) {
          grid.push(
            <div
              onClick={handleSquareClick}
              onMouseOver={squareHoverOn}
              onMouseOut={squareHoverOut}
              id={`C${i}B${j}`}
              key={`C${i}B${j}`}
            >
              {insertSquareMark(`C${i}B${j}`)}
            </div>,
          );
        }
      }
    }
    return grid;
  };

  const handleSubmit = () => {
    calculateScore();

    closeMsg();

    let isComplete = true;

    for (const item in answersTable) {
      if (answersTable[item][1] === "" || answersTable[item][2] === "") {
        isComplete = false;
      }
    }

    setConfirmSubmit({
      isComplete,
      showModal: true,
    });
  };

  const finalizeSubmit = () => {
    let correct = true;
    setConfirmSubmit({
      ...confirmSubmit,
      showModal: false,
    });

    Object.keys(answersTable).forEach((key) => {
      if (
        answersTable[key][0].toLowerCase() === answers[key][0].toLowerCase()
      ) {
        if (
          answersTable[key][1].toLowerCase() !==
            answers[key][1].toLowerCase() ||
          answersTable[key][2].toLowerCase() !== answers[key][2].toLowerCase()
        ) {
          correct = false;
        }
      }
    });

    if (correct) {
      setLogicGridResult(1, true, gameCode);
    } else {
      setLogicGridResult(1, false, gameCode);
    }
  };

  const cancelSubmit = () => {
    setConfirmSubmit({
      ...confirmSubmit,
      showModal: false,
    });
  };

  const handleReset = () => {
    //reset squares values
    initializeSquaresValues();

    //reset answers table
    Object.keys(answers).forEach((key) => {
      setAnswersTable((state) => ({
        ...state,
        [key]: [answers[key][0], "", ""],
      }));
    });

    //reset result
    setLogicGridResult(1, null, gameCode);
  };

  const initializeSquaresValues = () => {
    ["A", "C"].forEach((item) => {
      for (let i = 1; i <= 5; i++) {
        for (let j = 1; j <= 5; j++) {
          if (item === "A") {
            setSquares((s) => ({
              ...s,
              [`${item}${i}B${j}`]: 0,
            }));

            setSquares((s) => ({
              ...s,
              [`${item}${i}C${j}`]: 0,
            }));
          }

          if (item === "C") {
            setSquares((s) => ({
              ...s,
              [`${item}${i}B${j}`]: 0,
            }));
          }
        }
      }
    });

    setSquares((state) => ({
      ...state,
      ...initialSquaresValues,
    }));
  };

  const closeMsg = () => {
    setLogicGridResult(1, null, gameCode);
    clearInterval(intervalRef.current);
  };

  const registerMove = (
    ids,
    moveType,
    checkedSquareId,
    blankSquareId,
    answerBeforeUpdate,
  ) => {
    movesHistory.current.push({
      squaresIds: [...ids],
      moveType,
      ...checkedSquareId,
      ...blankSquareId,
      ...answerBeforeUpdate,
    });
  };

  const undoMove = () => {
    if (movesHistory.current.length > 0) {
      let latestMove = movesHistory.current[movesHistory.current.length - 1];
      if (latestMove.moveType === "cross") {
        let squareId = latestMove.squaresIds[0];
        setSquares((state) => ({
          ...state,
          [squareId]: 0,
        }));
      }

      if (latestMove.moveType === "check") {
        let checkedSquareId = latestMove.checkedSquareId;
        let crossedSquaresIds = latestMove.squaresIds;

        crossedSquaresIds.forEach((square) => {
          setSquares((state) => ({
            ...state,
            [square]: 0,
          }));
        });

        setSquares((state) => ({
          ...state,
          [checkedSquareId]: 1,
        }));

        // update answers table
        setAnswersTable((state) => ({
          ...state,
          ...latestMove.answerBeforeUpdate,
        }));
      }

      if (latestMove.moveType === "blank") {
        // check square
        setSquares((state) => ({
          ...state,
          [latestMove.blankSquareId]: 2,
        }));
        // add cross squares
        latestMove.squaresIds.forEach((squareId) => {
          if (squareId !== latestMove.blankSquareId) {
            setSquares((state) => ({
              ...state,
              [squareId]: 1,
            }));
          }
        });
        // Reset answers table
        setAnswersTable((state) => ({
          ...state,
          ...latestMove.answerBeforeUpdate,
        }));
      }
      movesHistory.current.splice(-1, 1);
    }
  };

  useEffect(() => {
    // initialize the squares'values
    initializeSquaresValues();

    puzzleAnswersRefs.current = new Array(20);

    // set the first col of the answers table
    Object.keys(answers).forEach((key) => {
      setAnswersTable((state) => ({
        ...state,
        [key]: [answers[key][0], "", ""],
      }));
    });
  }, []);

  // ********************************************  firebase multiplyer

  // give control Update
  useEffect(() => {
    setshowControlUpdate(true);
    setTimeout(() => {
      setshowControlUpdate(false);
    }, 2000);
  }, [playerInControl]);

  useEffect(() => {
    if (!checkGameCodeExists(gameCode)) {
      setgamewithoutCode(true);
      return;
    }

    const dbRefAnswers = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/answers`,
    );

    // Write answers table data
    set(dbRefAnswers, {
      ...answersTable,
    });
  }, [answersTable]);

  useEffect(() => {
    if (!checkGameCodeExists(gameCode)) {
      setgamewithoutCode(true);
      return;
    }

    const dbRefSquares = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room1/logicGrid1/squares`,
    );

    // Write squares data
    set(dbRefSquares, {
      ...squares,
    });
  }, [squares]);

  useEffect(() => {
    const dbRefAnswers = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/answers`,
    );
    const dbRefSquares = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/squares`,
    );
    const dbRefGameCompleted = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/levelCompleted`,
    );
    const dbRefGameResult = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/result`,
    );
    const dbRefLogicGridOneScore = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/score`,
    );
    const dbRefInControl = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/inControl`,
    );
    const dbRefPlayersJoined = ref(database, `Rooms/${gameCode}/players`);

    // Read answers table data
    onValue(dbRefAnswers, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setAnswersTable(data);
      }
    });

    // Read squares data
    onValue(dbRefSquares, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setSquares(data);
      }
    });

    //  Read game completed or not
    onValue(dbRefGameCompleted, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGameCompleted(true);
      }
    });

    // syncs the grid result with firebase
    onValue(dbRefGameResult, (snapshot) => {
      const data = snapshot.val();
      setResult(data);
    });

    // syncs the grid score with firebase
    onValue(dbRefLogicGridOneScore, (snapshot) => {
      const data = snapshot.val();
      setlogicGridOneScore(data);
    });

    // checks who is in control
    onValue(dbRefInControl, (snapshot) => {
      const data = snapshot.val();
      setplayerInControl(data);
    });

    // get All players
    onValue(dbRefPlayersJoined, (snapshot) => {
      setallPlayers(snapshot.val());
    });
  }, []);

  const getControl = () => {
    // check if player id in null as for warden
    if (playerId) setPlayerInControlFirebaseLogicGrid(playerId, gameCode);
  };

  // ********************************************  firebase multiplyer

  const calculateScore = () => {
    let score = 0;

    Object.keys(answersTable).forEach((key) => {
      if (answersTable[key][1] === answers[key][1].toLowerCase()) score += 1;
      if (answersTable[key][2] === answers[key][2].toLowerCase()) score += 1;
    });

    setLogicGridScore(1, score, gameCode);
  };

  // timer
  useEffect(() => {
    const start = new Date().getTime() / 1000;
    const secondsTimer = setInterval(() => {
      const currentTime = 600 - Math.round(new Date().getTime() / 1000 - start);
      const minutes = ~~((currentTime % 3600) / 60);
      const secs = ~~currentTime % 60;

      // makes timer red
      if (currentTime < 60) {
        if (!timerRed) settimerRed(true);
      }
      if (document.querySelector(".puzzle__timerGridOne"))
        document.querySelector(".puzzle__timerGridOne").textContent =
          `${minutes > 0 ? `${minutes} mins` : ""} ${secs > 0 || minutes > 0 ? `${secs} secs remaining` : ""}`;
    }, 1000);

    return () => clearInterval(secondsTimer);
  }, []);

  // for auto Submit

  const inputRef1 = React.useRef(null);
  const inputRef2 = React.useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simulates click on handle submit
      inputRef1.current.click();
    }, 600000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simulates click on finalise submit
      inputRef2.current.click();
    }, 600500);
    return () => clearTimeout(timer);
  }, []);

  if (GameCompleted) {
    return <Redirect to="/nextPuzzleChoice" />;
  }

  if (gameWithoutCode) {
    return <Redirect to="/isa" />;
  }

  return (
    <>
      <InfoBtn />

      {/* permanent Control info */}
      {playerId !== "" ? (
        <div className="inControInfo">
          {playerInControl === playerId || allPlayers === null ? (
            <h1> YOU ARE IN CONTROL</h1>
          ) : (
            <h1>
              {" "}
              {allPlayers[playerInControl - 1]
                ? allPlayers[playerInControl - 1]
                : ""}{" "}
              IS IN CONTROL
            </h1>
          )}
        </div>
      ) : (
        ""
      )}

      {/* control update */}
      {showControlUpdate ? (
        <div className="controlUpdate">
          {playerInControl === playerId || allPlayers === null ? (
            <h1> YOU ARE IN CONTROL</h1>
          ) : (
            <h1>
              {" "}
              {allPlayers[playerInControl - 1]
                ? allPlayers[playerInControl - 1]
                : ""}{" "}
              IS IN CONTROL
            </h1>
          )}
        </div>
      ) : (
        ""
      )}

      {/* result */}
      {typeof result === "boolean" ? (
        <>
          <div className="result__tableOverlay"></div>
          <div className="result__table">
            <h1 className="result__tableScore">{`You Scored ${logicGridOneScore} / 10`}</h1>
            <h2 className="result__puzzlePoints">
              {calculateLogicPuzzlePoints(10, logicGridOneScore, 50)} Points
            </h2>
            <div className="answers-table">
              <div className="answers-table__col">
                {Object.keys(answersTable).map((key, i) => (
                  <div
                    className="answers-table__cell answers-table__cell-r"
                    key={`col1${i}`}
                  >
                    <div className="center">{answersTable[key][0]}</div>
                  </div>
                ))}
              </div>
              <div className="answers-table__col">
                {Object.keys(answersTable).map((key, i) => (
                  <div
                    className={`answers-table__cell answers-table__cell-d ${answersTable[key][1] === answers[key][1].toLowerCase() ? `answerCell__correct` : "answerCell__wrong"}`}
                    key={`col2${i}`}
                  >
                    <div className="center">{answersTable[key][1]}</div>
                  </div>
                ))}
              </div>
              <div className="answers-table__col">
                {Object.keys(answersTable).map((key, i) => (
                  <div
                    className={`answers-table__cell answers-table__cell-d ${answersTable[key][2] === answers[key][2].toLowerCase() ? `answerCell__correct` : "answerCell__wrong"}`}
                    key={`col3${i}`}
                  >
                    <div className="center">{answersTable[key][2]}</div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="nextPuzzle"
              onClick={() => {
                setLogicGridCompletedTrue(1, gameCode);
              }}
            >
              PROCEED
            </div>
          </div>
        </>
      ) : null}

      {/* result finish */}

      <video autoPlay muted loop id="HUDvideo">
        <source src={mainHUD} type="video/mp4" />
      </video>

      {/* timer  */}

      <h1
        className={`puzzle__timerGridOne ${timerRed ? "puzzle__timerRed" : ""}`}
      >
        {}
      </h1>

      <div className="puzzle-page">
        <div className="puzzle-container" id="puzzle">
          <div className="puzzle">
            <div className="puzzle__section grid-item--1">
              <div className="subsection subsection-txt">
                <div className="subsection__content">
                  <h2 className="heading--2 heading--2--underline">
                    Situation Report
                  </h2>
                  <p className="text">{situationReport.desc}</p>
                  <ul className="list list--margin-top">
                    {situationReport.list.map((item, i) => (
                      <li key={`list1${i}`}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="subsection__content">
                  <h2 className="heading--2 heading--2--underline">
                    Our Intelligence
                  </h2>
                  <ol className="list list--padding-top">
                    {ourIntelligence.map((item, i) => (
                      <li key={`list2${i}`}>{item}</li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>

            <div className="puzzle__section grid-item--3">
              <div className="subsection">
                <div className="puzzle-grid">
                  <div className="puzzle__item--empty"></div>
                  <div className="puzzle__item--h">
                    {puzzleLabels.topLeft.map((item, index) => (
                      <div
                        id={`labelTopB${index + 1}`}
                        key={`labelTopB${index + 1}`}
                        ref={(el) => (puzzleAnswersRefs.current[index] = el)}
                        className="puzzle__data--h"
                      >
                        <div className="center">{item}</div>
                      </div>
                    ))}
                  </div>
                  <div className="puzzle__item--h">
                    {puzzleLabels.topRight.map((item, index) => (
                      <div
                        id={`labelTopC${index + 1}`}
                        key={`labelTopC${index + 1}`}
                        ref={(el) =>
                          (puzzleAnswersRefs.current[index + 5] = el)
                        }
                        className="puzzle__data--h"
                      >
                        <div className="center">{item}</div>
                      </div>
                    ))}
                  </div>
                  <div className="puzzle__item--v">
                    {puzzleLabels.left.map((item, index) => (
                      <div
                        id={`labelLeftA${index + 1}`}
                        key={`labelLeftA${index + 1}`}
                        ref={(el) =>
                          (puzzleAnswersRefs.current[index + 10] = el)
                        }
                        className="puzzle__data--v"
                      >
                        <div className="center">{item}</div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="puzzle__item"
                    style={{
                      pointerEvents: `${playerInControl === playerId || playerId === null ? "auto" : "none"}`,
                    }}
                  >
                    <div className="squares">{generateSquares("topLeft")}</div>
                  </div>
                  <div
                    className="puzzle__item"
                    style={{
                      pointerEvents: `${playerInControl === playerId || playerId === null ? "auto" : "none"}`,
                    }}
                  >
                    <div className="squares">{generateSquares("topRight")}</div>
                  </div>
                  <div className="puzzle__item--v">
                    {puzzleLabels.topRight.map((item, index) => (
                      <div
                        id={`labelLeftC${index + 1}`}
                        key={`labelLeftC${index + 1}`}
                        ref={(el) =>
                          (puzzleAnswersRefs.current[index + 15] = el)
                        }
                        className="puzzle__data--v"
                      >
                        <div className="center">{item}</div>
                      </div>
                    ))}
                  </div>
                  <div
                    className="puzzle__item"
                    style={{
                      pointerEvents: `${playerInControl === playerId || playerId === null ? "auto" : "none"}`,
                    }}
                  >
                    <div className="squares">
                      {generateSquares("bottomLeft")}
                    </div>
                  </div>
                  <div className="puzzle__btn-container">
                    <div className="puzzle__btn">
                      <button
                        className="btn primary-btn"
                        onClick={handleReset}
                        type="button"
                        style={{
                          pointerEvents: `${playerInControl === playerId || playerId === null ? "auto" : "none"}`,
                        }}
                      >
                        Reset
                      </button>
                      <button
                        ref={inputRef1}
                        className="btn primary-btn"
                        onClick={handleSubmit}
                        type="button"
                        style={{
                          pointerEvents: `${playerInControl === playerId || playerId === null ? "auto" : "none"}`,
                        }}
                      >
                        Submit
                      </button>
                    </div>

                    <div className="puzzle__btn">
                      <button
                        className="btn primary-btn"
                        onClick={undoMove}
                        type="button"
                        style={{
                          pointerEvents: `${playerInControl === playerId || playerId === null ? "auto" : "none"}`,
                        }}
                      >
                        Undo
                      </button>
                      <button
                        className="btn primary-btn"
                        onClick={getControl}
                        type="button"
                      >
                        Control
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="puzzle__section grid-item--4">
              <div className="subsection">
                <h2 className="heading--2 heading--2--underline">Answers</h2>
                <div className="answers-table">
                  <div className="answers-table__col">
                    {Object.keys(answersTable).map((key, i) => (
                      <div
                        className="answers-table__cell answers-table__cell-r"
                        key={`col1${i}`}
                      >
                        <div className="center">{answersTable[key][0]}</div>
                      </div>
                    ))}
                  </div>
                  <div className="answers-table__col">
                    {Object.keys(answersTable).map((key, i) => (
                      <div
                        className="answers-table__cell answers-table__cell-d"
                        key={`col2${i}`}
                      >
                        <div className="center">{answersTable[key][1]}</div>
                      </div>
                    ))}
                  </div>
                  <div className="answers-table__col">
                    {Object.keys(answersTable).map((key, i) => (
                      <div
                        className="answers-table__cell answers-table__cell-d"
                        key={`col3${i}`}
                      >
                        <div className="center">{answersTable[key][2]}</div>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className={`message ${confirmSubmit.showModal ? "" : "hideComponent"}`}
                >
                  <div className="message_header">
                    <h1> Submit Answer? </h1>
                  </div>
                  <div className="message_body">
                    <div className="message__text">
                      {confirmSubmit.isComplete
                        ? "ARE YOU SURE YOU WANT TO SUBMIT THE QUIZ? ALL SUBMISSIONS ARE FINAL!"
                        : "THE PUZZLE IS INCOMPLETE, DO YOU STILL WANT TO SUBMIT?"}
                    </div>
                    <div className="puzzle__btn-container">
                      <button
                        className="message-btn"
                        onClick={cancelSubmit}
                        type="button"
                      >
                        Cancel
                      </button>
                      <button
                        ref={inputRef2}
                        className="message-btn submit-message-btn"
                        onClick={finalizeSubmit}
                        type="button"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Puzzle1;
