import { onValue, ref } from "@firebase/database";
import React, { useEffect, useState } from "react";

import "./roomThreeResult.page.styles.scss";

import {
  setShowEndingVideo,
  setTotalTimeBonusFirebase,
  setTotalTimeTakenFirebase,
} from "../../firebase/firebase";
import { database } from "../../firebase/firebaseConfig";

import { Redirect } from "react-router";
import InfoBtn from "../../components/infoBtn/infoBtn.component";

function RoomThreeResult() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  // Global
  const [gameStartTime, setgameStartTime] = useState(0);
  const [gameFinishTime, setgameFinishTime] = useState(0);
  const [roomOneTotal, setroomOneTotal] = useState(0);
  const [roomTwoTotal, setroomTwoTotal] = useState(0);
  const [roomThreeTotal, setroomThreeTotal] = useState(0);
  const [timeElapsedTotal, settimeElapsedTotal] = useState(0);
  const [playerPass, setplayerPass] = useState(0);

  const [showPassVideo, setshowPassVideo] = useState(false);
  const [showFailVideo, setshowFailVideo] = useState(false);

  const [round1Score, setround1Score] = useState(0);
  const [round1Distance, setround1Distance] = useState(0);
  const [showRound1Result, setshowRound1Result] = useState(false);

  const [round2Score, setround2Score] = useState(0);
  const [round2Distance, setround2Distance] = useState(0);
  const [showRound2Result, setshowRound2Result] = useState(false);

  const [round3Score, setround3Score] = useState(0);
  const [round3Distance, setround3Distance] = useState(0);
  const [showRound3Result, setshowRound3Result] = useState(false);

  const [round4Score, setround4Score] = useState(0);
  const [round4Distance, setround4Distance] = useState(0);
  const [showRound4Result, setshowRound4Result] = useState(false);

  const [round5Score, setround5Score] = useState(0);
  const [round5Distance, setround5Distance] = useState(0);
  const [showRound5Result, setshowRound5Result] = useState(false);

  const [showTotal, setshowTotal] = useState(false);

  const [showButton, setshowButton] = useState(false);

  useEffect(() => {
    const dbRefResult = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/result`,
    );

    const dbRefGameStartTime = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/startTime`,
    );

    const dbRefGameFinishTime = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/finishTime`,
    );

    const dbRefRoomOneTotal = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/totalScore`,
    );
    const dbRefRoomTwoTotal = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/totalScore`,
    );
    const dbRefRoomThreeTotal = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/totalScore`,
    );

    const dbRefPassVideo = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/showPassVideo`,
    );
    const dbRefFailVideo = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/showFailVideo`,
    );

    //
    onValue(dbRefResult, (snapshot) => {
      const data = snapshot.val();

      setround1Score(data.round1.points);
      setround2Score(data.round2.points);
      setround3Score(data.round3.points);
      setround4Score(data.round4.points);
      setround5Score(data.round5.points);

      setround1Distance(Math.floor(data.round1.distances));
      setround2Distance(Math.floor(data.round2.distances));
      setround3Distance(Math.floor(data.round3.distances));
      setround4Distance(Math.floor(data.round4.distances));
      setround5Distance(Math.floor(data.round5.distances));
    });

    onValue(dbRefGameStartTime, (snapshot) => {
      const data = snapshot.val();
      setgameStartTime(data);
    });

    onValue(dbRefGameFinishTime, (snapshot) => {
      const data = snapshot.val();
      setgameFinishTime(data);
    });

    onValue(dbRefRoomOneTotal, (snapshot) => {
      const data = snapshot.val();
      setroomOneTotal(data);
    });

    onValue(dbRefRoomTwoTotal, (snapshot) => {
      const data = snapshot.val();
      setroomTwoTotal(data);
    });

    onValue(dbRefRoomThreeTotal, (snapshot) => {
      const data = snapshot.val();
      setroomThreeTotal(data);
    });

    onValue(dbRefPassVideo, (snapshot) => {
      const data = snapshot.val();
      setshowPassVideo(data);
    });

    onValue(dbRefFailVideo, (snapshot) => {
      const data = snapshot.val();
      setshowFailVideo(data);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowButton(true);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowTotal(true);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRound5Result(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRound4Result(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRound3Result(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRound2Result(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRound1Result(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    //  final Time Calculations

    let TimeBonus = 0;

    const timeTaken = Math.floor(gameFinishTime - gameStartTime);

    setTotalTimeTakenFirebase(gameCode, timeTaken);

    if (timeTaken > 3600) {
      TimeBonus = 0;
    } else {
      TimeBonus = 3600 - timeTaken;
    }

    settimeElapsedTotal(TimeBonus);
    setTotalTimeBonusFirebase(gameCode, TimeBonus);
  }, [gameFinishTime, gameStartTime]);

  useEffect(() => {
    // final Score calculations
    const overallScore =
      roomOneTotal + roomTwoTotal + roomThreeTotal + timeElapsedTotal;

    if (overallScore >= 360) {
      setplayerPass(true);
    } else {
      setplayerPass(false);
    }
  }, [timeElapsedTotal, roomOneTotal, roomTwoTotal, roomThreeTotal]);

  if (showPassVideo) {
    return <Redirect to="/pv34124" />;
  }

  if (showFailVideo) {
    return <Redirect to="/fv2315" />;
  }

  return (
    <>
      <InfoBtn />
      <div className="roomThreeResult">
        <div className="roomThreeResult__left"></div>
        <div className="roomThreeResult__right">
          <h1>STAGE 3 SCORING SUMMARY</h1>
          <div className="resultTable">
            <div className="row row1">
              {showRound1Result ? (
                <>
                  <div className="col col1">Location 1 (Tokyo)</div>
                  <div className="col col2">{round1Distance} KM</div>
                  <div className="col col3">{round1Score} Points</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row2">
              {showRound2Result ? (
                <>
                  <div className="col col1">Location 2 (Kenya)</div>
                  <div className="col col2">{round2Distance} KM</div>
                  <div className="col col3">{round2Score} Points</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row3">
              {showRound3Result ? (
                <>
                  <div className="col col1">Location 3 (Chile)</div>
                  <div className="col col2">{round3Distance} KM</div>
                  <div className="col col3">{round3Score} Points</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row4">
              {showRound4Result ? (
                <>
                  <div className="col col1">Location 4 (Cambodia)</div>
                  <div className="col col2">{round4Distance} KM</div>
                  <div className="col col3">{round4Score} Points</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row5">
              {showRound5Result ? (
                <>
                  <div className="col col1">Location 5 (Finland)</div>
                  <div className="col col2">{round5Distance} KM</div>
                  <div className="col col3">{round5Score} Points</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="row row6">
              {showTotal ? (
                <>
                  <div className="col col1">Total</div>
                  <div className="col col2"></div>
                  <div className="col col3">
                    {round1Score +
                      round2Score +
                      round3Score +
                      round4Score +
                      round5Score}{" "}
                    Points
                  </div>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {showButton ? (
            <>
              {playerPass ? (
                <button
                  className="resultTable__Btn"
                  onClick={() => {
                    setShowEndingVideo(gameCode, "Pass");
                  }}
                >
                  Escape
                </button>
              ) : (
                <button
                  className="resultTable__Btn"
                  onClick={() => {
                    setShowEndingVideo(gameCode, "Fail");
                  }}
                >
                  Escape
                </button>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default RoomThreeResult;
