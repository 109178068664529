import React, { useState, useEffect, useReducer } from "react";

import { setRoomTwoFinishedTrue } from "../../firebase/firebase";
import InfoBtn from "../infoBtn/infoBtn.component";
import "./assets/css/style.css";

const KeypadPuzzle = () => {
  //  parse gameCode from local Storage
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const keyNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
  const initialState = "";
  const [input, dispatch] = useReducer(reducer, initialState);
  const [correctCode] = useState("7686");

  function reducer(state, action) {
    let newState;
    switch (action.type) {
      case "ADD":
        if (state.length < 7) {
          if (!state) {
            newState = action.payload;
            break;
          }
          newState = `${state}-${action.payload}`;
          break;
        }
        newState = state;
        break;
      case "DELETE":
        if (state) {
          if (state.length > 1) {
            newState = state.slice(0, -2);
            break;
          }
        }
        newState = "";
        break;
      case "RESULT":
        if (action.payload.input.length === 4) {
          if (action.payload.input === action.payload.answer) {
            // completes the room2
            setRoomTwoFinishedTrue(gameCode);
            newState = "O-P-E-N";
            break;
          }
        }
        newState = "#-E-R-R";
        break;
      default:
        newState = state;
    }
    return newState;
  }

  const handleKeyClick = (e) => {
    const keyNumber = e.target.getAttribute("key-number");
    dispatch({
      type: "ADD",
      payload: keyNumber,
    });
  };

  const handleDelete = () => {
    dispatch({
      type: "DELETE",
    });
  };

  const handleEnter = () => {
    let inputCode = input.split("-").join("");
    dispatch({
      type: "RESULT",
      payload: {
        input: inputCode,
        answer: correctCode,
      },
    });
  };

  const handleKeydown = (e) => {
    const keyCode = e.keyCode;
    if (keyCode >= 96 && keyCode <= 105) {
      let keyNumber = keyCode - 96;
      keyNumber = keyNumber.toString();

      dispatch({
        type: "ADD",
        payload: keyNumber,
      });
    }

    if (keyCode === 8) {
      dispatch({
        type: "DELETE",
      });
    }

    if (keyCode === 13) {
      handleEnter();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, []);

  return (
    <>
      <InfoBtn />
      <div className="keypad-puzzle">
        <div className="keypad-wrap">
          <div className="keypad">
            <div className="keypad-container">
              <div className="keypad__screen">
                <div className="keypad__input">{input}</div>
              </div>
              <div className="keypad__keys">
                <div className="keypad__numbers">
                  {keyNumbers.map((keyNumber) => (
                    <button
                      key={`keyNumber-${keyNumber}`}
                      className={
                        keyNumber === 0
                          ? "keypad__key keypad__key--last-key"
                          : "keypad__key"
                      }
                      type="button"
                      key-number={keyNumber}
                      onClick={handleKeyClick}
                    >
                      <div className="number">{keyNumber}</div>
                    </button>
                  ))}
                </div>
              </div>
              <div className="keypad__options">
                <button
                  className="keypad__option"
                  type="button"
                  onClick={handleEnter}
                >
                  <div>
                    <div>Enter</div>
                    <div>
                      <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </div>
                </button>
                <button
                  className="keypad__option"
                  type="button"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeypadPuzzle;
