import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import fillDataToSheet from "../../firebase/DataToSheet";

import "./dataToSheet.styles.scss";
import toast, { Toaster } from "react-hot-toast";

import GLOGO from "../../assets/gameAssets/g-logo.png";

const API_URL =
  "https://sheets.googleapis.com/v4/spreadsheets/1B5mmhzO-7jH0PMN1mflDk-MBZ9Ng0-Ubcn6cxIBhw58/values/Data To Store!A1:ZZ1000?valueInputOption=USER_ENTERED";

function DataToSheet() {
  const [loading, setloading] = useState(false);

  const [accessToken, setaccessToken] = useState(null);

  async function fillDateToSheet() {
    setloading(true);

    const values = await fillDataToSheet();

    const columns = [
      "RoomCode",
      "ScheduledTime",
      "Starttime",
      "FinishTime",
      "WardenEmail",
      "Players",
      "OverallScore",
      "Room 1 Risk It Or Play It Safe?",
      "Room 1 Video Watched Twice?",
      "Room 1 Completed?",
      "Room 1 Score",
      "Room 2 Secret Hint Used?",
      "Room 2 Completed?",
      "Room 2 Finish Time",
      "Room 2 Score",
      "Room 3 Round 1 Distances",
      "Room 3 Round 1 Points",
      "Room 3 Round 2 Distances",
      "Room 3 Round 2 Points",
      "Room 3 Round 3 Distances",
      "Room 3 Round 3 Points",
      "Room 3 Round 4 Distances",
      "Room 3 Round 4 Points",
      "Room 3 Round 5 Distances",
      "Room 3 Round 5 Points",
      "Room 3 Completed?",
      "Room 3 Score",
    ];

    values.unshift(columns);

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        majorDimension: "ROWS",
        values: values,
      }),
    };
    fetch(API_URL, requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data));

    toast("Sheet was filled successfully!");

    setloading(false);
  }

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => setaccessToken(tokenResponse.access_token),
    scope: "https://www.googleapis.com/auth/spreadsheets",
  });

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#1AA1E6",
            color: "#fff",
            fontWeight: 800,
            fontSize: "calc( .8vh + 1.2vw)",
          },
        }}
      />
      <div className="toSheet">
        <h1>
          Do you want to auto-fill the sheet with the latest game data as of{" "}
          <br />
          {new Date().toString()} ?
        </h1>
        <div className="overlay"></div>
        {!accessToken ? (
          <button onClick={login} className="toSheet_Google">
            <img src={GLOGO} alt="" />
            <div className="text">Sign in with Google</div>
          </button>
        ) : (
          <button
            onClick={fillDateToSheet}
            id="defaultBtn"
            className="toSheet_btn"
          >
            {loading ? "Filling..." : "Yes"}
          </button>
        )}
      </div>
    </>
  );
}

export default DataToSheet;
