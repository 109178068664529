import React from "react";
import { useEffect, useState } from "react";

import "./infoBtn.styles.scss";

function InfoBtn() {
  const [gameCode, setgameCode] = useState(null);
  const [warden, setwarden] = useState(null);

  useEffect(() => {
    setgameCode(JSON.parse(localStorage.getItem("GAME_CODE")));
    setwarden(JSON.parse(localStorage.getItem("WARDEN")));
  }, []);

  const [showgameInfo, setshowgameInfo] = useState(false);

  return (
    <>
      {gameCode && warden ? (
        <div
          className="gameInfoButton"
          onClick={() => {
            setshowgameInfo(!showgameInfo);
          }}
        >
          i
        </div>
      ) : (
        ""
      )}

      {showgameInfo ? (
        <div className="gameInfo">
          <h1 className="code">GAME CODE:</h1>
          <h1>{gameCode}</h1>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default InfoBtn;
