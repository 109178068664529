import React, { useEffect, useState } from "react";

import room3Intro from "../../assets/gameAssets/FarrowIntro(Final).mp4";
import { setRoomThreeStarted } from "../../firebase/firebase";
import { database } from "../../firebase/firebaseConfig";
import { ref, onValue } from "firebase/database";

import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

import { Redirect } from "react-router";

import "./room3Entry.styles.scss";

function Room3Entry() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const warden = JSON.parse(localStorage.getItem("WARDEN"));

  const [showStart, setShowStart] = useState(false);

  const [gameStarted, setgameStarted] = useState(false);

  const [muted, setmuted] = useState(true);

  useEffect(() => {
    const dbRefRoomTwoStarted = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/roomStarted`,
    );

    onValue(dbRefRoomTwoStarted, (snapshot) => {
      setgameStarted(snapshot.val());
    });
  }, []);

  if (gameStarted) {
    return <Redirect to="/guessit" />;
  }

  return (
    <>
      <div className="room3Entry">
        <video
          autoPlay
          muted={muted}
          id="transitionVideo"
          onEnded={() => {
            setShowStart(true);
          }}
        >
          <source src={room3Intro} type="video/mp4" />
        </video>

        {showStart ? (
          <button
            className="gameIntro__startBtn"
            onClick={() => {
              setRoomThreeStarted(gameCode);
            }}
          >
            {" "}
            Ok!{" "}
          </button>
        ) : (
          ""
        )}

        {warden ? (
          <button
            id="defaultBtn"
            onClick={() => {
              setRoomThreeStarted(gameCode);
            }}
          >
            SKIP AND START
          </button>
        ) : (
          ""
        )}
      </div>
      <div
        className="videoSound"
        onClick={() => {
          setmuted(!muted);
        }}
      >
        {!muted ? <img src={soundOn} alt="" /> : <img src={soundOff} alt="" />}
      </div>
    </>
  );
}

export default Room3Entry;
