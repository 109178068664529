export const quizDataObject = [
  {
    id: 1,
    title:
      "Frederico DiSanta is known for his signature all-black clothing style. What color tie was he wearing at his business meeting?",
    correctTitle:
      "Frederico DiSanta was wearing a GREEN tie at his business meeting.",
    answer: "e",
    choices: [
      {
        title: "Black",
        key: "a",
      },
      {
        title: "White",
        key: "b",
      },
      {
        title: "Red",
        key: "c",
      },
      {
        title: "Blue",
        key: "d",
      },
      {
        title: "Green",
        key: "e",
      },
      {
        title: "Orange",
        key: "f",
      },
    ],
  },
  {
    id: 2,
    title: "What was the name of the restaurant?",
    correctTitle: "The name of the restaurant was Luigi’s.",
    answer: "d",
    choices: [
      {
        title: "Salvatore’s",
        key: "a",
      },
      {
        title: "La Taglatella",
        key: "b",
      },
      {
        title: "Donatello’s",
        key: "c",
      },
      {
        title: "Luigi’s",
        key: "d",
      },
      {
        title: "Il Forno",
        key: "e",
      },
      {
        title: "La Mafiana",
        key: "f",
      },
    ],
  },
  {
    id: 3,
    title: "What was the country of the flag that the old man was staring at?",
    correctTitle: "The old man was staring at the flag of the Czech Republic.",
    answer: "d",
    choices: [
      {
        title: "China",
        key: "a",
      },
      {
        title: "Peru",
        key: "b",
      },
      {
        title: "Ukraine",
        key: "c",
      },
      {
        title: "Czech Republic",
        key: "d",
      },
      {
        title: "Indonesia",
        key: "e",
      },
      {
        title: "Egypt",
        key: "f",
      },
    ],
  },
  {
    id: 4,
    title: "What was the time on the clock?",
    correctTitle: "It was 9:15.",
    answer: "a",
    choices: [
      {
        title: "9:15",
        key: "a",
      },
      {
        title: "6:00",
        key: "b",
      },
      {
        title: "12:30",
        key: "c",
      },
      {
        title: "5:45",
        key: "d",
      },
      {
        title: "12:45",
        key: "e",
      },
      {
        title: "3:30",
        key: "f",
      },
    ],
  },
  {
    id: 5,
    title:
      "Another ISA spy was keeping an eye on the DiSanta Brothers this evening. He was wearing the ISA patch on his sleeve. What was it?",
    correctTitle:
      "The Xylophone Player was the other ISA spy in attendance this evening.",
    answer: "c",
    choices: [
      {
        title: "The Man Holding The Menu",
        key: "a",
      },
      {
        title: "The Pirate",
        key: "b",
      },
      {
        title: "The Xylophone Player",
        key: "c",
      },
      {
        title: "The Gamer Girl",
        key: "d",
      },
      {
        title: "The Guitarist",
        key: "e",
      },
      {
        title: "The Baby",
        key: "f",
      },
    ],
  },
  {
    id: 6,
    title: "How many babies were in the restaurant?",
    correctTitle: "There were 2 babies in the restaurant.",
    answer: "c",
    choices: [
      {
        title: "0",
        key: "a",
      },
      {
        title: "1",
        key: "b",
      },
      {
        title: "2",
        key: "c",
      },
      {
        title: "3",
        key: "d",
      },
      {
        title: "4",
        key: "e",
      },
      {
        title: "5",
        key: "f",
      },
    ],
  },
  {
    id: 7,
    title: "Which character was eating the pizza?",
    correctTitle: "The Pirate was eating the pizza.",
    answer: "e",
    choices: [
      {
        title: "The Nurse",
        key: "a",
      },
      {
        title: "Frederico DiSanta",
        key: "b",
      },
      {
        title: "The Wizard",
        key: "c",
      },
      {
        title: "The Police Officer",
        key: "d",
      },
      {
        title: "The Pirate",
        key: "e",
      },
      {
        title: "The Sailor",
        key: "f",
      },
    ],
  },
];

export const selectedOptionArrayState = {
  0: "inactive",
  1: "inactive",
  2: "inactive",
  3: "inactive",
  4: "inactive",
  5: "inactive",
};
