import { ref, set } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

// Game.js
export const showPuzzleSolved = (computer) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/showPuzzleSolved`,
  );
  set(dbRef, true);
};

export const showGlobalAttempts = (computer, val) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/showGlobalAttempts`,
  );
  set(dbRef, val);
};

export const setcurrentPuzzle = (computer, val) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/currentPuzzle`,
  );
  set(dbRef, val);
};

export const setAttempts = (computer, val) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/attempts`,
  );
  set(dbRef, val);
};

export const puzzleSolved = (computer) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/solved`,
  );
  set(dbRef, true);
};

// hacking Puzzle penalty used
export const setHackingPuzzlePenaltyUsed = (computer) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/penalty`,
  );
  set(dbRef, true);
};

// limit use of attempts
export const limitAttemptsRefillsComputer = (computer, val, currentPuzzle) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/attemptsRefillsPuzzle${currentPuzzle}`,
  );
  set(dbRef, val);
};

// HackingPuzzle.js
// export const removeDud = (computer, val)=>{
//     const dbRef = ref(database, `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/removeDud`);
//     set(dbRef,val);
// }

// export const setUsedSpecialStrings = (computer, val)=>{
//     const dbRef = ref(database, `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/usedSpecialStrings`);
//     set(dbRef,val);
// }
