import React, { useEffect, useState } from "react";
import { setObservationInstructionsWatched } from "../../firebase/firebase";
import { ref, onValue } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

import { Redirect } from "react-router-dom";
import "./observationPuzzleinstruction.styles.scss";

import transitionVid from "../../assets/gameAssets/transitiontoObsPuzv2.mp4";
import InfoBtn from "../../components/infoBtn/infoBtn.component";

import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

function ObservationPuzzleInstruction() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  // const [gameWithoutCode, setgamewithoutCode] = useState(false)

  const [instructionsWatched, setInstuctionsWatched] = useState(false);

  const [showOption, setshowOption] = useState(false);

  const [muted, setmuted] = useState(true);

  //*************** */

  useEffect(() => {
    const dbRefinstructionsWatched = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/instructionsWatched`,
    );

    //  Read game completed or not
    onValue(dbRefinstructionsWatched, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setInstuctionsWatched(true);
      }
    });
  }, []);

  //*************** */

  if (instructionsWatched) {
    return <Redirect to="/observationPuzzleImg" />;
  }

  return (
    <>
      <InfoBtn />
      <video
        autoPlay
        muted={muted}
        id="transitionVideo"
        onEnded={() => {
          setshowOption(true);
        }}
      >
        <source src={transitionVid} type="video/mp4" />
      </video>

      {showOption ? (
        <h1
          className="playVideo"
          onClick={() => {
            setObservationInstructionsWatched(gameCode);
          }}
        >
          Yes
        </h1>
      ) : (
        ""
      )}

      <div
        className="videoSound"
        onClick={() => {
          setmuted(!muted);
        }}
      >
        {!muted ? <img src={soundOn} alt="" /> : <img src={soundOff} alt="" />}
      </div>
    </>
  );
}

export default ObservationPuzzleInstruction;
