const puzzlesData = () => {
  const puzzles = {
    easy: {
      //17 is the number of lines
      //12 is number of symbols on each line
      symbols: `
        !'+*^+$/:'~'"%,^++&_;;:*
        $^/?/,^:^+?!*=#/':--*"_+
        |_-",!&?--%+#:_/-?'$-%@,
        @@"$,;?,:-#-!$#^$'%#@|_+
        "",$_"~"/@++%:;-%^|+&':#
        /!;=:@+:%*$++?+"""-$^-&^
        &*~?#/'~*|=&,_@!/?@';,:%
        ;?-+@@~;&'~"_@|+'^'%,;^=
        &&|*_$*=|;:?/@,_,;=~$";+
        "%;,@*@?@/#&;@/:@^#|^|'+
        ~+:#|_@:!:/'=_|@!#_=;$?=
        |_+:&&$!/~^"/'=~^/~**$=-
        ?|&;/%;$|_-,/,&@$@$$-,##
        %&-++;==-%@~^;''#~,#_+~_
        |~*,+",#;~@#_~,!"^;_";_|
        =%#++'~_|@|$|_##:%'/?@|%
        ,=__'@-#@|,*,|~&+-/@,?;+
      `,
      specialSymbols: [
        {
          string: "[$/`%@+]",
          position: {
            col: "LEFT",
            index: 22,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(=¨`%~|)",
          position: {
            col: "LEFT",
            index: 108,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[!^~,_&%]",
          position: {
            col: "LEFT",
            index: 187,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(:+$_*|)",
          position: {
            col: "RIGHT",
            index: 45,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[#@_;?+]",
          position: {
            col: "RIGHT",
            index: 109,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(+;~#_=&,)",
          position: {
            col: "RIGHT",
            index: 142,
          },
          action: "ATTEMPTS_REFILL",
        },
      ],
      duds: ["LEPTA", "MESAS", "EASTS"],
      goalWord: {
        word: "WAINS",
        position: {
          col: "RIGHT",
          index: 102,
        },
      },
      words: [
        {
          word: "GLARE",
          position: {
            col: "LEFT",
            index: 10,
          },
        },
        {
          word: "REDIP",
          position: {
            col: "LEFT",
            index: 38,
          },
        },
        {
          word: "AMBIT",
          position: {
            col: "LEFT",
            index: 52,
          },
        },
        {
          word: "CINCH",
          position: {
            col: "LEFT",
            index: 85,
          },
        },
        {
          word: "LEPTA",
          position: {
            col: "LEFT",
            index: 102,
          },
        },
        {
          word: "KINKS",
          position: {
            col: "LEFT",
            index: 125,
          },
        },
        {
          word: "CETES",
          position: {
            col: "LEFT",
            index: 142,
          },
        },
        {
          word: "BLAMS",
          position: {
            col: "LEFT",
            index: 175,
          },
        },
        {
          word: "LAREE",
          position: {
            col: "RIGHT",
            index: 24,
          },
        },
        {
          word: "MESAS",
          position: {
            col: "RIGHT",
            index: 37,
          },
        },
        {
          word: "GAPES",
          position: {
            col: "RIGHT",
            index: 68,
          },
        },
        {
          word: "YAFFS",
          position: {
            col: "RIGHT",
            index: 88,
          },
        },
        {
          word: "RAYAS",
          position: {
            col: "RIGHT",
            index: 122,
          },
        },
        {
          word: "GADIS",
          position: {
            col: "RIGHT",
            index: 174,
          },
        },
        {
          word: "EASTS",
          position: {
            col: "RIGHT",
            index: 187,
          },
        },
      ],
    },
    medium: {
      symbols: `
        #"';?:!_-/~?;$''"&"';"-_
        ?=+/@--=^=?^:;_%''+:$=+%
        ?@!^~,_&%=*~',|@~,;||/!:
        '?/&,+~~-|:/&&,+@'=,^,~_
        ?:@^-,?#,,%-=/%%'=!#=/~$
        ?/,,+#!'?@=|-?/?~'|$:+-!
        &/~!$#$"%-"-%,,^%@;;@#|$
        @'!_|'%"#@#''/-:"=|':!&&
        ,&/@";,";+'-=^'~#^?!&#-/
        -&%;$~#_!?*~//@''-;!|^%,
        =&;-|-@_-/'~'_$:/^/?:*#*
        *$@%&=?/;"-%@^;~%&&#'?=!
        +=@'''$_^$:+$_*|&#?=~==~
        !=-%&=^!$"&@'",/';$%:@+*
        ++#;=%'=::^#@_;?+~-=;~@~
        ~';=^""^!;=-:="^_:~,'*-^
        :-~'^~?|-'+;~#_=&,=;?:@|  
      `,
      specialSymbols: [
        {
          string: "[;+=^'~#]",
          position: {
            col: "LEFT",
            index: 45,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(=;~@~)",
          position: {
            col: "LEFT",
            index: 134,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[!%'=:]",
          position: {
            col: "LEFT",
            index: 195,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(/@;,|)",
          position: {
            col: "RIGHT",
            index: 32,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[#,|@~,;|]",
          position: {
            col: "RIGHT",
            index: 88,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(-|:/&&,)",
          position: {
            col: "RIGHT",
            index: 168,
          },
          action: "ATTEMPTS_REFILL",
        },
      ],
      duds: ["TROCHLEA", "SHIPLESS", "TRADUCES"],
      goalWord: {
        word: "KHALIFAS",
        position: {
          col: "LEFT",
          index: 116,
        },
      },
      words: [
        {
          word: "MISDATED",
          position: {
            col: "LEFT",
            index: 100,
          },
        },
        {
          word: "BASIFIED",
          position: {
            col: "LEFT",
            index: 18,
          },
        },
        {
          word: "GAVINATE",
          position: {
            col: "LEFT",
            index: 36,
          },
        },
        {
          word: "LOCOFOCO",
          position: {
            col: "LEFT",
            index: 62,
          },
        },
        {
          word: "TROCHLEA",
          position: {
            col: "LEFT",
            index: 84,
          },
        },
        {
          word: "WITHIEST",
          position: {
            col: "LEFT",
            index: 143,
          },
        },
        {
          word: "PRESOAKS",
          position: {
            col: "LEFT",
            index: 185,
          },
        },
        {
          word: "PLEATERS",
          position: {
            col: "RIGHT",
            index: 8,
          },
        },
        {
          word: "GOSSOONS",
          position: {
            col: "RIGHT",
            index: 22,
          },
        },
        {
          word: "MINIFIES",
          position: {
            col: "RIGHT",
            index: 57,
          },
        },
        {
          word: "SHIPLESS",
          position: {
            col: "RIGHT",
            index: 78,
          },
        },
        {
          word: "WHITEFLY",
          position: {
            col: "RIGHT",
            index: 107,
          },
        },
        {
          word: "TRADUCES",
          position: {
            col: "RIGHT",
            index: 152,
          },
        },
        {
          word: "OUTLASTS",
          position: {
            col: "RIGHT",
            index: 184,
          },
        },
      ],
    },
    hard: {
      symbols: `
        !'+*^+$/:'~'"%,^++&_;;:*
        #~-'-//'&,=:^"+;^|~$_"%?
        "::$*#!~++;~_:?#*,~-#+^%
        ~='&^,?:&|+&,_'%!~?#?:~/
        __:?^??~_!,=*$?@+:-!?";|
        =|=|!|+"=##":*$_~*@"&'^&
        /",/*"?&&==;+?~~~+;'~!#_
        ^=,,#;&/%@*,-&=_+@#"/!*%
        +;#&!*,=&%!#=/;$/#+?"";;
        -##/%*|!!_=+'&&-*-$%''#*
        ,-&!!$#^?*;&#"/;_"-+,*~?
        --=_#$-!@-?||#@-&@-'!|^!
        $:/'?~^,_"@!!@/_+!#|~|,:
        ,@":%=-/::~-?$=$,|#^+!-$
        !@'|:%:=,~&#,/""";,"-:_+
        &=!+~--#=|^-,'%_^/$?*?/^
        @,'|&@@'@/-@-$#_,/#/"$;^
        =+:^'"#,#|=?@;$#+%""@%+|       
      `,
      specialSymbols: [
        {
          string: "[-#=|^-,']",
          position: {
            col: "LEFT",
            index: 2,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "(??~_!,=)",
          position: {
            col: "LEFT",
            index: 83,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "[|+&,_'%]",
          position: {
            col: "LEFT",
            index: 158,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "(^?*;&#/;)",
          position: {
            col: "RIGHT",
            index: 40,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "['~'%,^+]",
          position: {
            col: "RIGHT",
            index: 93,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "(;&#/&,)",
          position: {
            col: "RIGHT",
            index: 164,
          },
          action: "REMOVE_DUD",
        },
      ],
      duds: ["ECLAMPTIC", "COATTENDS", "GRIDDLING"],
      goalWord: {
        word: "SPICEBUSH",
        position: {
          col: "RIGHT",
          index: 178,
        },
      },
      words: [
        {
          word: "OVERHUNTS",
          position: {
            col: "LEFT",
            index: 18,
          },
        },
        {
          word: "ECLAMPTIC",
          position: {
            col: "LEFT",
            index: 47,
          },
        },
        {
          word: "ANTECHOIR",
          position: {
            col: "LEFT",
            index: 68,
          },
        },
        {
          word: "PLASMATIC",
          position: {
            col: "LEFT",
            index: 104,
          },
        },
        {
          word: "COATTENDS",
          position: {
            col: "LEFT",
            index: 142,
          },
        },
        {
          word: "APERTURED",
          position: {
            col: "LEFT",
            index: 179,
          },
        },
        {
          word: "REINFLAME",
          position: {
            col: "RIGHT",
            index: 27,
          },
        },
        {
          word: "CULTURIST",
          position: {
            col: "RIGHT",
            index: 56,
          },
        },
        {
          word: "GRIDDLING",
          position: {
            col: "RIGHT",
            index: 79,
          },
        },
        {
          word: "CLIENTAGE",
          position: {
            col: "RIGHT",
            index: 123,
          },
        },
        {
          word: "CONCEPTUS",
          position: {
            col: "RIGHT",
            index: 144,
          },
        },
      ],
    },
  };

  return puzzles;
};

export default puzzlesData;
