import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { database } from "../../firebase/firebaseConfig";

import "./obsPuzzleMouse.styles.scss";

// sets observation puzzle time in seconds
const OBSERVATION_PUZZLE_TIMER = 27;

function ObsPuzzleMouse() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  // for auto redurection when player joins late or rejoins
  const [roomOneCompleted, setroomOneCompleted] = useState(false);

  const [posX, setposX] = useState(50);
  const [posY, setposY] = useState(50);

  const [timesUp, settimesUp] = useState(false);

  const handleMouseMove = (e) => {
    setposX(Math.ceil((e.clientX / window.innerWidth) * 100));
    setposY(Math.ceil((e.clientY / window.innerHeight) * 100));
  };

  useEffect(() => {
    const dbRefRoomOneAlreadyComplete = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/completed`,
    );
    // for late join or rejoin
    onValue(dbRefRoomOneAlreadyComplete, (snapshot) => {
      setroomOneCompleted(snapshot.val());
    });
  }, []);

  // timesout the observation puzzle
  useEffect(() => {
    const timer = setTimeout(() => {
      settimesUp(true);
    }, OBSERVATION_PUZZLE_TIMER * 1000);
    return () => clearTimeout(timer);
  }, []);

  if (roomOneCompleted) {
    return <Redirect to="/room2" />;
  }

  if (timesUp) {
    return <Redirect to="/ObservationPuzzle" />;
  }

  return (
    <>
      <div className="container">
        <div
          className="obsPuzzleMouse"
          onMouseMove={handleMouseMove}
          style={{
            clipPath: `circle(calc(10vh + 10vw) at ${posX}% ${posY}%)`,
            cursor: "none",
          }}
        ></div>
      </div>
    </>
  );
}

export default ObsPuzzleMouse;
