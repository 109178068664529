import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const config = {
  apiKey: "AIzaSyAb4SWYJCdOneR0Oj4ighdQv4S40eLRrgE",
  authDomain: "spyisland-2304e.firebaseapp.com",
  databaseURL:
    "https://spyisland-2304e-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "spyisland-2304e",
  storageBucket: "spyisland-2304e.appspot.com",
  messagingSenderId: "1028074929554",
  appId: "1:1028074929554:web:65313b2248cca9af20d54b",
  measurementId: "G-R6K6J2HW10",
};

// Initialize Firebase
const app = initializeApp(config);
export const database = getDatabase(app);

// global variables
export const maxPlayers = 10;
