import { ref, set, get, child } from "firebase/database";
import { database } from "./firebaseConfig";

export const checkCodeValidity = async (code) => {
  const dbRefCurrentTeam = ref(database);

  const snapshot = await get(
    child(dbRefCurrentTeam, `Rooms/${code}/scheduledTime`),
  );

  if (!snapshot.exists()) {
    return false;
  }

  // can only join 30 minutes prior and 3 hours later
  if (
    new Date().getTime() / 1000 - snapshot.val() > 10800 ||
    new Date().getTime() / 1000 - snapshot.val() < -1800
  ) {
    return false;
  }

  return true;
};

export const startTheGame = (teamCode) => {
  const dbRefGameStrt = ref(database, `Rooms/${teamCode}/gameStarted`);
  set(dbRefGameStrt, true);
};

export const setMediumHardFirebase = (level, teamCode) => {
  const dbRefGameLevel = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/nextPuzzleChoice/${level}`,
  );
  set(dbRefGameLevel, true);
};

export const setObservationPuzzleCompletedTrue = (teamCode) => {
  const dbRefGameCompleted = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/levelCompleted`,
  );
  set(dbRefGameCompleted, true);
};

export const setObservationInstructionsWatched = (teamCode) => {
  const dbRefinstructionsWatched = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/instructionsWatched`,
  );
  set(dbRefinstructionsWatched, true);
};

export const setGiveGameIntroTrue = (teamCode) => {
  // setGameStartTime
  const setGameStartTimeRef = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/startTime`,
  );
  set(setGameStartTimeRef, new Date().getTime() / 1000);

  const setGiveGameIntroTrueRef = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/gameIntro/introPlayed`,
  );
  set(setGiveGameIntroTrueRef, true);
};

export const setQuestionNumberObsPuzzle = (quesNum, teamCode) => {
  const dbRefQuestionNumber = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/currentQuestion`,
  );
  set(dbRefQuestionNumber, quesNum);
};

export const setCurrentAnsResultObsPuz = (result, teamCode) => {
  const dbRefCurrentAnsResultObsPuz = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/currentAnsResult`,
  );
  set(dbRefCurrentAnsResultObsPuz, result);
};

export const setShowFeedbackObsPuz = (feedBack, teamCode) => {
  const dbRefShowFeedbackObsPuz = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/showFeedback`,
  );
  set(dbRefShowFeedbackObsPuz, feedBack);
};

export const setScoreObsPuz = (score, teamCode) => {
  const dbRefScoreObsPuz = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/score`,
  );
  set(dbRefScoreObsPuz, score);
};

export const setTookHelp = (teamCode) => {
  const dbRefTookHelp = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/tookHelp`,
  );
  set(dbRefTookHelp, true);
};

export const setvideoWatchedTwiceTrue = (teamCode) => {
  // to wait for reditrection first
  setTimeout(() => {
    const dbRefvideoWatchedTwice = ref(
      database,
      `Rooms/${teamCode}/RoomStates/room1/observationPuzzle/videoWatchedTwice`,
    );
    set(dbRefvideoWatchedTwice, true);
  }, 1000);
};

export const setRoomOneFinishedTrue = (teamCode) => {
  const dbRefRoomOneFinished = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/completed`,
  );
  set(dbRefRoomOneFinished, true);

  const dbRefRoomOneFinishTime = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room1/finishTime`,
  );
  set(dbRefRoomOneFinishTime, new Date().getTime() / 1000);
};

// starts room2 and takes all the players to room 2 after the video
export const setRoomTwoStarted = (teamCode) => {
  const dbRefRoomTwoStarted = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room2/roomStarted`,
  );
  set(dbRefRoomTwoStarted, true);

  const dbRefRoomTwoStartTime = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room2/startTime`,
  );
  set(dbRefRoomTwoStartTime, Math.floor(new Date().getTime() / 1000));
};

export const setSecretHintUsedFirebase = (teamCode, value) => {
  const dbRef = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room2/secretHintUsed`,
  );
  set(dbRef, value);
};

export const setForcedToRoom3 = (teamCode, value) => {
  const dbRef = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room2/forcedtoRoom3`,
  );
  set(dbRef, value);
};

export const setRoomTwoFinishedTrue = (teamCode) => {
  const dbRefRoomTwoFinished = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room2/completed`,
  );
  set(dbRefRoomTwoFinished, true);

  const dbRefRoomTwoFinishTime = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room2/finishTime`,
  );
  set(dbRefRoomTwoFinishTime, Math.floor(new Date().getTime() / 1000));
};

// starts room3 and takes all the players to room 3 after the video
export const setRoomThreeStarted = (teamCode) => {
  const dbRefRoomThreeStarted = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room3/roomStarted`,
  );
  set(dbRefRoomThreeStarted, true);

  const dbRefRoomThreeStartTime = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room3/startTime`,
  );
  set(dbRefRoomThreeStartTime, Math.floor(new Date().getTime() / 1000));
};

// redirect to room 3 Intro
export const setToRoom3IntroTrue = (teamCode) => {
  const dbRefsetToRoom3IntroTrue = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room3/toRoom3Intro`,
  );
  set(dbRefsetToRoom3IntroTrue, true);
};

export const setRoomThreeFinishedTrue = (teamCode) => {
  const dbRefRoomThreeFinished = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room3/completed`,
  );
  set(dbRefRoomThreeFinished, true);

  const dbRefRoomThreeFinishTime = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room3/finishTime`,
  );
  set(dbRefRoomThreeFinishTime, new Date().getTime() / 1000);
};

export const setShowEndingVideo = (teamCode, result) => {
  const dbRef = ref(
    database,
    `Rooms/${teamCode}/RoomStates/room3/show${result}Video`,
  );
  set(dbRef, true);
};

// totals

export const setRoomOneTotalFirebase = (teamCode, value) => {
  const dbRef = ref(database, `Rooms/${teamCode}/RoomStates/room1/totalScore`);
  set(dbRef, value);
};

export const setRoomTwoTotalFirebase = (teamCode, value) => {
  const dbRef = ref(database, `Rooms/${teamCode}/RoomStates/room2/totalScore`);
  set(dbRef, value);
};

export const setTotalTimeTakenFirebase = (teamCode, value) => {
  const dbRef = ref(database, `Rooms/${teamCode}/totalTimeTaken`);
  set(dbRef, value);
};

export const setTotalTimeBonusFirebase = (teamCode, value) => {
  const dbRef = ref(database, `Rooms/${teamCode}/timeBonusPoints`);
  set(dbRef, value);
};

export const setOverAllScoreFirebase = (teamCode, value) => {
  const dbRef = ref(database, `Rooms/${teamCode}/overallScore`);
  set(dbRef, value);
};
