import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { checkCodeValidity } from "../../firebase/firebase";

import "./playerGameStart.styles.scss";
import { setTeam } from "./playerGameUtil";

function PlayerGameStart() {
  // getting form inputs
  // use let for making the code uppercase, can't use setCode because of asynchronous nature
  let [code, setCode] = useState("");

  const [playerName, setplayerName] = useState("");

  // waiting for other players
  const [waiting, setwaiting] = useState(false);

  // code validity
  const [codeInvalid, setcodeInvalid] = useState(false);

  const assignToTeam = async (e) => {
    e.preventDefault();

    // gaurd Clause for valid code
    if (code === "") {
      return;
    }

    // makes the code uppercase
    code = code.toUpperCase();

    // check game code
    const codeValid = await checkCodeValidity(code);

    if (!codeValid) {
      setcodeInvalid(true);
      return;
    }

    // using local storage to persist data b/w refresh
    localStorage.setItem("GAME_CODE", JSON.stringify(code));

    // using local storage to persist data b/w refresh
    localStorage.setItem("WARDEN", JSON.stringify(false));

    // hacking puzzle fix
    localStorage.setItem("HPR_REFRESHED", JSON.stringify(false));
    localStorage.setItem("HPL_REFRESHED", JSON.stringify(false));

    await setTeam({ code, playerName });

    setwaiting(true);
  };

  if (waiting) {
    return <Redirect to="/waitingRoom" />;
  }

  if (codeInvalid) {
    return <Redirect to="/roomCodeExpired" />;
  }

  return (
    <div className="playergameStart">
      <form action="" onSubmit={assignToTeam}>
        <h1>Game Code</h1>
        <input
          maxLength="5"
          type="text"
          required
          className="game__code"
          onChange={(e) => setCode(e.target.value)}
        />
        <h1>Your Name</h1>
        <input
          type="text"
          className="game__player"
          required
          onChange={(e) => setplayerName(e.target.value)}
        />

        <input type="submit" id="defaultBtn" value="Submit" />
      </form>
    </div>
  );
}

export default PlayerGameStart;
