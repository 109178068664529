const generatePointersColumns = () => {
  const VALUE = 65100;

  let generatedPointers = {
    left: [],
    right: [],
  };

  const generatePointerColumn = (value, columnPos) => {
    let pointers = [];
    let pointerVal;

    // 17 lines
    for (let i = 0; i < 17; i++) {
      let leadingSpace = columnPos === "LEFT" ? "" : " ";

      pointerVal = leadingSpace + convertToHex(value) + " ​";
      pointers.push(pointerVal);

      value += 12;
    }

    return pointers;
  };

  const convertToHex = (d) => {
    let hex = Number(d).toString(16).toUpperCase();
    while (hex.length < 4) {
      hex = "0" + hex;
    }
    return "0x" + hex;
  };

  generatedPointers = {
    left: generatePointerColumn(VALUE, "LEFT"),
    right: generatePointerColumn(VALUE + 204, "RIGHT"),
  };

  return generatedPointers;
};

export default generatePointersColumns;
