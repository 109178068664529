import React, { useEffect, useState } from "react";

import "./gameCreater.styles.scss";
import { createRooms } from "./gameCreaterFirebase";

const columns = [{ heading: "Codes", property: "code" }];

const Table = ({ columns, data, propertyAsKey }) => (
  <table
    className="table"
    style={{
      backgroundColor: "#ffffff8c",
      padding: "calc(1vh + 1vw)",
      borderRadius: "calc(.5vh + .5vw)",
    }}
  >
    <thead>
      <tr>
        {columns.map((col) => (
          <th
            style={{ fontSize: "calc(3vh + 3vw)", fontWeight: "800" }}
            key={`header-${col.heading}`}
          >
            {col.heading}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <tr key={`${item[propertyAsKey]}-row`}>
          {columns.map((col) => (
            <td
              style={{
                fontSize: "calc(2vh + 2vw)",
                fontWeight: "500",
                textAlign: "center",
              }}
              key={`${item[propertyAsKey]}-${col.property}`}
            >
              {item[col.property]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

function GameCreater() {
  const [roomCount, setroomCount] = useState(1);
  const [dateAndTime, setdateAndTime] = useState("");
  const [roomCodes, setroomCodes] = useState([]);
  const [inCorrectPass, setinCorrectPass] = useState(false);

  const [password, setpassword] = useState("");

  const [showRoomCodeTable, setshowRoomCodeTable] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setinCorrectPass(false);
    }, 2000);
  }, [inCorrectPass]);

  const handleCreate = async (event) => {
    event.preventDefault();

    if (password !== "pass") {
      setinCorrectPass(true);
      return;
    }

    const codes = await createRooms(roomCount, dateAndTime);
    setroomCodes(codes);
    setshowRoomCodeTable(true);
  };

  return (
    <div className="gameCreater">
      {inCorrectPass ? (
        <div className="downloadWipe__incorrectPass">Incorrect Password!</div>
      ) : (
        ""
      )}

      <div className="overlay"></div>

      <div className="gameCreater__content">
        {!showRoomCodeTable ? (
          <form className="creationDetails" onSubmit={handleCreate}>
            <h1> How many codes do you need? </h1>
            <input
              type="number"
              min={1}
              max={20}
              defaultValue={1}
              required
              onChange={(e) => {
                setroomCount(e.target.value);
              }}
            />
            <h1>What date/time is the game?</h1>
            <input
              type="datetime-local"
              required
              onChange={(e) => {
                setdateAndTime(e.target.value);
              }}
            />
            <h1>Please enter the password</h1>
            <input
              type="password"
              onChange={(e) => setpassword(e.target.value)}
            />
            <input type="submit" id="defaultBtn" value="Submit" />
          </form>
        ) : (
          <Table columns={columns} data={roomCodes} propertyAsKey="code" />
        )}
      </div>
    </div>
  );
}

export default GameCreater;
