import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import FailOutro from "../../assets/gameAssets/SpyIslandOutroVideoFail.mp4";
import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

function FailVideo() {
  const [toResult, settoResult] = useState(false);
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const warden = JSON.parse(localStorage.getItem("WARDEN"));
  const [muted, setmuted] = useState(true);

  if (toResult) {
    return <Redirect to="/overallResults" />;
  }

  return (
    <>
      <video
        autoPlay
        muted={muted}
        id="transitionVideo"
        onEnded={() => {
          settoResult(true);
        }}
      >
        <source src={FailOutro} type="video/mp4" />
      </video>

      {warden ? (
        <button
          id="defaultBtn"
          onClick={() => {
            settoResult(gameCode);
          }}
        >
          SKIP
        </button>
      ) : (
        ""
      )}

      <div
        className="videoSound"
        onClick={() => {
          setmuted(!muted);
        }}
      >
        {!muted ? <img src={soundOn} alt="" /> : <img src={soundOff} alt="" />}
      </div>
    </>
  );
}

export default FailVideo;
