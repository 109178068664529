const generateSymbolColumns = (symbolsTemplate) => {
  let generatedSymbols = {
    left: [],
    right: [],
  };

  const filterSymbols = (symbolsTemplate) => {
    let array = symbolsTemplate.split("");

    let filteredString = array.filter((value) => {
      return value !== "\n" && value !== " ";
    });

    return filteredString;
  };

  const generateSymbolColumn = (isLeft, symbolsTemplateFilteredArray) => {
    let symbols = [];
    let symbolsArray = [];
    let symbolVal;
    let midStringIndex = symbolsTemplateFilteredArray.length / 2;

    if (isLeft) {
      symbolsArray = symbolsTemplateFilteredArray.slice(0, midStringIndex);
    } else {
      symbolsArray = symbolsTemplateFilteredArray.slice(midStringIndex);
    }

    symbolsArray.forEach((symb) => {
      symbolVal = {
        type: "SYMBOL",
        value: symb,
      };
      symbols.push(symbolVal);
    });

    return symbols;
  };

  let filteredSymbols = filterSymbols(symbolsTemplate);

  generatedSymbols = {
    left: generateSymbolColumn(true, filteredSymbols),
    right: generateSymbolColumn(false, filteredSymbols),
  };

  return generatedSymbols;
};

export default generateSymbolColumns;
