import React, { useEffect, useState } from "react";

import "./observationPuzzle.styles.scss";

import { ref, onValue } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

import {
  setQuestionNumberObsPuzzle,
  setCurrentAnsResultObsPuz,
  setShowFeedbackObsPuz,
  setScoreObsPuz,
  setTookHelp,
  setvideoWatchedTwiceTrue,
} from "../../firebase/firebase";

import quesImg1 from "../../assets/observationPuzzle/Q1.png";
import quesImg2 from "../../assets/observationPuzzle/Q2.png";
import quesImg3 from "../../assets/observationPuzzle/Q3.png";
import quesImg4 from "../../assets/observationPuzzle/Q4.png";
import quesImg5 from "../../assets/observationPuzzle/Q5.png";
import quesImg6 from "../../assets/observationPuzzle/Q6.png";
import quesImg7 from "../../assets/observationPuzzle/Q7.png";

import mainHUD from "../../assets/gameAssets/mainHUD.mp4";
import rewatch from "../../assets/gameAssets/Re-WatchVideo(Final)v2.mp4";
import { quizDataObject, selectedOptionArrayState } from "../../Utils/quizData";
import { Redirect } from "react-router-dom";
import { setObservationPuzzleCompletedTrue } from "../../firebase/firebase";
import InfoBtn from "../infoBtn/infoBtn.component";

function ObservationPuzzle() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const [ansImages] = useState([
    quesImg1,
    quesImg2,
    quesImg3,
    quesImg4,
    quesImg5,
    quesImg6,
    quesImg7,
  ]);

  const [gameWithoutCode, setgamewithoutCode] = useState(false);

  const [GameCompleted, setGameCompleted] = useState(false);

  const [quizData] = useState(quizDataObject);

  const [showVideoAgain, setshowVideoAgain] = useState(false);

  const [selectedOptionArray, setselectedOptionArray] = useState(
    selectedOptionArrayState,
  );

  const [score, setscore] = useState(0);

  const [showFeedback, setshowFeedback] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const [selectedOption, setSelectedOption] = useState("");

  const [currentAnsResult, setcurrentAnsResult] = useState(false);

  const [takeHelp, setTakeHelp] = useState(false);

  const [showrewatchBtns, setshowrewatchBtns] = useState(false);

  const [showConfirmModal, setshowConfirmModal] = useState(false);

  const [videoWatchedTwice, setvideoWatchedTwice] = useState(false);

  const selectOption = (e) => {
    const clickedOn = parseInt(e.target.classList[0].slice(-1));
    setselectedOptionArray({
      ...selectedOptionArrayState,
      [clickedOn]: "active",
    });
    setSelectedOption(e.target.id);
  };

  const nextQuestion = () => {
    if (currentQuestion === quizData.length - 1) {
      setObservationPuzzleCompletedTrue(gameCode);
      return;
    }

    setSelectedOption("");

    setQuestionNumberObsPuzzle(currentQuestion + 1, gameCode);
    setShowFeedbackObsPuz(false, gameCode);
  };

  const handleAnsSubmit = (e) => {
    e.preventDefault();

    setshowConfirmModal(false);

    setselectedOptionArray(selectedOptionArrayState);

    if (selectedOption === quizData[currentQuestion].answer) {
      setCurrentAnsResultObsPuz(true, gameCode);
      setScoreObsPuz(score + 1, gameCode);
    } else {
      setCurrentAnsResultObsPuz(false, gameCode);
    }

    setShowFeedbackObsPuz(true, gameCode);
  };

  //*************** */

  useEffect(() => {
    const dbRefGameCompleted = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/levelCompleted`,
    );
    const dbRefCurrentQues = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/currentQuestion`,
    );
    const dbRefCurrentAnsResultObsPuz = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/currentAnsResult`,
    );
    const dbRefShowFeedbackObsPuz = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/showFeedback`,
    );
    const dbRefScoreObsPuz = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/score`,
    );
    const dbRefTookHelp = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/tookHelp`,
    );
    const dbRefvideoWatchedTwice = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/videoWatchedTwice`,
    );

    //  Read game completed or not
    onValue(dbRefGameCompleted, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGameCompleted(true);
      }
    });

    //  syncs questions b/w players
    onValue(dbRefCurrentQues, (snapshot) => {
      const data = snapshot.val();
      setCurrentQuestion(data);
    });

    //  syncs current answers result b/w players
    onValue(dbRefCurrentAnsResultObsPuz, (snapshot) => {
      const data = snapshot.val();
      setcurrentAnsResult(data);
    });

    //  syncs score Screens b/w players
    onValue(dbRefScoreObsPuz, (snapshot) => {
      const data = snapshot.val();
      setscore(data);
    });

    //  syncs feedback Screens b/w players
    onValue(dbRefShowFeedbackObsPuz, (snapshot) => {
      const data = snapshot.val();
      setshowFeedback(data);
    });

    //  syncs video playback b/w players
    onValue(dbRefTookHelp, (snapshot) => {
      const data = snapshot.val();
      setshowVideoAgain(data);
    });

    //  saves video already watched twice
    onValue(dbRefvideoWatchedTwice, (snapshot) => {
      const data = snapshot.val();
      setvideoWatchedTwice(data);
    });
  }, []);

  useEffect(() => {
    if (currentQuestion === 3 && score <= 1 && !videoWatchedTwice) {
      setTakeHelp(true);
    }
  }, [currentQuestion]);

  if (showVideoAgain && !videoWatchedTwice) {
    setvideoWatchedTwiceTrue(gameCode);
    return <Redirect to="/observationPuzzleImg" />;
  }

  //*************** */

  if (GameCompleted) {
    return <Redirect to="/roomOneResult" />;
  }

  return (
    <>
      <InfoBtn />
      {showConfirmModal ? (
        <>
          <div className="confirmAns__overlay"></div>
          <div className="confirmAns">
            <div className="message_header">
              <h1> Submit Answer? </h1>
            </div>
            <div className="message_body">
              <div className="message__text">
                Are you sure you want to submit on the behalf of your team ?
              </div>
              <div className="puzzle__btn-container">
                <button
                  className="message-btn"
                  onClick={() => {
                    setshowConfirmModal(false);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="message-btn submit-message-btn"
                  onClick={handleAnsSubmit}
                  type="button"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <video autoPlay muted loop id="HUDvideoFull">
        <source src={mainHUD} type="video/mp4" />
      </video>

      {takeHelp ? (
        <>
          <video
            autoPlay
            muted
            onEnded={() => {
              setshowrewatchBtns(true);
            }}
            id="HUDvideoFull"
          >
            <source src={rewatch} type="video/mp4" />
          </video>

          <div className="takeHelp">
            {showrewatchBtns ? (
              <div className="options">
                <button
                  onClick={() => {
                    setTookHelp(gameCode);
                  }}
                >
                  GO
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <div className="quiz">
          {showFeedback ? (
            <div className="quizAnswerScreen">
              {currentAnsResult ? (
                <h1 className="gameFinishMessage">CORRECT!</h1>
              ) : (
                <h1 className="gameFinishMessage">INCORRECT!</h1>
              )}
              <img
                className="answerImages"
                src={ansImages[currentQuestion]}
                alt=""
              />
              <h2 className="gameFinishTitle">
                {quizData[currentQuestion].correctTitle}
              </h2>

              <button id="defaultBtn" onClick={nextQuestion}>
                Next
              </button>
            </div>
          ) : (
            <>
              <div className="quizQuestionContainer">
                <h1>
                  Q{currentQuestion + 1} - {quizData[currentQuestion].title}
                </h1>
              </div>
              <div className="quizOptionContainer">
                {quizData[currentQuestion].choices.map((option, i) => {
                  return (
                    <h1
                      key={`q${i}`}
                      id={option.key}
                      className={`option__${i} ${selectedOptionArray[i]}`}
                      onClick={selectOption}
                    >
                      {option.title}
                    </h1>
                  );
                })}
              </div>
              <button
                id="defaultBtn"
                onClick={() => {
                  if (selectedOption === "") return;
                  setshowConfirmModal(true);
                }}
              >
                Submit
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}
export default ObservationPuzzle;
