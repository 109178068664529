import React, { useEffect, useState } from "react";
import { onValue, ref } from "@firebase/database";
import { database } from "../../firebase/firebaseConfig";

import {
  setForcedToRoom3,
  setSecretHintUsedFirebase,
} from "../../firebase/firebase";

import "./room2.styles.scss";

import { Redirect } from "react-router";
import Loading from "../../components/loading/loading.component";
import CipherWheel from "../../components/cipherWheel/cipherWheel.component";
import KeypadPuzzle from "../../components/KeypadPuzzle/KeypadPuzzle";
import RadioPuzzle from "../../components/RadioPuzzle/RadioPuzzle";
import InfoBtn from "../../components/infoBtn/infoBtn.component";
import { Link } from "react-router-dom";

import { useStateValue } from "../../stateManagement/StateProvider.state";

function Room2() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const warden = JSON.parse(localStorage.getItem("WARDEN"));

  const [{ room2Loaded }, dispatch] = useStateValue();

  const [showImage1, setshowImage1] = useState(false);

  const [showImage2, setshowImage2] = useState(false);

  const [showCipherWheel, setshowCipherWheel] = useState(false);

  const [showKeypad, setshowKeypad] = useState(false);

  const [showRadio, setshowRadio] = useState(false);

  const [currentlyLoading, setcurrentlyLoading] = useState(true);

  const [roomTwoCompleted, setroomTwoCompleted] = useState(false);

  const [showSecretHint, setshowSecretHint] = useState(false);

  const [secretHintUsed, setsecretHintUsed] = useState("No");

  const [forcedOutofRoom, setforcedOutofRoom] = useState("No");

  useEffect(() => {
    const timer = setTimeout(() => {
      setcurrentlyLoading(false);

      dispatch({
        type: "SET_LOADED",
        payload: true,
      });
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const dbRefRoomOneFinished = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/completed`,
    );
    // checks when room two is completed
    onValue(dbRefRoomOneFinished, (snapshot) => {
      const data = snapshot.val();
      setroomTwoCompleted(data);
    });
  }, []);

  const toPainting1 = () => {
    setshowImage1(true);
  };

  const toPainting2 = () => {
    setshowImage2(true);
  };

  const toCipherWheel = () => {
    setshowCipherWheel(true);
  };

  const toRadio = () => {
    setshowRadio(true);
  };

  const toKeypad = () => {
    setshowKeypad(true);
  };

  const [allbackgrounds] = useState([
    "base",
    "cipherwheel",
    "leftcomp",
    "lockpad",
    "painting",
    "radioscreen",
    "painting2",
    "rightComp",
  ]);

  const [activeBackground, setactiveBackground] = useState(0);

  if (roomTwoCompleted) {
    return <Redirect to="/roomTwoResult" />;
  }

  return (
    <>
      <InfoBtn />

      {warden ? (
        <div
          className="room2SecretHintButton"
          onClick={() => {
            setshowSecretHint(!showSecretHint);
          }}
        >
          H
        </div>
      ) : (
        ""
      )}

      {showSecretHint ? (
        <div className="secretHintUsed">
          <h1>Hint Used?</h1>
          <div className="options">
            <div
              className="option"
              onClick={() => {
                setsecretHintUsed("Yes");
                setSecretHintUsedFirebase(gameCode, true);
              }}
            >
              <input
                className="radioButon"
                type="radio"
                value={secretHintUsed}
                checked={secretHintUsed === "Yes"}
                onChange={() => {
                  // to remove the warning
                }}
              />
              Yes
            </div>
            <div
              className="option"
              onClick={() => {
                setsecretHintUsed("No");
                setSecretHintUsedFirebase(gameCode, false);
              }}
            >
              <input
                className="radioButon"
                type="radio"
                value={secretHintUsed}
                checked={secretHintUsed === "No"}
              />
              No
            </div>
          </div>

          <h1>Forced to Room 3?</h1>
          <div className="options">
            <div
              className="option"
              onClick={() => {
                setforcedOutofRoom("Yes");
                setForcedToRoom3(gameCode, true);
              }}
            >
              <input
                className="radioButon"
                type="radio"
                value={forcedOutofRoom}
                checked={forcedOutofRoom === "Yes"}
                onChange={() => {
                  // to remove the warning
                }}
              />
              Yes
            </div>
            <div
              className="option"
              onClick={() => {
                setforcedOutofRoom("No");
                setForcedToRoom3(gameCode, false);
              }}
            >
              <input
                className="radioButon"
                type="radio"
                value={forcedOutofRoom}
                checked={forcedOutofRoom === "No"}
              />
              No
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {showImage1 ? (
        <div className="room2__imageContainer">
          <div
            className="roomTwoPaintings1 roomTwoPaintings"
            onClick={() => {
              setshowImage1(false);
            }}
          ></div>
          ;
        </div>
      ) : (
        ""
      )}

      {showImage2 ? (
        <div className="room2__imageContainer">
          <div
            className="roomTwoPaintings2 roomTwoPaintings"
            onClick={() => {
              setshowImage2(false);
            }}
          ></div>
          ;
        </div>
      ) : (
        ""
      )}

      {showCipherWheel ? (
        <div className="cipherWheel__container">
          <div
            className="cipherWheelCloseBtn"
            onClick={() => {
              setshowCipherWheel(false);
            }}
          >
            X
          </div>
          <CipherWheel />
        </div>
      ) : (
        ""
      )}

      <div className={`Keypad__container ${!showKeypad ? "dontDisplay" : ""}`}>
        <div
          className="KeypadCloseBtn"
          onClick={() => {
            setshowKeypad(false);
          }}
        >
          X
        </div>
        <KeypadPuzzle />
      </div>

      {showRadio ? (
        <div className="Radio__container">
          <div
            className="RadioCloseBtn"
            onClick={() => {
              setshowRadio(false);
            }}
          >
            X
          </div>
          <RadioPuzzle />
        </div>
      ) : (
        ""
      )}

      {currentlyLoading && !room2Loaded ? <Loading /> : ""}

      <div className={`room2 ${allbackgrounds[activeBackground]}`}>
        <div
          className="cipherwheelArea hoverArea hoverArea1"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(1);
          }}
          onClick={toCipherWheel}
        ></div>
        <Link
          to={"/hpl"}
          className="leftcompArea hoverArea hoverArea2"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(2);
          }}
        ></Link>
        <div
          className="lockpadArea hoverArea hoverArea3"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(3);
          }}
          onClick={toKeypad}
        ></div>
        <div
          className="paintingArea hoverArea hoverArea4"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(4);
          }}
          onClick={toPainting1}
        ></div>
        <div
          className="radioArea hoverArea hoverArea5"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(5);
          }}
          onClick={toRadio}
        ></div>
        <div
          className="painting2Area hoverArea hoverArea6"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(6);
          }}
          onClick={toPainting2}
        ></div>
        <Link
          to={"/hpr"}
          className="rightCompArea hoverArea hoverArea7"
          onMouseLeave={() => {
            setactiveBackground(0);
          }}
          onMouseEnter={() => {
            setactiveBackground(7);
          }}
        ></Link>
      </div>
    </>
  );
}

export default Room2;
