const puzzlesData = () => {
  const puzzles = {
    easy: {
      //17 is the number of lines
      //12 is number of symbols on each line
      symbols: `
        !'+*^+$/:'~'"%,^++&_;;:*
        $^/?/,^:^+?!*=#/':--*"_+
        |_-",!&?--%+#:_/-?'$-%@,
        @@"$,;?,:-#-!$#^$'%#@|_+
        "",$_"~"/@++%:;-%^|+&':#
        /!;=:@+:%*$++?+"""-$^-&^
        &*~?#/'~*|=&,_@!/?@';,:%
        ;?-+@@~;&'~"_@|+'^'%,;^=
        &&|*_$*=|;:?/@,_,;=~$";+
        "%;,@*@?@/#&;@/:@^#|^|'+
        ~+:#|_@:!:/'=_|@!#_=;$?=
        |_+:&&$!/~^"/'=~^/~**$=-
        ?|&;/%;$|_-,/,&@$@$$-,##
        %&-++;==-%@~^;''#~,#_+~_
        |~*,+",#;~@#_~,!"^;_";_|
        =%#++'~_|@|$|_##:%'/?@|%
        ,=__'@-#@|,*,|~&+-/@,?;+
      `,
      specialSymbols: [
        {
          string: "[$/`%@+]",
          position: {
            col: "LEFT",
            index: 22,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(=¨`%~|)",
          position: {
            col: "LEFT",
            index: 108,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[!^~,_&%]",
          position: {
            col: "LEFT",
            index: 187,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(:+$_*|)",
          position: {
            col: "RIGHT",
            index: 45,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[#@_;?+]",
          position: {
            col: "RIGHT",
            index: 109,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(+;~#_=&,)",
          position: {
            col: "RIGHT",
            index: 142,
          },
          action: "ATTEMPTS_REFILL",
        },
      ],
      duds: ["SLICKE", "LUGING", "VIANDS"],
      goalWord: {
        word: "NICKER",
        position: {
          col: "RIGHT",
          index: 102,
        },
      },
      words: [
        {
          word: "TICKER",
          position: {
            col: "LEFT",
            index: 10,
          },
        },
        {
          word: "TAFIAS",
          position: {
            col: "LEFT",
            index: 38,
          },
        },
        {
          word: "PEELER",
          position: {
            col: "LEFT",
            index: 52,
          },
        },
        {
          word: "PIPIER",
          position: {
            col: "LEFT",
            index: 85,
          },
        },
        {
          word: "SLICKE",
          position: {
            col: "LEFT",
            index: 102,
          },
        },
        {
          word: "SIMPER",
          position: {
            col: "LEFT",
            index: 125,
          },
        },
        {
          word: "LACKER",
          position: {
            col: "LEFT",
            index: 142,
          },
        },
        {
          word: "MILTER",
          position: {
            col: "LEFT",
            index: 175,
          },
        },
        {
          word: "DULLER",
          position: {
            col: "RIGHT",
            index: 24,
          },
        },
        {
          word: "LUGING",
          position: {
            col: "RIGHT",
            index: 37,
          },
        },
        {
          word: "REGAPES",
          position: {
            col: "RIGHT",
            index: 68,
          },
        },
        {
          word: "SEYAFFS",
          position: {
            col: "RIGHT",
            index: 88,
          },
        },
        {
          word: "RAMYAS",
          position: {
            col: "RIGHT",
            index: 122,
          },
        },
        {
          word: "GIADIS",
          position: {
            col: "RIGHT",
            index: 174,
          },
        },
        {
          word: "VIANDS",
          position: {
            col: "RIGHT",
            index: 187,
          },
        },
      ],
    },
    medium: {
      symbols: `
        #"';?:!_-/~?;$''"&"';"-_
        ?=+/@--=^=?^:;_%''+:$=+%
        ?@!^~,_&%=*~',|@~,;||/!:
        '?/&,+~~-|:/&&,+@'=,^,~_
        ?:@^-,?#,,%-=/%%'=!#=/~$
        ?/,,+#!'?@=|-?/?~'|$:+-!
        &/~!$#$"%-"-%,,^%@;;@#|$
        @'!_|'%"#@#''/-:"=|':!&&
        ,&/@";,";+'-=^'~#^?!&#-/
        -&%;$~#_!?*~//@''-;!|^%,
        =&;-|-@_-/'~'_$:/^/?:*#*
        *$@%&=?/;"-%@^;~%&&#'?=!
        +=@'''$_^$:+$_*|&#?=~==~
        !=-%&=^!$"&@'",/';$%:@+*
        ++#;=%'=::^#@_;?+~-=;~@~
        ~';=^""^!;=-:="^_:~,'*-^
        :-~'^~?|-'+;~#_=&,=;?:@|  
      `,
      specialSymbols: [
        {
          string: "[;+=^'~#]",
          position: {
            col: "LEFT",
            index: 45,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(=;~@~)",
          position: {
            col: "LEFT",
            index: 134,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[!%'=:]",
          position: {
            col: "LEFT",
            index: 195,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(/@;,|)",
          position: {
            col: "RIGHT",
            index: 32,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "[#,|@~,;|]",
          position: {
            col: "RIGHT",
            index: 88,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "(-|:/&&,)",
          position: {
            col: "RIGHT",
            index: 168,
          },
          action: "ATTEMPTS_REFILL",
        },
      ],
      duds: ["STEAMINESS", "CRUSTINESS", "SPOOKINESS"],
      goalWord: {
        word: "TWEEDINESS",
        position: {
          col: "LEFT",
          index: 116,
        },
      },
      words: [
        {
          word: "WARDENSHIP",
          position: {
            col: "LEFT",
            index: 100,
          },
        },
        {
          word: "FIDDLEBACK",
          position: {
            col: "LEFT",
            index: 18,
          },
        },
        {
          word: "DENTIFRICE",
          position: {
            col: "LEFT",
            index: 36,
          },
        },
        {
          word: "WINTERKILL",
          position: {
            col: "LEFT",
            index: 62,
          },
        },
        {
          word: "STEAMINESS",
          position: {
            col: "LEFT",
            index: 84,
          },
        },
        {
          word: "JAYWALKING",
          position: {
            col: "LEFT",
            index: 143,
          },
        },
        {
          word: "DEFLATIONS",
          position: {
            col: "LEFT",
            index: 185,
          },
        },
        {
          word: "COMMANDERY",
          position: {
            col: "RIGHT",
            index: 8,
          },
        },
        {
          word: "CONCISIONS",
          position: {
            col: "RIGHT",
            index: 22,
          },
        },
        {
          word: "DIMETHOATE",
          position: {
            col: "RIGHT",
            index: 57,
          },
        },
        {
          word: "CRUSTINESS",
          position: {
            col: "RIGHT",
            index: 78,
          },
        },
        {
          word: "POSTHUMOUS",
          position: {
            col: "RIGHT",
            index: 107,
          },
        },
        {
          word: "SPOOKINESS",
          position: {
            col: "RIGHT",
            index: 152,
          },
        },
        {
          word: "ROSINWEEDS",
          position: {
            col: "RIGHT",
            index: 184,
          },
        },
      ],
    },
    hard: {
      symbols: `
        !'+*^+$/:'~'"%,^++&_;;:*
        #~-'-//'&,=:^"+;^|~$_"%?
        "::$*#!~++;~_:?#*,~-#+^%
        ~='&^,?:&|+&,_'%!~?#?:~/
        __:?^??~_!,=*$?@+:-!?";|
        =|=|!|+"=##":*$_~*@"&'^&
        /",/*"?&&==;+?~~~+;'~!#_
        ^=,,#;&/%@*,-&=_+@#"/!*%
        +;#&!*,=&%!#=/;$/#+?"";;
        -##/%*|!!_=+'&&-*-$%''#*
        ,-&!!$#^?*;&#"/;_"-+,*~?
        --=_#$-!@-?||#@-&@-'!|^!
        $:/'?~^,_"@!!@/_+!#|~|,:
        ,@":%=-/::~-?$=$,|#^+!-$
        !@'|:%:=,~&#,/""";,"-:_+
        &=!+~--#=|^-,'%_^/$?*?/^
        @,'|&@@'@/-@-$#_,/#/"$;^
        =+:^'"#,#|=?@;$#+%""@%+|       
      `,
      specialSymbols: [
        {
          string: "[-#=|^-,']",
          position: {
            col: "LEFT",
            index: 2,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "(??~_!,=)",
          position: {
            col: "LEFT",
            index: 83,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "[|+&,_'%]",
          position: {
            col: "LEFT",
            index: 158,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "(^?*;&#/;)",
          position: {
            col: "RIGHT",
            index: 40,
          },
          action: "REMOVE_DUD",
        },
        {
          string: "['~'%,^+]",
          position: {
            col: "RIGHT",
            index: 93,
          },
          action: "ATTEMPTS_REFILL",
        },
        {
          string: "(;&#/&,)",
          position: {
            col: "RIGHT",
            index: 164,
          },
          action: "REMOVE_DUD",
        },
      ],
      duds: ["CHALLENGE", "WEDNESDAY", "IDENTICAL"],
      goalWord: {
        word: "CRACKSMAN",
        position: {
          col: "RIGHT",
          index: 178,
        },
      },
      words: [
        {
          word: "ORDINALLY",
          position: {
            col: "LEFT",
            index: 18,
          },
        },
        {
          word: "CHALLENGE",
          position: {
            col: "LEFT",
            index: 47,
          },
        },
        {
          word: "LEAFINESS",
          position: {
            col: "LEFT",
            index: 68,
          },
        },
        {
          word: "PHASEDOWN",
          position: {
            col: "LEFT",
            index: 104,
          },
        },
        {
          word: "WEDNESDAY",
          position: {
            col: "LEFT",
            index: 142,
          },
        },
        {
          word: "GRANTSMEN",
          position: {
            col: "LEFT",
            index: 179,
          },
        },
        {
          word: "CAPARISON",
          position: {
            col: "RIGHT",
            index: 27,
          },
        },
        {
          word: "CRACKINGS",
          position: {
            col: "RIGHT",
            index: 56,
          },
        },
        {
          word: "IDENTICAL",
          position: {
            col: "RIGHT",
            index: 79,
          },
        },
        {
          word: "CHATCHKAS",
          position: {
            col: "RIGHT",
            index: 123,
          },
        },
        {
          word: "CRANCHING",
          position: {
            col: "RIGHT",
            index: 144,
          },
        },
      ],
    },
  };

  return puzzles;
};

export default puzzlesData;
