import React, { useEffect, useState } from "react";

import room2Intro from "../../assets/gameAssets/ProfessorKhanIntro.mp4";
import { setRoomTwoStarted } from "../../firebase/firebase";
import { ref, onValue } from "firebase/database";

import { database } from "../../firebase/firebaseConfig";

import { Redirect } from "react-router";

import "./room2Entry.styles.scss";

import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

function Room2Entry() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const warden = JSON.parse(localStorage.getItem("WARDEN"));

  const [showStart, setShowStart] = useState(false);

  const [gameStarted, setgameStarted] = useState(false);
  const [muted, setmuted] = useState(true);

  useEffect(() => {
    const dbRefRoomTwoStarted = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/roomStarted`,
    );

    onValue(dbRefRoomTwoStarted, (snapshot) => {
      setgameStarted(snapshot.val());
    });
  }, []);

  if (gameStarted) {
    return <Redirect to="/room2" />;
  }

  return (
    <>
      <div className="room2Entry">
        <video
          autoPlay
          muted={muted}
          id="transitionVideo"
          onEnded={() => {
            setShowStart(true);
          }}
        >
          <source src={room2Intro} type="video/mp4" />
        </video>

        {showStart ? (
          <button
            className="gameIntro__startBtn"
            onClick={() => {
              setRoomTwoStarted(gameCode);
            }}
          >
            {" "}
            Go!{" "}
          </button>
        ) : (
          ""
        )}

        {warden ? (
          <button
            id="defaultBtn"
            onClick={() => {
              setRoomTwoStarted(gameCode);
            }}
          >
            SKIP AND START
          </button>
        ) : (
          ""
        )}
      </div>

      <div
        className="videoSound"
        onClick={() => {
          setmuted(!muted);
        }}
      >
        {!muted ? <img src={soundOn} alt="" /> : <img src={soundOff} alt="" />}
      </div>
    </>
  );
}

export default Room2Entry;
