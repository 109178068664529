import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { setMediumHardFirebase } from "../../firebase/firebase";
import { database } from "../../firebase/firebaseConfig";
import { ref, onValue } from "firebase/database";
import transitionVid from "../../assets/gameAssets/transitiontogrid2.mp4";
import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

import "./nextPuzzleChoice.styles.scss";
import InfoBtn from "../../components/infoBtn/infoBtn.component";

function NextPuzzleChoice() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const [toMedium, settoMedium] = useState(false);
  const [toVeryHard, settoVeryHard] = useState(false);
  const [showChoice, setshowChoice] = useState(false);
  const [muted, setmuted] = useState(true);

  const setMediumFirebase = () => {
    setMediumHardFirebase("medium", gameCode);
  };

  const setHardFirebase = () => {
    setMediumHardFirebase("veryHard", gameCode);
  };

  useEffect(() => {
    const dbRefMedium = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/nextPuzzleChoice/medium`,
    );
    const dbRefHard = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/nextPuzzleChoice/veryHard`,
    );

    // for medium
    onValue(dbRefMedium, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        settoMedium(data);
      }
    });

    // for very hard
    onValue(dbRefHard, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        settoVeryHard(data);
      }
    });
  }, []);

  if (toMedium) {
    return <Redirect to="/logicGrid2" />;
  }

  if (toVeryHard) {
    return <Redirect to="/logicGrid3" />;
  }

  return (
    <>
      <InfoBtn />
      <video
        autoPlay
        muted={muted}
        id="transitionVideo"
        onEnded={() => {
          setshowChoice(true);
        }}
      >
        <source src={transitionVid} type="video/mp4" />
      </video>

      {showChoice ? (
        <div className="choice">
          <button id="defaultBtn" onClick={setMediumFirebase}>
            Play It Safe
          </button>

          <button id="defaultBtn" onClick={setHardFirebase}>
            Risk It
          </button>
        </div>
      ) : (
        ""
      )}

      <div
        className="videoSound"
        onClick={() => {
          setmuted(!muted);
        }}
      >
        {!muted ? <img src={soundOn} alt="" /> : <img src={soundOff} alt="" />}
      </div>
    </>
  );
}

export default NextPuzzleChoice;
