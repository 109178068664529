import React from "react";
import {
  puzzleSolved,
  setHackingPuzzlePenaltyUsed,
  showPuzzleSolved,
} from "../hackingPuzzleFirebase";

const FailScreen = () => {
  const handleClick = () => {
    setHackingPuzzlePenaltyUsed("Left");
    puzzleSolved("Left");
    showPuzzleSolved("Left");
  };

  return (
    <div className="hp__main-wrapper">
      <div className="hp__effects-wrapper">
        <div className="hp__effects1">
          <div className="hp__effects2">
            <div className="hp__landscape hp__loading">
              <p className="txt hp__cp">
                &gt; TERMINAL LOCKED
                <br />
                <br />
                &gt; FATAL ERROR
                <br />
                <br />
                &gt; SPEND 500 POINTS TO FORCE UNLOCK?
              </p>
              <br />
              <br />
              <p className="txt hp__cp" onClick={handleClick}>
                &gt; ACCEPT
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailScreen;
