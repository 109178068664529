import { useState, useEffect, useRef, useCallback } from "react";
import generateGame from "../helpers/generateGame";
import Header from "./Header";
import Feedback from "../Feedback";
import GameTemplate from "./GameTemplate";
import {
  limitAttemptsRefillsComputer,
  puzzleSolved,
  setAttempts,
} from "../hackingPuzzleFirebase";
import { onValue, ref } from "firebase/database";
import { database } from "../../../firebase/firebaseConfig";
import { Link } from "react-router-dom";

const HackingPuzzle = ({
  attempts,
  puzzle,
  currentPuzzle,
  updateCurrentPuzzleState,
  puzzleData,
  gameCode,
}) => {
  const [showHackingPuzzleInstruction, setshowHackingPuzzleHint] =
    useState(false);

  const [limitAttemptsRefills, setlimitAttemptsRefills] = useState(3);

  const gameRefs = useRef([]); /** stores the words and special strings refs */
  const [gameContent, setGameContent] = useState({
    left: [],
    right: [],
  });

  const goalWord = useRef();

  const [userInput, setUserInput] = useState({
    value: "",
    type: "",
  });

  const [entry, setEntry] = useState("");
  const [runningPuzzle, setRunningPuzzle] = useState();

  const [duds, setDuds] = useState();
  const [usedSpecialStrings, setusedSpecialStrings] = useState([]);

  useEffect(() => {
    console.log(duds);
  }, [duds]);

  const resetEntry = () => {
    setEntry("");
  };

  const handleOnHover = (newEntry) => {
    setEntry(newEntry);
  };

  const handleSolvedPuzzle = () => {
    puzzleSolved("Right");
  };

  const handleClick = (e) => {
    const target = e.target;
    const classList = target.classList;

    if (classList.contains("hp__word")) {
      const word = classList[1];
      setUserInput({
        value: word,
        type: "WORD",
      });
    } else if (classList.contains("hp__symbol")) {
      let symbol = target.innerHTML;
      if (symbol === "&amp;") {
        symbol = "&";
      }

      setUserInput({
        value: symbol,
        type: "SYMBOL",
      });
    } else if (classList.contains("hp__special-symbol")) {
      const specialString = classList[1]
        .replace("hp__special-symbol", "")
        .trim();
      setUserInput({
        value: specialString,
        type: "SPECIAL_SYMBOL",
        action: classList[2],
      });

      deactivateSpecialString(specialString);
    }
  };

  const handleMouseEnterSymbol = useCallback((e, specialSymbolsRef) => {
    const target = e.target;
    const classList = target.classList;
    let symbol = target.innerHTML;

    if (
      (symbol === "(" || symbol === "[") &&
      classList.contains("hp__special-symbol")
    ) {
      const specialString = classList[1]
        .replace("hp__special-symbol", "")
        .trim();
      if (!classList.contains("hp__highlight")) {
        const string = classList[1];

        specialSymbolsRef.current.forEach((char) => {
          if (char.classList.contains(string)) {
            char.classList.add("hp__highlight");
          }
        });
      }

      handleOnHover(specialString);
    } else {
      if (symbol === "&amp;") {
        symbol = "&";
      }

      if (!classList.contains("hp__highlight")) {
        target.classList.add("hp__highlight");
      }

      handleOnHover(symbol);
    }
  }, []);

  const handleMouseLeaveSymbol = useCallback((e, specialSymbolsRef) => {
    const target = e.target;
    const classList = target.classList;

    if (classList.contains("hp__highlight")) {
      const string = classList[1];

      specialSymbolsRef.current.forEach((char) => {
        if (char.classList.contains(string)) {
          char.classList.remove("hp__highlight");
        }
      });
    }

    if (classList.contains("hp__highlight")) {
      target.classList.remove("hp__highlight");
    }

    handleOnHover("");
  }, []);

  const handleMouseEnterWord = (e, wordsRef) => {
    const target = e.target;
    const classList = target.classList;

    if (!classList.contains("word-removed")) {
      const word = classList[1].replace("hp__word", "").trim();

      if (!classList.contains("hp__highlight")) {
        const wordClass = classList[1];

        wordsRef.current.forEach((word) => {
          if (word.classList.contains(wordClass)) {
            word.classList.add("hp__highlight");
          }
        });
        handleOnHover(word);
      }
    }
  };

  const handleMouseLeaveWord = (e, wordsRef) => {
    const target = e.target;
    const classList = target.classList;

    if (classList.contains("hp__highlight")) {
      const wordClass = classList[1];

      wordsRef.current.forEach((word) => {
        if (word.classList.contains(wordClass)) {
          word.classList.remove("hp__highlight");
        }
      });
      handleOnHover("");
    }
  };

  const handleAttempts = (action) => {
    if (action === "DECREASE") {
      setAttempts("Right", attempts - 1);
    }
  };

  const removeDud = () => {
    if (duds.length > 0) {
      const dud = duds[0];
      gameRefs.current.forEach((elem) => {
        if (elem.classList.contains(dud)) {
          elem.classList.add("word-removed");
          elem.innerHTML = "_";
        }
      });

      let newDuds = [...duds];
      let removedDud = newDuds.shift();

      updateCurrentPuzzleState({
        removedDuds: [...puzzleData.removedDuds, removedDud],
      });

      console.log(newDuds);

      setDuds([...newDuds]);
    }
  };

  const deactivateSpecialString = (specialString) => {
    gameRefs.current.forEach((elem) => {
      if (elem.classList.contains(specialString)) {
        elem.classList.remove(
          "hp__special-symbol",
          specialString,
          "ATTEMPTS_REFILL",
          "SPECIAL_SYMBOL",
        );
        elem.classList.add("hp__symbol");
      }
    });

    setusedSpecialStrings([specialString, ...usedSpecialStrings]);
  };

  useEffect(() => {
    if (
      attempts < 3 &&
      userInput.type === "SPECIAL_SYMBOL" &&
      userInput.action === "ATTEMPTS_REFILL" &&
      limitAttemptsRefills
    ) {
      setAttempts("Right", attempts + 1);

      limitAttemptsRefillsComputer(
        "Right",
        limitAttemptsRefills - 1,
        currentPuzzle,
      );
    }

    if (userInput.action === "REMOVE_DUD") {
      removeDud();
    }
  }, [userInput]);

  useEffect(() => {
    updateCurrentPuzzleState({
      usedSpecialStrings: usedSpecialStrings,
    });
  }, [usedSpecialStrings]);

  useEffect(() => {
    if (currentPuzzle <= 3 && currentPuzzle !== runningPuzzle) {
      goalWord.current = puzzle.goalWord.word;
      setDuds(puzzle.duds);
      setGameContent(() => ({ ...generateGame(puzzle) }));
      setRunningPuzzle(currentPuzzle); /** */
    }

    return () => {
      setUserInput({
        value: "",
        type: "",
      });
    };
  }, [puzzle]);

  useEffect(() => {
    /** */
    puzzleData.removedDuds.forEach((dud) => {
      gameRefs.current.forEach((elem) => {
        if (elem.classList.contains(dud)) {
          elem.classList.add("word-removed");
          elem.innerHTML = "_";
        }
      });
    });

    puzzleData.usedSpecialStrings.forEach((specialString) => {
      gameRefs.current.forEach((elem) => {
        if (elem.classList.contains(specialString)) {
          elem.classList.remove(
            "hp__special-symbol",
            specialString,
            "ATTEMPTS_REFILL",
            "SPECIAL_SYMBOL",
          );
          elem.classList.add("hp__symbol");
        }
      });
    });
    /** */
  }, [puzzleData]);

  useEffect(() => {
    const dbRefAttemptsRefills = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/attemptsRefillsPuzzle${currentPuzzle}`,
    );

    onValue(dbRefAttemptsRefills, (snapshot) => {
      const data = snapshot.val();
      console.log(data);
      console.log("funcCalled refills");
      setlimitAttemptsRefills(data);
    });
  }, []);

  if (gameContent.left.length && gameContent.right.length) {
    return (
      <>
        <div
          className="hackingPuzzleInstruction"
          onClick={() => {
            setshowHackingPuzzleHint(!showHackingPuzzleInstruction);
          }}
        >
          {showHackingPuzzleInstruction ? "X" : "i"}
        </div>

        {showHackingPuzzleInstruction ? (
          <div className="hackingPuzzleHintInstructionContent">
            <p className="txt hp__cp heading">INSTRUCTIONS</p>

            <p className="txt hp__cp para">
              &gt;Find the correct password in the code by highlighting
              <br />
              &nbsp;a word and pressing ENTER
              <br />
              <br />
              &gt;The terminal will lock after 3 consecutive unsuccessful
              <br />
              &nbsp;attempts
              <br />
              <br />
              &gt;Find items in brackets [] or parentheses ()
              <br />
              &nbsp;to either remove duds or reset tries
            </p>
          </div>
        ) : (
          ""
        )}

        <Link className="back__btn" to="/room2">
          Back
        </Link>

        <div className="hp__hacking-symbols">
          <div className="hp__main-wrapper">
            <div className="hp__effects-wrapper hp__terminal-off">
              <div className="hp__effects1">
                <div className="hp__effects2">
                  <div className="hp__hacking hp__landscape">
                    <div className="hp__rest">
                      <Header attempts={attempts} />
                      <GameTemplate
                        handleMouseEnterSymbol={handleMouseEnterSymbol}
                        handleMouseLeaveSymbol={handleMouseLeaveSymbol}
                        handleMouseEnterWord={handleMouseEnterWord}
                        handleMouseLeaveWord={handleMouseLeaveWord}
                        handleOnHover={handleOnHover}
                        handleClick={handleClick}
                        attempts={attempts}
                        gameContent={{ ...gameContent }}
                        goalWord={goalWord}
                        currentPuzzle={runningPuzzle}
                        ref={gameRefs}
                      />
                      <Feedback
                        entry={entry}
                        resetEntry={resetEntry}
                        userInput={userInput}
                        goalWord={goalWord.current}
                        attempts={attempts}
                        currentPuzzle={currentPuzzle}
                        handleAttempts={handleAttempts}
                        handleSolvedPuzzle={handleSolvedPuzzle}
                        updateCurrentPuzzleState={updateCurrentPuzzleState}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default HackingPuzzle;
