import React, { useState } from "react";

import "./cipherWheel.styles.css";

import innerCipherWheel from "../../assets/cipherwheel/innerWheel.png";

import rotateArrow from "../../assets/cipherwheel/reload.png";
import ClickSound from "../../assets/cipherwheel/sounds/CipherWheelClick.mp3";
import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

const CipherWheel = () => {
  const [rotationDegree, setrotationDegree] = useState(242);

  const [soundState, setsoundState] = useState(true);

  let audio = new Audio(ClickSound);

  const rotateRight = () => {
    if (soundState) audio.play();
    setrotationDegree(rotationDegree + 13.85);
  };

  const resetCipher = () => {
    if (soundState) audio.play();
    setrotationDegree(242);
  };

  return (
    <div className="cipherWheel">
      <div
        className="soundButton"
        onClick={() => {
          setsoundState(!soundState);
        }}
      >
        {soundState ? (
          <img src={soundOn} alt="" />
        ) : (
          <img src={soundOff} alt="" />
        )}
      </div>
      <div className="cipherWheelContainer">
        <div className="outerCipherWheel">
          <img
            className="innerCipherWheel"
            src={innerCipherWheel}
            alt="innerCipherWheel"
            style={{ transform: `rotate(${rotationDegree}deg)` }}
          />
          <img
            onClick={rotateRight}
            className="rightArrow arrow"
            src={rotateArrow}
            alt=""
          />
          <button onClick={resetCipher} className="cipher__resetBtn">
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default CipherWheel;
