import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import UserMarker from "./UserMarker";
import MapControls from "./MapControls";
import { setUserMarkerLatLang } from "./GeoGuesserFirebase";
import { onValue, ref } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

const aspectRatio = 1.25;
const inactiveWidth = 16; //vw
const inactiveHeight = inactiveWidth / aspectRatio; //vw

const activeWidths = [30, 45, 65]; //vw
const activeHeights = [
  activeWidths[0] / aspectRatio,
  activeWidths[1] / aspectRatio,
  activeWidths[2] / aspectRatio,
]; // vw

const mapOpacityValues = {
  inactive: 0.6,
  active: 1,
};

const createMapOptions = () => {
  return {
    fullscreenControl: false,
  };
};

const config = {
  center: {
    lat: 40.33,
    lng: -40.33,
  },
  zoom: 1,
  fullscreenControl: false,
};

const Map = ({ apiKey, location, storeScore }) => {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const [showConfirmModal, setshowConfirmModal] = useState(false);

  // for syncing up the pin
  const [userMarkerLat, setuserMarkerLat] = useState(0);
  const [userMarkerLng, setuserMarkerLng] = useState(0);

  /** */
  const [displayMap, setDisplayMap] = useState(false);
  const intervalRef = useRef();
  /* */
  const [showControls, setShowControls] = useState(false);
  const [hideCloseMapBtn, setHideCloseMapBtn] = useState(true);
  const [activeMap, setActiveMap] = useState(true);
  const [mapDimensionsIndex, setMapDimensionsIndex] = useState(0);

  const [smallScreen, setSmallScreen] = useState(false);
  const smallScreenRef = useRef(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const [showMap, setShowMap] = useState(false);
  const [stickMap, setStickMap] = useState(false);

  const [marker, setMarker] = useState({
    lat: 40.33,
    lng: -40.33,
  });

  const mapsRef = useRef();
  const delayInterval = useRef();

  const handleApiLoaded = (map, maps) => {
    let lat;
    let lng;
    mapsRef.current = maps;

    // use map and maps objects
    map.addListener("click", (e) => {
      lat = e.latLng.lat();
      lng = e.latLng.lng();

      // set lat lang via firebase
      setUserMarkerLatLang(gameCode, lat, lng);
    });
  };

  const handlePosition = () => {
    setshowConfirmModal(false);
    const distance = Math.floor(
      mapsRef.current.geometry.spherical.computeDistanceBetween(
        { lat: marker.lat, lng: marker.lng },
        location,
      ),
    );
    storeScore(distance, marker);
  };

  const activateMap = () => {
    clearInterval(delayInterval.current);
    if (!smallScreen) {
      setShowControls(true);
    }
    setActiveMap(true);
  };

  const deactivateMap = () => {
    clearInterval(delayInterval.current);
    if (!stickMap) {
      delayInterval.current = setInterval(function () {
        setShowControls(false);
        setActiveMap(false);
      }, 2000);
    }
  };

  const increaseSize = () => {
    clearInterval(delayInterval.current);
    setMapDimensionsIndex((state) => {
      if (state < activeWidths.length - 1) {
        return state + 1;
      }
      return state;
    });
  };

  const decreaseSize = () => {
    clearInterval(delayInterval.current);
    setMapDimensionsIndex((state) => {
      if (state > 0) {
        return state - 1;
      }
      return state;
    });
  };

  const handleStickyMap = () => {
    clearInterval(delayInterval.current);
    setStickMap(!stickMap);
  };

  const handleMap = () => {
    setShowMap(!showMap);
    setHideCloseMapBtn(!hideCloseMapBtn);
  };

  const touchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  const generateMapStyle = () => {
    if (smallScreen) {
      return {
        opacity: mapOpacityValues.active,
      };
    } else {
      if (activeMap) {
        return {
          opacity: mapOpacityValues.active,
          width: `${activeWidths[mapDimensionsIndex]}vw`,
          height: `${activeHeights[mapDimensionsIndex]}vw`,
        };
      } else {
        return {
          opacity: mapOpacityValues.inactive,
          width: `${inactiveWidth}vw`,
          height: `${inactiveHeight}vw`,
        };
      }
    }
  };

  // timer

  const [timerRed, settimerRed] = useState(false);

  useEffect(() => {
    const start = new Date().getTime() / 1000;
    const secondsTimer = setInterval(() => {
      const currentTime = 180 - Math.round(new Date().getTime() / 1000 - start);
      const minutes = ~~((currentTime % 3600) / 60);
      const secs = ~~currentTime % 60;

      // makes timer red
      if (currentTime < 60) {
        if (!timerRed) settimerRed(true);
      }
      if (document.querySelector(".geoguesser__timer"))
        document.querySelector(".geoguesser__timer").textContent =
          `${minutes > 0 ? `${minutes} mins` : ""} ${secs > 0 || minutes > 0 ? `${secs} secs remaining` : ""}`;
    }, 1000);

    return () => clearInterval(secondsTimer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handlePosition();
    }, 180000);
    return () => clearTimeout(timer);
  }, []);

  // //////////////////////////// firebase stuff

  useEffect(() => {
    const dbRefUserMarker = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/result/UserMarker`,
    );

    // subscribes to values of user marker
    onValue(
      dbRefUserMarker,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setuserMarkerLat(data.lat);
          setuserMarkerLng(data.lng);
        }
      },
      [],
    );
  });

  useEffect(() => {
    setMarker({
      lat: userMarkerLat,
      lng: userMarkerLng,
    });
  }, [userMarkerLat, userMarkerLng]);

  // //////////////////////////// firebase stuff

  useEffect(() => {
    intervalRef.current = setInterval(function () {
      setDisplayMap(true);
    }, 3000);

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width <= 880) {
        if (!smallScreenRef.current) {
          smallScreenRef.current = true;
          setSmallScreen(true);
        }
      } else {
        if (smallScreenRef.current) {
          smallScreenRef.current = false;
          setSmallScreen(false);
        }
      }
    });

    if (touchDevice()) {
      setIsTouchDevice(true);
      setActiveMap(true);
    } else {
      setIsTouchDevice(false);
      setActiveMap(false);
    }

    resizeObserver.observe(document.getElementById("root"));

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (isTouchDevice && !showControls && !smallScreen) {
      setShowControls(true);
    } else {
      setShowControls(false);
    }
  }, [isTouchDevice]);

  useEffect(() => {
    if (smallScreen) {
      if (showMap) {
        setShowMap(false);
      }

      if (!hideCloseMapBtn) {
        setHideCloseMapBtn(true);
      }

      if (showControls) {
        setShowControls(false);
      }
    } else {
      if (!showMap) {
        setShowMap(true);
      }

      if (!showControls) {
        setShowControls(true);
      }

      if (isTouchDevice) {
        if (!showControls) {
          setShowControls(true);
        }
      } else {
        if (showControls) {
          setShowControls(false);
        }
      }
    }
  }, [smallScreen]);

  if (!displayMap) return null;

  return (
    <>
      {showConfirmModal ? (
        <>
          <div className="confirmAns__overlay"></div>
          <div className="confirmAns">
            <div className="message_header">
              <h1> Submit Answer? </h1>
            </div>
            <div className="message_body">
              <div className="message__text">
                Are you sure you want to submit on the behalf of your team ?
              </div>
              <div className="puzzle__btn-container">
                <button
                  className="message-btn"
                  onClick={() => {
                    setshowConfirmModal(false);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="message-btn submit-message-btn"
                  onClick={handlePosition}
                  type="button"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="geo__map-wrapper">
        <h1
          className={`geoguesser__timer ${timerRed ? "guesser__timerRed" : ""}`}
        >
          {}
        </h1>
        <div
          className="geo__map-container"
          onMouseOver={activateMap}
          onMouseLeave={deactivateMap}
          style={generateMapStyle()}
        >
          <MapControls
            activateMap={activateMap}
            showControls={showControls}
            increaseSize={increaseSize}
            decreaseSize={decreaseSize}
            handleStickyMap={handleStickyMap}
            handleMap={handleMap}
            smallScreen={smallScreen}
            hideCloseMapBtn={hideCloseMapBtn}
          />

          <div
            className="geo__map"
            style={showMap ? { display: "block" } : { display: "none" }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: apiKey }}
              defaultCenter={config.center}
              defaultZoom={config.zoom}
              options={createMapOptions}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >
              {marker ? (
                <UserMarker
                  lat={marker.lat}
                  lng={marker.lng}
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "50px",
                    overflow: "hidden",
                  }}
                />
              ) : null}
            </GoogleMapReact>
          </div>
          {smallScreen && !showMap ? (
            <button onClick={handleMap} className="geo__btn-cta">
              Show Guess Map
            </button>
          ) : (
            <button
              onClick={() => {
                setshowConfirmModal(true);
              }}
              className="geo__btn-cta"
            >
              Guess
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Map;
