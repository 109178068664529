import { useEffect, useState } from "react";
import HackingPuzzle from "./HackingPuzzle";
import StartScreen from "../StartScreen";
import puzzlesData from "./data/puzzlesData";
import "../assets/css/style.css";
import SolvedScreen from "./solvedScreen";
import FailScreen from "./hackingPuzzleFailScreen";
import GlobalAttemptsScreen from "../globalAttempts";

import {
  setAttempts,
  setcurrentPuzzle,
  showGlobalAttempts,
  showPuzzleSolved,
} from "../hackingPuzzleFirebase";
import { onValue, ref } from "firebase/database";
import { database } from "../../../firebase/firebaseConfig";

const Game = () => {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const refreshed = JSON.parse(localStorage.getItem("HPR_REFRESHED"));

  if (!refreshed) {
    localStorage.setItem("HPR_REFRESHED", JSON.stringify(true));
    window.location.reload();
  }

  const [attempts, setattempts] = useState(3);

  const [puzzles, setPuzzles] = useState();
  const [start, setStart] = useState(false);

  const [rightCurrentPuzzle, setrightCurrentPuzzle] = useState(1);

  const [puzzleSolved, setPuzzleSolved] = useState(false);

  const [showRightPuzzleSolved, setshowRightPuzzleSolved] = useState(false);
  const [showRightGlobalAttempts, setshowRightGlobalAttempts] = useState(false);

  /** */
  const [easyPuzzle, setEasyPuzzle] = useState({
    usedSpecialStrings: [],
    removedDuds: [],
    feedbackLogs: [],
    attempts: 3,
  });

  const [mediumPuzzle, setMediumPuzzle] = useState({
    usedSpecialStrings: [],
    removedDuds: [],
    feedbackLogs: [],
    attempts: 3,
  });

  const [hardPuzzle, setHardPuzzle] = useState({
    usedSpecialStrings: [],
    removedDuds: [],
    feedbackLogs: [],
    attempts: 3,
  });
  /** */

  const updateCurrentPuzzleState = (newData) => {
    if (rightCurrentPuzzle === 1) {
      setEasyPuzzle((state) => ({
        ...state,
        ...newData,
      }));
    } else if (rightCurrentPuzzle === 2) {
      setMediumPuzzle((state) => ({
        ...state,
        ...newData,
      }));
    } else {
      setHardPuzzle((state) => ({
        ...state,
        ...newData,
      }));
    }
  };

  const startGame = () => {
    setStart(true);
  };

  const getCurrentPuzzle = () => {
    if (rightCurrentPuzzle === 1) {
      return { ...puzzles.easy };
    } else if (rightCurrentPuzzle === 2) {
      return { ...puzzles.medium };
    } else if (rightCurrentPuzzle === 3) {
      return { ...puzzles.hard };
    }
  };

  const getCurrentPuzzleData = () => {
    if (rightCurrentPuzzle === 1) {
      return easyPuzzle;
    } else if (rightCurrentPuzzle === 2) {
      return mediumPuzzle;
    } else if (rightCurrentPuzzle === 3) {
      return hardPuzzle;
    }
  };

  const startNextPuzzle = () => {
    if (attempts === 0 && rightCurrentPuzzle <= 3) {
      let nextPuzzle = rightCurrentPuzzle + 1;

      setcurrentPuzzle("Right", nextPuzzle);

      /** reset state */
      setAttempts("Right", 3);
    }

    showGlobalAttempts("Right", false);

    updateCurrentPuzzleState({
      attempts: attempts,
    });
  };

  // firebase //////////////////
  useEffect(() => {
    const dbRefShowPuzzleSolved = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/showPuzzleSolved`,
    );
    const dbRefShowGlobalAttempts = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/showGlobalAttempts`,
    );
    const dbRefSetcurrentPuzzle = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/currentPuzzle`,
    );
    const dbRefsetAttempts = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/attempts`,
    );
    const dbRefPuzzleSolved = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/solved`,
    );

    //  Read game completed or not
    onValue(dbRefShowPuzzleSolved, (snapshot) => {
      const data = snapshot.val();

      setshowRightPuzzleSolved(data);
    });

    onValue(dbRefShowGlobalAttempts, (snapshot) => {
      const data = snapshot.val();

      setshowRightGlobalAttempts(data);
    });

    onValue(dbRefSetcurrentPuzzle, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setrightCurrentPuzzle(data);
      }
    });

    onValue(dbRefsetAttempts, (snapshot) => {
      const data = snapshot.val();
      if (data !== null) {
        console.log("attempts", data);
        setattempts(data);
      }
    });

    onValue(dbRefPuzzleSolved, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPuzzleSolved(data);
      }
    });
  }, []);

  // firebase end //////////////

  useEffect(() => {
    if (attempts === 0 && rightCurrentPuzzle <= 2) {
      showGlobalAttempts("Right", true);
      return;
    }

    if (attempts === 0 && rightCurrentPuzzle <= 3) {
      startNextPuzzle();
      return;
    }
  }, [attempts]);

  useEffect(() => {
    /** In case the puzzle is solved */
    if (puzzleSolved && rightCurrentPuzzle <= 3) {
      // show puzzle completed
      showPuzzleSolved("Right");
    }
  }, [puzzleSolved]);

  useEffect(() => {
    setPuzzles({ ...puzzlesData() });
  }, []);

  if (!start) {
    return <StartScreen onClick={startGame} />;
  } else if (showRightGlobalAttempts) {
    return (
      <GlobalAttemptsScreen
        attempts={3 - rightCurrentPuzzle}
        startNextPuzzle={startNextPuzzle}
      />
    );
  } else if (showRightPuzzleSolved) {
    return <SolvedScreen />;
  } else if (puzzles && rightCurrentPuzzle <= 3) {
    return (
      <HackingPuzzle
        attempts={attempts}
        gameCode={gameCode}
        puzzle={getCurrentPuzzle()}
        currentPuzzle={rightCurrentPuzzle}
        updateCurrentPuzzleState={updateCurrentPuzzleState}
        puzzleData={getCurrentPuzzleData()}
      />
    );
  } else {
    return <FailScreen />;
  }
};

export default Game;
