import React from "react";
import { Link } from "react-router-dom";

const StartScreen = ({ onClick }) => {
  return (
    <div className="hp__main-wrapper">
      <Link className="back__btn" to="/room2">
        Back
      </Link>
      <div className="hp__effects-wrapper">
        <div className="hp__effects1">
          <div className="hp__effects2">
            <div className="hp__landscape hp__loading" onClick={onClick}>
              <p className="txt hp__cp">
                WELCOME TO THE INTERNATIONAL SPY ACADEMY (ISA) MAINFRAME
                <br />
                <br />
                &gt;SET TERMINAL/INQUIRE
                <br />
                ISA-9000
                <br />
                &gt;SET FILE/PROTECTION = OWNER:KHAN ACCOUNTS
              </p>
              <p className="txt hp__cp">
                <br />
                <br />
                &gt;INSTRUCTIONS
              </p>
              <p className="txt hp__cp">
                &gt;Find the correct password in the code by highlighting
                <br />
                &nbsp;a word and clicking on it to confirm
                <br />
                <br />
                &gt;The terminal will lock after 3 consecutive unsuccessful
                <br />
                &nbsp;attempts
                <br />
                <br />
                &gt;Find items in brackets [] or parentheses ()
                <br />
                &nbsp;to either remove duds or reset tries
              </p>
              <p className="txt hp__cp">
                <br />
                <br />
                &gt;CLICK ANYWHERE TO BEGIN
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
