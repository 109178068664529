import React from "react";

const Status = ({ round, score }) => {
  return (
    <div className="geo__status-wrapper">
      <div>
        <div className="geo__status-title">Round</div>
        <div className="geo__status-value">{round}/5</div>
      </div>
      <div>
        <div className="geo__status-title">Score</div>
        <div className="geo__status-value">{score}</div>
      </div>
    </div>
  );
};

export default Status;
