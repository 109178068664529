import { ref, set } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

export const setGeoGuesserShowResult = (gameCode, value) => {
  const dbRefGeoGuesserShowResult = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/showResult`,
  );
  set(dbRefGeoGuesserShowResult, value);
};

export const setGeoGuesserShowNextRound = (gameCode, value) => {
  const dbRefGeoGuesserShowNextRound = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/showNextRound`,
  );
  set(dbRefGeoGuesserShowNextRound, value);
};

export const setGeoGuesserRound = (gameCode, value) => {
  const dbRef = ref(database, `Rooms/${gameCode}/RoomStates/room3/round`);
  set(dbRef, value);
};

export const setGeoGuesserTotalScore = (gameCode, value, round) => {
  const dbRef = ref(database, `Rooms/${gameCode}/RoomStates/room3/totalScore`);
  set(dbRef, value);
};

export const setGeoGuess = (gameCode, guess) => {
  const dbRef = ref(database, `Rooms/${gameCode}/RoomStates/room3/guess`);
  set(dbRef, guess);
};

export const setGeoGuesserPoints = (gameCode, value, round) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/result/round${round}/points`,
  );
  set(dbRef, value);
};

export const setGeoGuesserDistances = (gameCode, value, round) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/result/round${round}/distances`,
  );
  set(dbRef, value / 1000);
};

export const setGeoGuesserLats = (gameCode, value, round) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/result/round${round}/lat`,
  );
  set(dbRef, value);
};

export const setGeoGuesserLangs = (gameCode, value, round) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/result/round${round}/lang`,
  );
  set(dbRef, value);
};

export const setUserMarkerLatLang = (gameCode, lat, lng) => {
  const dbRef = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room3/result/UserMarker`,
  );
  set(dbRef, {
    lat,
    lng,
  });
};
