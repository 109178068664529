import React, { useEffect, useState } from "react";
import { fetchData, getRoomData, wipeDatabase } from "./downloadFromFirebase";

import "./downloadWipe.styles.scss";

function DownloadWipe() {
  const [password, setpassword] = useState("");
  const [roomCode, setroomCode] = useState("");
  const [inCorrectPass, setinCorrectPass] = useState(false);
  const [success, setsuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== "pass") {
      setinCorrectPass(true);
      return;
    }

    getRoomData(roomCode.toUpperCase());
    console.log(password);
    setsuccess(true);
  };

  const handlefetchData = () => {
    if (password !== "pass") {
      setinCorrectPass(true);

      return;
    }
    fetchData();
    setsuccess(true);
  };

  const handlewipeDatabase = () => {
    if (password !== "pass") {
      setinCorrectPass(true);

      return;
    }
    wipeDatabase();
    setsuccess(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setinCorrectPass(false);
    }, 3000);
  }, [inCorrectPass]);

  useEffect(() => {
    setTimeout(() => {
      setsuccess(false);
    }, 3000);
  }, [success]);

  return (
    <div className="downloadWipe">
      {inCorrectPass ? (
        <div className="downloadWipe__incorrectPass">Incorrect Password!</div>
      ) : (
        ""
      )}

      {success ? <div className="downloadWipe__success">Success!</div> : ""}

      <div className="downloadWipe__container">
        <h1>SpyIsland Admin</h1>

        <h3>
          {" "}
          <span>&#9888;</span> Careful! These actions are irreversible
        </h3>

        <form>
          <label>Please enter the password</label>
          <input
            type="password"
            onChange={(e) => {
              setpassword(e.target.value);
            }}
            placeholder="Enter Password"
          />
        </form>

        <button id="defaultBtn" onClick={handlefetchData}>
          Download
        </button>
        <button className="downloadWipe__wipeBtn" onClick={handlewipeDatabase}>
          Wipe
        </button>

        <form onSubmit={handleSubmit}>
          <label>Get Room Specific Data</label>
          <input
            type="text"
            onChange={(e) => {
              setroomCode(e.target.value);
            }}
            placeholder="Enter Room Code"
          />
          <input type="submit" value={"Download"} id="defaultBtn" />
        </form>
      </div>
    </div>
  );
}

export default DownloadWipe;
