import { onValue, ref } from "@firebase/database";
import React, { useEffect, useState } from "react";

import "./overallResult.page.styles.scss";

import mainHUD from "../../assets/gameAssets/mainHUD.mp4";
import { database } from "../../firebase/firebaseConfig";

import GradeA from "../../assets/gameAssets/GradeA.png";
import GradeB from "../../assets/gameAssets/GradeB.png";
import GradeC from "../../assets/gameAssets/GradeC.png";
import GradeD from "../../assets/gameAssets/GradeD.png";
import GradeF from "../../assets/gameAssets/GradeF.png";
import InfoBtn from "../../components/infoBtn/infoBtn.component";
import { setOverAllScoreFirebase } from "../../firebase/firebase";

function OverallResults() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  // Global
  const [roomOneTotal, setroomOneTotal] = useState(0);
  const [roomTwoTotal, setroomTwoTotal] = useState(0);
  const [roomThreeTotal, setroomThreeTotal] = useState(0);
  const [timeBonusPoints, settimeBonusPoints] = useState(0);
  const [overallScore, setoverallScore] = useState(0);

  const [showRow1, setshowRow1] = useState(false);
  const [showRow2, setshowRow2] = useState(false);
  const [showRow3, setshowRow3] = useState(false);
  const [showRow4, setshowRow4] = useState(false);
  const [showRow5, setshowRow5] = useState(false);
  const [showRow5Highlight, setshowRow5Highlight] = useState(false);

  const [showImage, setshowImage] = useState(false);

  const [GradesImages] = useState([GradeA, GradeB, GradeC, GradeD, GradeF]);

  const [grade, setgrade] = useState(0);

  useEffect(() => {
    const dbRefRoomOneTotal = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/totalScore`,
    );
    const dbRefRoomTwoTotal = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/totalScore`,
    );
    const dbRefRoomThreeTotal = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/totalScore`,
    );
    const dbRefTimeBonusPoints = ref(
      database,
      `Rooms/${gameCode}/timeBonusPoints`,
    );

    onValue(dbRefRoomOneTotal, (snapshot) => {
      const data = snapshot.val();
      console.log(data, "one");
      setroomOneTotal(data);
    });

    onValue(dbRefRoomTwoTotal, (snapshot) => {
      const data = snapshot.val();
      console.log(data, "two");
      setroomTwoTotal(data);
    });

    onValue(dbRefRoomThreeTotal, (snapshot) => {
      const data = snapshot.val();
      console.log(data, "three");
      setroomThreeTotal(data);
    });

    onValue(dbRefTimeBonusPoints, (snapshot) => {
      const data = snapshot.val();
      settimeBonusPoints(data, "time");
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowImage(true);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRow5(true);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRow5Highlight(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRow4(true);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRow3(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRow2(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setshowRow1(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const total =
      roomOneTotal + roomTwoTotal + roomThreeTotal + timeBonusPoints;

    setoverallScore(total);

    if (total >= 3200) {
      setgrade(0); // A Grade
    } else if (total >= 2200 && total < 3200) {
      setgrade(1); // B Grade
    } else if (total >= 1200 && total < 2200) {
      setgrade(2); // C Grade
    } else if (total >= 200 && total < 1200) {
      setgrade(3); // D Grade
    } else {
      setgrade(4); // E Grade
    }
  }, [roomOneTotal, roomTwoTotal, roomThreeTotal, timeBonusPoints]);

  if (showRow5) {
    setOverAllScoreFirebase(gameCode, overallScore);
  }

  return (
    <>
      <InfoBtn />
      <video autoPlay muted loop id="HUDvideoFull">
        <source src={mainHUD} type="video/mp4" />
      </video>

      <div className="overallResult">
        <div className="overallResult__right">
          {showImage ? <img src={GradesImages[grade]} alt="" /> : ""}
        </div>
        <div className="overallResult__left">
          <h1>FINAL EXAM RESULTS</h1>
          <div className="resultTable">
            <div className="row row1">
              <div className="col col1">STAGE</div>
              <div className="col col2">YOUR SCORE</div>
              <div className="col col3">MAX POSSIBLE SCORE</div>
            </div>

            <div className="row row2">
              {showRow1 ? (
                <>
                  <div className="col col1">
                    Stage 1&nbsp;&nbsp;(Professor Tang):
                  </div>
                  <div className="col col2">{roomOneTotal}</div>
                  <div className="col col3">2300</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row3">
              {showRow2 ? (
                <>
                  <div className="col col1">
                    Stage 2&nbsp;&nbsp;(Professor Khan):
                  </div>
                  <div className="col col2">{roomTwoTotal}</div>
                  <div className="col col3">500</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row4">
              {showRow3 ? (
                <>
                  <div className="col col1">
                    Stage 3&nbsp;&nbsp;(Professor Farrow):
                  </div>
                  <div className="col col2">{roomThreeTotal}</div>
                  <div className="col col3">2500</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row5">
              {showRow4 ? (
                <>
                  <div className="col col1">Overall Time Bonus:</div>
                  <div className="col col2">{timeBonusPoints}</div>
                  <div className="col col3">3600</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div
              className={`row row6 ${showRow5Highlight ? "row6Highlight" : ""}`}
            >
              {showRow5 ? (
                <>
                  <div className="col col1">Total:</div>
                  <div className="col col2">{overallScore}</div>
                  <div className="col col3">8900</div>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OverallResults;
