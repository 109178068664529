import { onValue, ref } from "@firebase/database";
import React, { useEffect, useState } from "react";

import "./roomTwoResult.page.styles.scss";

import {
  setRoomTwoTotalFirebase,
  setToRoom3IntroTrue,
} from "../../firebase/firebase";
import { database } from "../../firebase/firebaseConfig";

import { Redirect } from "react-router";
import InfoBtn from "../../components/infoBtn/infoBtn.component";

function RoomTwoResult() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const [leftComputerPenalty, setleftComputerPenalty] = useState(false);
  const [leftComputerPenaltyPoints, setleftComputerPenaltyPoints] = useState(0);

  const [secretHintPentalty, setsecretHintPentalty] = useState(false);
  const [secretHintPentaltyPoints, setsecretHintPentaltyPoints] = useState(0);

  const [forcedToRoom3, setforcedToRoom3] = useState(false);
  const [forcedToRoom3Points, setforcedToRoom3Points] = useState(0);

  const [rightComputerPenalty, setrightComputerPenalty] = useState(false);
  const [rightComputerPenaltyPoints, setrightComputerPenaltyPoints] =
    useState(0);

  const [roomStartTime, setroomStartTime] = useState(0);

  const [roomFinishTime, setroomFinishTime] = useState(0);

  const [timeElapsed, settimeElapsed] = useState(0);
  const [timeElapsedPoints, settimeElapsedPoints] = useState(0);

  const [showTotal, setshowTotal] = useState(false);

  const [showTimePoints, setshowTimePoints] = useState(false);

  const [showrightPuzzleResult, setshowrightPuzzleResult] = useState(false);

  const [showleftPuzzleResult, setshowleftPuzzleResult] = useState(false);

  const [showsecretHintUsed, setshowsecretHintUsed] = useState(false);

  const [showforcedToRoom3, setshowforcedToRoom3] = useState(false);

  const [showButton, setshowButton] = useState(false);

  const [toRoom3, settoRoom3] = useState(false);

  useEffect(() => {
    const dbRefleftComputerPenalty = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleLeft/penalty`,
    );
    const dbRefrightComputerPenalty = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleRight/penalty`,
    );
    const dbRefSecretHintUsed = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/secretHintUsed`,
    );
    const dbRefForcedToRoom3 = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/forcedtoRoom3`,
    );
    const dbRefroomStartTime = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/startTime`,
    );
    const dbRefroomFinishTime = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room2/finishTime`,
    );
    const dbRefTakeToRoomThree = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/toRoom3Intro`,
    );

    //
    onValue(dbRefleftComputerPenalty, (snapshot) => {
      const data = snapshot.val();
      setleftComputerPenalty(data);
      if (data) setleftComputerPenaltyPoints(-500);
    });

    //
    onValue(dbRefrightComputerPenalty, (snapshot) => {
      const data = snapshot.val();
      setrightComputerPenalty(data);
      if (data) setrightComputerPenaltyPoints(-500);
    });

    //
    onValue(dbRefroomStartTime, (snapshot) => {
      const data = snapshot.val();
      setroomStartTime(data);
    });

    onValue(dbRefSecretHintUsed, (snapshot) => {
      const data = snapshot.val();
      setsecretHintPentalty(data);
      if (data) setsecretHintPentaltyPoints(-750);
    });

    onValue(dbRefForcedToRoom3, (snapshot) => {
      const data = snapshot.val();
      setforcedToRoom3(data);
      if (data) setforcedToRoom3Points(-750);
    });

    //
    onValue(dbRefroomFinishTime, (snapshot) => {
      const data = snapshot.val();
      setroomFinishTime(data);
    });

    onValue(dbRefTakeToRoomThree, (snapshot) => {
      const data = snapshot.val();
      settoRoom3(data);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowButton(true);
    }, 7500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowTotal(true);
    }, 6500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowTimePoints(true);
    }, 5500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowforcedToRoom3(true);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowsecretHintUsed(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowrightPuzzleResult(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowleftPuzzleResult(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const minutes = Math.floor((roomFinishTime - roomStartTime) / 60);

    if (minutes < 20) settimeElapsedPoints(500);
    else if (minutes < 25) settimeElapsedPoints(300);
    else if (minutes < 30) settimeElapsedPoints(100);

    settimeElapsed(minutes);
  }, [roomStartTime, roomFinishTime]);

  useEffect(() => {
    const total =
      leftComputerPenaltyPoints +
      rightComputerPenaltyPoints +
      timeElapsedPoints +
      secretHintPentaltyPoints +
      forcedToRoom3Points;

    setRoomTwoTotalFirebase(gameCode, +total);
  }, [showTotal]);

  if (toRoom3) {
    return <Redirect to="/room3Intro" />;
  }

  return (
    <>
      <InfoBtn />
      <div className="roomTwoResult">
        <div className="roomTwoResult__left"></div>
        <div className="roomTwoResult__right">
          <h1>STAGE 2 SCORING SUMMARY</h1>
          <div className="resultTable">
            <div className="row row1">
              <div className="col col1">
                {showleftPuzzleResult ? <>Left Computer Penalty:</> : ""}
              </div>
              <div className="col col2">
                {showleftPuzzleResult ? (
                  <>{leftComputerPenalty ? "Yes" : "No"}</>
                ) : (
                  ""
                )}
              </div>
              <div className="col col3">
                {showleftPuzzleResult ? (
                  <>{`${leftComputerPenaltyPoints} points`}</>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row2">
              <div className="col col1">
                {showrightPuzzleResult ? <>Right Computer Penalty:</> : ""}
              </div>
              <div className="col col2">
                {showrightPuzzleResult ? (
                  <>{rightComputerPenalty ? "Yes" : "No"}</>
                ) : (
                  ""
                )}
              </div>
              <div className="col col3">
                {showrightPuzzleResult ? (
                  <>{`${rightComputerPenaltyPoints} points`}</>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row row3">
              {showsecretHintUsed ? (
                <>
                  <div className="col col1">Hint</div>
                  <div className="col col2">
                    {secretHintPentalty ? "Yes" : "No"}
                  </div>
                  <div className="col col3">
                    {`${secretHintPentaltyPoints} points`}
                  </div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row4">
              {showforcedToRoom3 ? (
                <>
                  <div className="col col1">Forced to Stage 3</div>
                  <div className="col col2">{forcedToRoom3 ? "Yes" : "No"}</div>
                  <div className="col col3">
                    {`${forcedToRoom3Points} points`}
                  </div>{" "}
                </>
              ) : (
                ""
              )}
            </div>

            <div className="row row5">
              <div className="col col1">
                {showTimePoints ? `Speed-Run Bonus:` : ""}
              </div>
              <div className="col col2">
                {showTimePoints ? `${timeElapsed} minutes` : ""}
              </div>
              <div className="col col3">
                {showTimePoints ? `${timeElapsedPoints} points` : ""}
              </div>
            </div>

            <div className="row row6">
              {showTotal ? (
                <>
                  <div className="col col1">Total:</div>
                  <div className="col col2"></div>
                  <div className="col col3">
                    {`${
                      leftComputerPenaltyPoints +
                      rightComputerPenaltyPoints +
                      timeElapsedPoints +
                      secretHintPentaltyPoints +
                      forcedToRoom3Points
                    } points`}
                  </div>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {showButton ? (
            <button
              className="resultTable__Btn"
              onClick={() => {
                setToRoom3IntroTrue(gameCode);
              }}
            >
              Proceed to Stage 3
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default RoomTwoResult;
