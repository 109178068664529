import React from "react";
import { css } from "@emotion/react";

import "./loading.component.styles.scss";
import { RingLoader } from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  border-color: #bef2f8;
`;

function Loading() {
  return (
    <div className="loading">
      <RingLoader color={`#bef2f8`} loading={true} css={override} size={250} />

      <h2>loading...</h2>
    </div>
  );
}

export default Loading;
