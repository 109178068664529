import React from "react";
import spyMarker from "../../assets/geoGuesser/SpyPinImage.png";

const UserMarker = ({ lat, lng, style }) => {
  return (
    <div lat={lat} lng={lng} style={style}>
      <img src={spyMarker} style={{ height: "30px", width: "30px" }} alt="" />
    </div>
  );
};

export default UserMarker;
