import React from "react";
import { Link } from "react-router-dom";

const SolvedScreen = () => {
  return (
    <div className="hp__main-wrapper">
      <Link className="back__btn" to="/room2">
        Back
      </Link>
      <div className="hp__effects-wrapper">
        <div className="hp__effects1">
          <div className="hp__effects2">
            <div className="hp__splash-page">
              RIGHT TERMINAL UNLOCKED
              <br />
              <br />
              Code Unlocked: GJRYIR
              <br />
              <br />
              Professor Khan says:
              <br />
              “Of course the wheel is not a lock…this is a cryptographer’s
              office, not a locksmith’s office!”
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolvedScreen;
