import React from "react";
import { Link } from "react-router-dom";

import "./somethingWentWrong.styles.scss";

function SomethingWentWrong() {
  return (
    <div className="somethingwentwrong">
      <h1>
        Something went wrong
        <br />
        Please check your internet connection and try again.{" "}
      </h1>

      <Link to="/isa" className="somethingwentwrong__btn">
        Try Again
      </Link>
    </div>
  );
}

export default SomethingWentWrong;
