import { onValue, ref } from "@firebase/database";
import { database } from "../../firebase/firebaseConfig";
import React, { useEffect, useState } from "react";

import "./roomOneResult.page.styles.scss";

import {
  setRoomOneFinishedTrue,
  setRoomOneTotalFirebase,
} from "../../firebase/firebase";
import { Redirect } from "react-router";
import InfoBtn from "../../components/infoBtn/infoBtn.component";

function RoomOneResult() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const [roomOneFinished, setroomOneFinished] = useState(false);

  const [logicGridOneScore, setlogicGridOneScore] = useState(0);
  const [logicGridOnePoints, setlogicGridOnePoints] = useState(0);

  const [logicGridTwoScore, setlogicGridTwoScore] = useState(0);
  const [logicGridTwoPoints, setlogicGridTwoPoints] = useState(0);

  const [logicGridThreeScore, setlogicGridThreeScore] = useState(0);
  const [logicGridThreePoints, setlogicGridThreePoints] = useState(0);

  const [tookMedium, setTookMedium] = useState(false);

  const [obsPuzzleScore, setobsPuzzleScore] = useState(0);
  const [obsPuzzlePoints, setobsPuzzlePoints] = useState(0);

  const [tookHelp, setTookHelp] = useState(false);
  const [tookHelpPoints, setTookHelpPoints] = useState(0);

  const [showChallengeOne, setshowChallengeOne] = useState(false);

  const [showChallengeTwo, setshowChallengeTwo] = useState(false);

  const [showChallengeThree, setshowChallengeThree] = useState(false);

  const [showChallengeThreePenalty, setshowChallengeThreePenalty] =
    useState(false);

  const [showButton, setshowButton] = useState(false);

  const [showTotal, setshowTotal] = useState(false);

  useEffect(() => {
    const dbReflogicGridOneScore = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid1/score`,
    );
    const dbReflogicGridTwoScore = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid2/score`,
    );
    const dbReflogicGridThreeScore = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/logicGrid3/score`,
    );
    const dbRefTookMedium = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/nextPuzzleChoice/medium`,
    );
    const dbRefobsPuzzleScore = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/score`,
    );
    const dbRefTookHelp = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/observationPuzzle/tookHelp`,
    );
    const dbRefRoomOneFinished = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/completed`,
    );

    //  Read game completed or not
    onValue(dbReflogicGridOneScore, (snapshot) => {
      const data = snapshot.val();
      setlogicGridOneScore(data);
      setlogicGridOnePoints(data * 50 - (10 - data) * 50);
    });

    //  syncs questions b/w players
    onValue(dbReflogicGridTwoScore, (snapshot) => {
      const data = snapshot.val();

      setlogicGridTwoScore(data);
      setlogicGridTwoPoints(data * 50 - (10 - data) * 50);
    });

    //  syncs current answers result b/w players
    onValue(dbReflogicGridThreeScore, (snapshot) => {
      const data = snapshot.val();
      setlogicGridThreeScore(data);
      setlogicGridThreePoints(data * 100 - (12 - data) * 100);
    });

    //  syncs score Screens b/w players
    onValue(dbRefTookMedium, (snapshot) => {
      const data = snapshot.val();
      setTookMedium(data);
    });

    //  syncs video playback b/w players
    onValue(dbRefobsPuzzleScore, (snapshot) => {
      const data = snapshot.val();
      setobsPuzzleScore(data);
      setobsPuzzlePoints(data * 100 - (7 - data) * 100);
    });

    //  syncs feedback Screens b/w players
    onValue(dbRefTookHelp, (snapshot) => {
      const data = snapshot.val();
      setTookHelp(data);
      if (data) setTookHelpPoints(-200);
    });

    //  checks on room One is completed
    onValue(dbRefRoomOneFinished, (snapshot) => {
      const data = snapshot.val();
      setroomOneFinished(data);
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowButton(true);
    }, 6500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowTotal(true);
    }, 5500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowChallengeThreePenalty(true);
    }, 4500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowChallengeThree(true);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowChallengeTwo(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowChallengeOne(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let total = 0;

    if (tookMedium) {
      total =
        logicGridOnePoints +
        logicGridTwoPoints +
        obsPuzzlePoints +
        tookHelpPoints;
    } else {
      total =
        logicGridOnePoints +
        logicGridThreePoints +
        obsPuzzlePoints +
        tookHelpPoints;
    }

    //  setTotal In Firebase
    setRoomOneTotalFirebase(gameCode, total);
  }, [showTotal]);

  if (roomOneFinished) {
    return <Redirect to="/room2Intro" />;
  }

  return (
    <>
      <InfoBtn />

      <div className="roomOneResult">
        <div className="roomOneResult__left"></div>
        <div className="roomOneResult__right">
          <h1>STAGE 1 SCORING SUMMARY</h1>
          <div className="resultTable">
            <div className="row row1">
              <div className="col col1">
                {showChallengeOne ? <>Challenge #1:</> : ""}
              </div>
              <div className="col col2">
                {showChallengeOne ? <>{logicGridOneScore}/10 Correct</> : ""}
              </div>
              <div className="col col3">
                {showChallengeOne ? <>{`${logicGridOnePoints} points`}</> : ""}
              </div>
            </div>
            <div className="row row2">
              <div className="col col1">
                {showChallengeTwo ? (
                  <>
                    {tookMedium
                      ? `Challenge #2`
                      : `Challenge #2 (2x Multiplier)`}{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col col2">
                {showChallengeTwo ? (
                  <>
                    {" "}
                    {tookMedium
                      ? `${logicGridTwoScore}/10 Correct`
                      : `${logicGridThreeScore}/12 Correct`}{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col col3">
                {showChallengeTwo ? (
                  <>
                    {" "}
                    {tookMedium
                      ? `${logicGridTwoPoints} points`
                      : `${logicGridThreePoints} points`}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row3">
              <div className="col col1">
                {showChallengeThree ? <>Challenge #3</> : ""}
              </div>
              <div className="col col2">
                {showChallengeThree ? <>{obsPuzzleScore}/7 Correct</> : ""}
              </div>
              <div className="col col3">
                {showChallengeThree ? <>{`${obsPuzzlePoints} points`}</> : ""}
              </div>
            </div>
            <div className="row row4">
              <div className="col col1">
                {showChallengeThreePenalty ? <>Challenge #3 Penalty:</> : ""}
              </div>
              <div className="col col2">
                {showChallengeThreePenalty ? (
                  <>{tookHelp ? "Yes" : "No"}</>
                ) : (
                  ""
                )}
              </div>
              <div className="col col3">
                {showChallengeThreePenalty ? (
                  <>{`${tookHelpPoints} points`}</>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row row5">
              <div className="col col1">{showTotal ? <>Total:</> : ""}</div>
              <div className="col col2"></div>
              <div className="col col3">
                {showTotal ? (
                  <>
                    {tookMedium
                      ? `${logicGridOnePoints + logicGridTwoPoints + obsPuzzlePoints + tookHelpPoints} points`
                      : `${logicGridOnePoints + logicGridThreePoints + obsPuzzlePoints + tookHelpPoints} points`}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          {showButton ? (
            <button
              className="resultTable__Btn"
              onClick={() => {
                setRoomOneFinishedTrue(gameCode);
              }}
            >
              Proceed to Stage 2
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default RoomOneResult;
