import React from "react";
import Streetview from "react-google-streetview";

const StreetViewMap = ({ apiKey, location }) => {
  const streetViewPanoramaOptions = {
    position: { lat: location.lat, lng: location.lng },
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
    addressControl: false,
    showRoadLabels: false,
  };

  return (
    <>
      <div style={{ height: "100vh" }}>
        <Streetview
          apiKey={apiKey}
          streetViewPanoramaOptions={streetViewPanoramaOptions}
        />
      </div>
    </>
  );
};

export default StreetViewMap;
