import React, { useEffect, useState } from "react";
import Geoguessr from "./Geoguessr";
import Result from "./Result";

import { database } from "../../firebase/firebaseConfig";
import { ref, onValue } from "firebase/database";

import {
  setGeoGuesserShowResult,
  setGeoGuesserShowNextRound,
  setGeoGuesserTotalScore,
  setGeoGuesserPoints,
  setGeoGuesserDistances,
  setGeoGuesserRound,
  setGeoGuesserLangs,
  setGeoGuesserLats,
} from "./GeoGuesserFirebase";

import InfoBtn from "../infoBtn/infoBtn.component";

const API_KEY = `${process.env.REACT_APP_API_KEY}`;

const locations = [
  {
    lat: 35.6713672,
    lng: 139.7041151,
  },
  {
    lat: -1.1662729,
    lng: 34.4449584,
  },
  {
    lat: -32.2134853,
    lng: -71.1480721,
  },
  {
    lat: 11.5818567,
    lng: 104.8958884,
  },
  {
    lat: 62.528683,
    lng: 26.4307213,
  },
];

const GeoGuesserGame = () => {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const [showResult, setShowResult] = useState(false);
  const [showNextRound, setShowNextRound] = useState(false);

  const [points, setpoints] = useState();
  const [lats, setlats] = useState();
  const [langs, setlangs] = useState();
  const [distances, setdistances] = useState();
  const [totalScore, settotalScore] = useState(0);
  const [round, setround] = useState(1);

  const calculateScore = (distance) => {
    let score = 0;

    distance = distance / 1000;

    if (distance < 0.05) score = 500;
    else if (distance > 12000) score = 0;
    else score = Math.round(Math.pow(Math.E, -distance / 2000) * 500);

    return score;
  };

  const setNextRound = () => {
    setGeoGuesserShowNextRound(gameCode, true);
    if (round < locations.length) {
      setGeoGuesserRound(gameCode, round + 1);
    }
    setGeoGuesserShowResult(gameCode, false);
  };

  const storeScore = (distance, userLocation) => {
    let calculatedScore = calculateScore(distance);

    setGeoGuesserTotalScore(gameCode, totalScore + calculatedScore);
    setGeoGuesserPoints(gameCode, calculatedScore, round);
    setGeoGuesserDistances(gameCode, distance, round);
    setGeoGuesserLats(gameCode, userLocation.lat, round);
    setGeoGuesserLangs(gameCode, userLocation.lng, round);

    setGeoGuesserShowResult(gameCode, true);
  };

  // ////////////////////////////

  useEffect(() => {
    const dbRefGeoGuesserShowResult = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/showResult`,
    );
    const dbRefGeoGuesserShowNextRound = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/showNextRound`,
    );

    const dbReftotalScores = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room3/totalScore`,
    );
    const dbRefround = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room3/round`,
    );
    const dbRefpoints = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room3/result/round${round}/points`,
    );
    const dbRefdistanes = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room3/result/round${round}/distances`,
    );
    const dbRefLats = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room3/result/round${round}/lat`,
    );
    const dbRefLangs = ref(
      database,
      `/Rooms/${gameCode}/RoomStates/room3/result/round${round}/lang`,
    );

    // syncs the result with firebase
    onValue(
      dbReftotalScores,
      (snapshot) => {
        const data = snapshot.val();
        settotalScore(data);
      },
      [],
    );

    onValue(
      dbRefround,
      (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        setround(data);
      },
      [],
    );

    onValue(
      dbRefpoints,
      (snapshot) => {
        const data = snapshot.val();
        if (data !== null) setpoints(data);
      },
      [],
    );

    onValue(
      dbRefdistanes,
      (snapshot) => {
        const data = snapshot.val();
        if (data) setdistances(data);
      },
      [],
    );

    onValue(
      dbRefLats,
      (snapshot) => {
        const data = snapshot.val();
        if (data) setlats(data);
      },
      [],
    );

    onValue(
      dbRefLangs,
      (snapshot) => {
        const data = snapshot.val();
        if (data) setlangs(data);
      },
      [],
    );

    onValue(
      dbRefGeoGuesserShowResult,
      (snapshot) => {
        const data = snapshot.val();
        setShowResult(data);
      },
      [],
    );

    onValue(
      dbRefGeoGuesserShowNextRound,
      (snapshot) => {
        const data = snapshot.val();
        setShowNextRound(data);
      },
      [],
    );
  });

  if (showResult) {
    return (
      <>
        <InfoBtn />
        <Result
          apiKey={API_KEY}
          correctLocation={locations[round - 1]}
          userLocation={{
            lat: lats,
            lng: langs,
          }}
          distance={distances}
          points={points}
          isLastRound={round === locations.length ? true : false}
          setNextRound={setNextRound}
        />
      </>
    );
  } else {
    if (round === 1) {
      return (
        <>
          <Geoguessr
            apiKey={API_KEY}
            location={locations[0]}
            storeScore={storeScore}
            score={totalScore}
            round={round}
          />
        </>
      );
    } else if (showNextRound) {
      if (round === 2) {
        return (
          <Geoguessr
            apiKey={API_KEY}
            location={locations[1]}
            storeScore={storeScore}
            score={totalScore}
            round={round}
          />
        );
      } else if (round === 3) {
        return (
          <Geoguessr
            apiKey={API_KEY}
            location={locations[2]}
            storeScore={storeScore}
            score={totalScore}
            round={round}
          />
        );
      } else if (round === 4) {
        return (
          <Geoguessr
            apiKey={API_KEY}
            location={locations[3]}
            storeScore={storeScore}
            score={totalScore}
            round={round}
          />
        );
      } else {
        return (
          <Geoguessr
            apiKey={API_KEY}
            location={locations[4]}
            storeScore={storeScore}
            score={totalScore}
            round={round}
          />
        );
      }
    }
  }
};

export default GeoGuesserGame;
