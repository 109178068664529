import React from "react";
import Map from "./Map";
import StreetViewMap from "./StreetViewMap";
import Status from "./Status";
import "./assets/css/style.css";
import InfoBtn from "../infoBtn/infoBtn.component";

const Geoguessr = ({ location, storeScore, score, round, apiKey }) => {
  return (
    <>
      <InfoBtn />
      <div className="geo__container">
        <StreetViewMap apiKey={apiKey} location={location} />
        <Map apiKey={apiKey} location={location} storeScore={storeScore} />
        <Status score={score} round={round} />
      </div>
    </>
  );
};

export default Geoguessr;
