import React, { useState } from "react";
import { checkCodeValidity } from "../../firebase/firebase";
import { Redirect } from "react-router-dom";

import "./wardenGameStart.styles.scss";
import { setWardenCredentials } from "./wardenLoginUtils";

function WardenGameStart() {
  // use let for making the code uppercase, can't use setCode because of asynchronous nature
  let [code, setCode] = useState("");

  const [wardenEmail, setwardenEmail] = useState("");

  // waiting for other players
  const [waiting, setwaiting] = useState(false);

  const [password, setpassword] = useState("");

  // code validity
  const [codeInvalid, setcodeInvalid] = useState(false);

  const [correctPassword] = useState("qwerty");

  const joinTeamAsWarden = async () => {
    // gaurd Clause for valid code
    if (code === "") {
      return;
    }

    // makes the code uppercase
    code = code.toUpperCase();

    // check game code
    const codeValid = await checkCodeValidity(code);

    if (!codeValid) {
      setcodeInvalid(true);
      return;
    }

    // using local storage to persist data b/w refresh
    localStorage.setItem("GAME_CODE", JSON.stringify(code));

    // using local storage to persist data b/w refresh
    localStorage.setItem("WARDEN", JSON.stringify(true));

    // reset old player id
    localStorage.setItem("PLAYERID", JSON.stringify(null));

    // hacking puzzle fix
    localStorage.setItem("HPR_REFRESHED", JSON.stringify(false));
    localStorage.setItem("HPL_REFRESHED", JSON.stringify(false));

    await setWardenCredentials(code, wardenEmail);

    setwaiting(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // check password
    if (password === correctPassword) {
      joinTeamAsWarden();
    }
  };

  if (waiting) {
    return <Redirect to="/waitingRoom" />;
  }

  if (codeInvalid) {
    return <Redirect to="/roomCodeExpired" />;
  }

  return (
    <div className="wardengameStart">
      <form action="" onSubmit={handleSubmit}>
        <h1>Enter Warden Passcode</h1>
        <input
          required
          type="password"
          onChange={(e) => setpassword(e.target.value)}
        />

        <h1>Enter Room Code</h1>
        <input
          maxLength="5"
          type="text"
          required
          className="game__code"
          onChange={(e) => setCode(e.target.value)}
        />

        <h1>Enter Your Email</h1>
        <input
          required
          type="email"
          onChange={(e) => setwardenEmail(e.target.value)}
        />

        <input type="submit" id="defaultBtn" value="Submit" />
      </form>
    </div>
  );
}

export default WardenGameStart;
