import getGamesDataFirebase from "./getDataFromFirebase";

function trasformData(data) {
  // stage 1 filter rooms that were not started
  data = Object.entries(data).filter(
    (roomEntry) =>
      roomEntry[1].gameStarted &&
      roomEntry[1].players &&
      Object.values(roomEntry[1].players).length,
  );

  // // adding a roomCode as a key
  data = data.map((roomEntry) => {
    roomEntry[1].roomCode = roomEntry[0];
    return roomEntry[1];
  });
  // transforming data to be sheet ready

  const values = data.map((room) => {
    let output = [];
    //     RoomCode
    output.push(room.roomCode);
    // ScheduledTime
    output.push(new Date(room.scheduledTime * 1000));
    // Starttime
    output.push(new Date(room.RoomStates.room1.startTime * 1000));
    // FinishTime
    output.push(
      room.RoomStates.room3.finishTime
        ? room.RoomStates.room3.finishTime
        : "Not Finished",
    );
    // WardenEmail
    output.push(room.wardenEmail);
    // Players
    output.push(Object.values(room.players).toString());
    // OverallScore
    output.push(room.overallScore);
    // Room 1 Risk It Or Play It Safe?
    output.push(
      room.RoomStates.room1.nextPuzzleChoice.medium
        ? "Played It Safe"
        : "Risked It",
    );
    // Room 1 Video Watched Twice?
    output.push(
      room.RoomStates.room1.observationPuzzle.tookHelp
        ? "Watched Twice"
        : "Watched Once",
    );
    // Room 1 Completed?
    output.push(room.RoomStates.room1.completed ? "Yes" : "No");
    // Room 1 Score
    output.push(room.RoomStates.room1.totalScore);
    // Room 2 Secret Hint Used?
    output.push(room.RoomStates.room2.secretHintUsed ? "Yes" : "No");
    // Room 2 Completed?
    output.push(room.RoomStates.room2.completed ? "Yes" : "No");
    // Room 2 Finish Time
    output.push(
      room.RoomStates.room2.finishTime ? room.RoomStates.room2.finishTime : 0,
    );
    // Room 2 Score
    output.push(
      room.RoomStates.room2.totalScore ? room.RoomStates.room2.totalScore : 0,
    );
    // Room 3 Round 1 Distances
    output.push(room.RoomStates.room3.result?.round1?.distances);
    // Room 3 Round 1 Points
    output.push(room.RoomStates.room3.result?.round1?.points);
    // Room 3 Round 2 Distances
    output.push(room.RoomStates.room3.result?.round2?.distances);
    // Room 3 Round 2 Points
    output.push(room.RoomStates.room3.result?.round2?.points);
    // Room 3 Round 3 Distances
    output.push(room.RoomStates.room3.result?.round3?.distances);
    // Room 3 Round 3 Points
    output.push(room.RoomStates.room3.result?.round3?.points);
    // Room 3 Round 4 Distances
    output.push(room.RoomStates.room3.result?.round4?.distances);
    // Room 3 Round 4 Points
    output.push(room.RoomStates.room3.result?.round4?.points);
    // Room 3 Round 5 Distances
    output.push(room.RoomStates.room3.result?.round5?.distances);
    // Room 3 Round 5 Points
    output.push(room.RoomStates.room3.result?.round5?.points);
    // Room 3 Completed?
    output.push(
      room.RoomStates.room3.completed ? "Completed" : "Not Completed",
    );
    // Room 3 Score
    output.push(room.RoomStates.room3.totalScore);

    return output;
  });
  return values;
}

export default async () => {
  let data = await getGamesDataFirebase();
  const transformedData = trasformData(data);
  return transformedData;
};
