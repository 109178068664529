import { child, get, ref, set } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";
const ObjectsToCsv = require("objects-to-csv");

const filterAndDownload = async function (loadedData) {
  const rooms = Object.keys(loadedData);

  console.log(rooms);

  const extractedData = rooms
    .map((item, i) => {
      if (
        loadedData[item].players?.length <= 1 ||
        loadedData[item].players === undefined
      )
        return {};

      return {
        RoomCode: item,
        ScheduledTime: new Date(loadedData[item].scheduledTime).toUTCString(),
        Starttime: new Date(
          loadedData[item].RoomStates.room1.startTime,
        ).toUTCString(),
        Players: loadedData[item].players?.join(", "),
        WardenEmail: loadedData[item].wardenEmail,
        FinishTime: loadedData[item].RoomStates.room3.finishTime
          ? new Date(loadedData[item].RoomStates.room3.finishTime).toUTCString()
          : "Did not finish",
        OverallScore: loadedData[item].overallScore,
      };
    })
    .filter((element) => {
      if (Object.keys(element).length !== 0) {
        return true;
      }
      return false;
    });

  console.log(extractedData);

  const csv = new ObjectsToCsv(extractedData);

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = "_" + mm + "_" + dd + "_" + yyyy;

  // // Return the CSV file as string:
  const csvContent = await csv.toString();

  //display the created CSV data on the web browser
  document.write(csvContent);

  var hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
  hiddenElement.target = "_blank";

  //provide the name for the CSV file to be downloaded
  hiddenElement.download = `gameDate${today}.csv`;
  hiddenElement.click();
};

export const fetchData = async () => {
  console.log("wait");

  const dbRef = ref(database);

  const snapshot = await get(child(dbRef, `Rooms`));

  if (!snapshot.exists()) {
    return false;
  }

  const loadedData = snapshot.val();

  console.log(loadedData);

  filterAndDownload(loadedData);
};

export const wipeDatabase = async () => {
  const dbRef = ref(database);
  set(dbRef, null);
};

export const getRoomData = async (roomCode) => {
  const dbRef = ref(database);

  const snapshot = await get(child(dbRef, `Rooms/${roomCode}`));

  if (!snapshot.exists()) {
    return false;
  }

  const loadedData = { [roomCode]: snapshot.val() };

  console.log(loadedData);

  filterAndDownload(loadedData);
};
