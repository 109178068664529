import React from "react";
import { Link } from "react-router-dom";

const SolvedScreen = () => {
  return (
    <div className="hp__main-wrapper">
      <Link className="back__btn" to="/room2">
        Back
      </Link>
      <div className="hp__effects-wrapper">
        <div className="hp__effects1">
          <div className="hp__effects2">
            <div className="hp__splash-page">
              LEFT TERMINAL UNLOCKED
              <br />
              <br />
              Code Unlocked: CLOQVQTL
              <br />
              <br />
              Professor Khan says:
              <br />
              “Everything in my office is placed precisely, especially the
              computers and the artwork”
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolvedScreen;
