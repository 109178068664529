import { ref, get, child, set } from "firebase/database";
import { database, maxPlayers } from "../../firebase/firebaseConfig";

export const setTeam = async ({ code: teamCode, playerName }) => {
  playerName = playerName.toUpperCase();

  // fetchdata

  let players = [playerName];
  let existingPlayers;

  const dbRefCurrentTeam = ref(database);

  get(child(dbRefCurrentTeam, `Rooms/${teamCode}/players`))
    .then((snapshot) => {
      if (snapshot.exists()) {
        existingPlayers = snapshot.val();

        if (existingPlayers.length >= maxPlayers) {
          alert("This room is full");
          return;
        }

        while (existingPlayers.includes(playerName)) {
          playerName = playerName + 1;
        }

        players = [...existingPlayers, playerName];
      }

      const dbRefSetPlayers = ref(database, `Rooms/${teamCode}/players`);
      set(dbRefSetPlayers, players);
      localStorage.setItem("PLAYERID", JSON.stringify(players.length));
    })
    .catch((error) => {
      console.error(error);
    });
};
