import { onValue } from "firebase/database";
import {
  useEffect,
  useState,
  useRef,
  memo,
  useCallback,
  forwardRef,
} from "react";
import { ref as fireRef } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import { database } from "../../../firebase/firebaseConfig";
import { setPlayerInControlHackingPuzzle } from "../../../firebase/TakeControl";
import generatePointersColumns from "../helpers/generatePointersColumns";

const GameTemplate = memo(
  forwardRef(
    (
      {
        handleMouseEnterSymbol,
        handleMouseLeaveSymbol,
        handleMouseEnterWord,
        handleMouseLeaveWord,
        handleClick,
        gameContent,
        currentPuzzle,
      },
      ref,
    ) => {
      // take control

      const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

      const playerId = JSON.parse(localStorage.getItem("PLAYERID"));

      const [showControlUpdate, setshowControlUpdate] = useState(false);

      const [playerInControl, setplayerInControl] = useState("");

      const [allPlayers, setallPlayers] = useState([]);

      const wordsRef = useRef([]);
      const specialSymbolsRef = useRef([]);
      const [pointers, setPointers] = useState({
        left: [],
        right: [],
      });
      const [gameData, setGameData] = useState();

      const generateGameElements = () => {
        let elements = [];
        let index = 0;
        for (let i = 0; i < 17; i++) {
          addLine(elements, pointers.left[i], index, gameContent.left);
          addLine(elements, pointers.right[i], index, gameContent.right);
          elements.push(<br key={uuidv4()} />);
          index = index + 12;
        }
        return elements;
      };

      const addLine = useCallback(
        (elements, pointer, index, gameContentSide) => {
          elements.push(<span key={uuidv4()}>{pointer}</span>);

          for (let j = index; j < index + 12; j++) {
            if (gameContentSide[j].type === "WORD") {
              elements.push(
                <span
                  className={`hp__word ${gameContentSide[j].word}`}
                  onMouseEnter={(e) => handleMouseEnterWord(e, wordsRef)}
                  onMouseLeave={(e) => handleMouseLeaveWord(e, wordsRef)}
                  onClick={handleClick}
                  ref={(elem) => addRef(elem, wordsRef)}
                  key={uuidv4()}
                >
                  {gameContentSide[j].value}
                </span>,
              );
            } else if (gameContentSide[j].type === "SYMBOL") {
              elements.push(
                <span
                  className="hp__symbol"
                  key={uuidv4()}
                  onMouseEnter={(e) =>
                    handleMouseEnterSymbol(e, specialSymbolsRef)
                  }
                  onMouseLeave={(e) =>
                    handleMouseLeaveSymbol(e, specialSymbolsRef)
                  }
                  onClick={handleClick}
                >
                  {gameContentSide[j].value}
                </span>,
              );
            } else if (gameContentSide[j].type === "SPECIAL_SYMBOL") {
              let classList = "";
              if (
                gameContentSide[j].value === "(" ||
                gameContentSide[j].value === "["
              ) {
                classList = `hp__special-symbol ${gameContentSide[j].string} ${gameContentSide[j].action}`;
              } else {
                classList = `hp__symbol ${gameContentSide[j].string}`;
              }

              elements.push(
                <span
                  className={classList}
                  ref={(elem) => addRef(elem, specialSymbolsRef)}
                  key={uuidv4()}
                  onMouseEnter={(e) =>
                    handleMouseEnterSymbol(e, specialSymbolsRef)
                  }
                  onMouseLeave={(e) =>
                    handleMouseLeaveSymbol(e, specialSymbolsRef)
                  }
                  onClick={handleClick}
                >
                  {gameContentSide[j].value}
                </span>,
              );
            }
          }
        },
        [],
      );

      const addRef = (elem, refArr) => {
        if (elem) {
          refArr.current.push(elem);
          ref.current.push(elem);
        }
      };

      useEffect(() => {
        if (!pointers.left.length && !pointers.right.length) {
          setPointers(generatePointersColumns());
        }
      }, []);

      useEffect(() => {
        setGameData(gameContent);
      }, [currentPuzzle]);

      // ********************************************  firebase multiplyer

      // give control Update
      useEffect(() => {
        setshowControlUpdate(true);
        setTimeout(() => {
          setshowControlUpdate(false);
        }, 2000);
      }, [playerInControl]);

      useEffect(() => {
        const dbRefInControl = fireRef(
          database,
          `Rooms/${gameCode}/RoomStates/room2/hackingPuzzleLeft/inControl`,
        );
        const dbRefPlayersJoined = fireRef(
          database,
          `Rooms/${gameCode}/players`,
        );

        // checks who is in control
        onValue(dbRefInControl, (snapshot) => {
          const data = snapshot.val();
          setplayerInControl(data);
        });

        // get All players
        onValue(dbRefPlayersJoined, (snapshot) => {
          setallPlayers(snapshot.val());
        });
      }, []);
      // ********************************************  firebase multiplyer

      const getControl = () => {
        // check if player id in null as for warden
        if (playerId)
          setPlayerInControlHackingPuzzle(playerId, gameCode, "Left");
      };

      if (
        gameContent.left.length &&
        gameContent.right.length &&
        pointers.left.length &&
        pointers.right.length
      ) {
        return (
          <>
            {/* control update */}
            {showControlUpdate ? (
              <div className="controlUpdate" id="controlUpdateHP">
                {playerInControl === playerId || allPlayers === null ? (
                  <h1> YOU ARE IN CONTROL</h1>
                ) : (
                  <h1>
                    {" "}
                    {allPlayers[playerInControl - 1]
                      ? allPlayers[playerInControl - 1]
                      : ""}{" "}
                    IS IN CONTROL
                  </h1>
                )}
              </div>
            ) : (
              ""
            )}

            <button className="takeControlBtn" onClick={getControl}>
              Take Control
            </button>

            {/* permanent Control info */}
            {playerId !== "" ? (
              <>
                {playerInControl === playerId || allPlayers === null ? (
                  <h3 className="permanentControlInfo"> YOU ARE IN CONTROL </h3>
                ) : (
                  <h3 className="permanentControlInfo">{`${
                    allPlayers[playerInControl - 1]
                      ? allPlayers[playerInControl - 1]
                      : ""
                  } IS IN CONTROL`}</h3>
                )}
              </>
            ) : (
              ""
            )}

            <p
              className="txt hp__hacking-symbols"
              style={{
                pointerEvents: `${
                  playerInControl === playerId || playerId === null
                    ? "auto"
                    : "none"
                }`,
              }}
            >
              {generateGameElements()}
            </p>
          </>
        );
      }

      return null;
    },
  ),
  (prevProps, nextProps) => {
    if (prevProps.currentPuzzle === nextProps.currentPuzzle) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component*/
  },
);

export default GameTemplate;
