import { ref, set } from "firebase/database";
import { database } from "./firebaseConfig";

export const setPlayerInControlHackingPuzzle = (id, gameCode, computer) => {
  const dbRefInControl = ref(
    database,
    `Rooms/${gameCode}/RoomStates/room2/hackingPuzzle${computer}/inControl`,
  );
  set(dbRefInControl, id);
};
