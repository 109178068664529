import React, { useState } from "react";
import { Redirect } from "react-router";

import "./roomCodeExpired.styles.scss";

function RoomCodeExpired() {
  const [toGameStart, settoGameStart] = useState(false);

  if (toGameStart) {
    return <Redirect to="/isa" />;
  }

  return (
    <div className="roomCodeExpired">
      <h1>
        Your Room Code is <span> EXPIRED. </span>
        <br />
        Please enter a valid Room Code to join.{" "}
      </h1>

      <button
        className="roomCodeExpired__btn"
        onClick={() => {
          settoGameStart(true);
        }}
      >
        Try Again
      </button>
    </div>
  );
}

export default RoomCodeExpired;
