import React, { useEffect, useState } from "react";

import "./gameintro.page.styles.scss";

import { ref, onValue } from "firebase/database";
import { database } from "../../firebase/firebaseConfig";

import gameIntro from "../../assets/gameAssets/gameIntroAndTang.mp4";
import { startTheGame } from "../../firebase/firebase";
import { Redirect } from "react-router";
import soundOn from "../../assets/cipherwheel/volume.png";

import soundOff from "../../assets/cipherwheel/no-sound.png";

function GameIntro() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const warden = JSON.parse(localStorage.getItem("WARDEN"));

  const [muted, setmuted] = useState(true);

  const [showStart, setShowStart] = useState(false);

  const [gameStarted, setgameStarted] = useState(false);

  useEffect(() => {
    const dbRefJoinGame = ref(database, `Rooms/${gameCode}/gameStarted`);

    onValue(dbRefJoinGame, (snapshot) => {
      setgameStarted(snapshot.val());
    });
  }, []);

  if (gameStarted) {
    return <Redirect to="/logicGrid1" />;
  }

  return (
    <>
      <video
        autoPlay
        id="transitionVideo"
        muted={muted}
        onEnded={() => {
          setShowStart(true);
        }}
      >
        <source src={gameIntro} type="video/mp4" />
      </video>

      {showStart ? (
        <button
          className="gameIntro__startBtn"
          onClick={() => {
            startTheGame(gameCode);
          }}
        >
          {" "}
          Yes{" "}
        </button>
      ) : (
        ""
      )}

      {warden ? (
        <button
          id="defaultBtn"
          onClick={() => {
            startTheGame(gameCode);
          }}
        >
          SKIP AND START
        </button>
      ) : (
        ""
      )}

      <div
        className="videoSound"
        onClick={() => {
          setmuted(!muted);
        }}
      >
        {!muted ? <img src={soundOn} alt="" /> : <img src={soundOff} alt="" />}
      </div>
    </>
  );
}

export default GameIntro;
