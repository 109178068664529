import React, { useState, useEffect, memo } from "react";

const Header = ({ attempts }) => {
  const [showWarning, setShowWarning] = useState(false);
  const showAttempts = () => {
    let att = [];
    for (let i = 0; i < attempts; i++) {
      att.push(" ██ ");
    }
    return att;
  };

  useEffect(() => {
    if (attempts === 1) {
      setShowWarning(true);
    } else if (attempts === 3) {
      setShowWarning(false);
    }
  }, [attempts]);

  return (
    <div>
      <p className="txt hp__active-command-prompt">
        THE INTERNATIONAL SPY ACADEMY (ISA)
      </p>
      <br />
      {showWarning ? (
        <p className="txt hp__blinker">!!! WARNING: LOCKOUT IMMINENT !!!</p>
      ) : (
        <>
          <p className="txt hp__active-command-prompt">ENTER PASSWORD NOW</p>
          <br />
        </>
      )}
      <br />
      <p className="txt hp__active-command-prompt">
        ​​<span>{attempts} Attempt(s) Left:</span>
        <span className="hp__attempts-blocks">{showAttempts()}</span>
      </p>
      <br />
      <br />
      <br />
    </div>
  );
};

export default memo(Header);
