import React from "react";
import { Link } from "react-router-dom";

const GlobalAttemptsScreen = ({ attempts, startNextPuzzle }) => {
  return (
    <div className="hp__main-wrapper">
      <Link className="back__btn" to="/room2">
        Back
      </Link>

      <div className="hp__effects-wrapper">
        <div className="hp__effects1">
          <div className="hp__effects2">
            <div className="hp__landscape hp__loading">
              <p className="txt hp__cp">
                <br />
                <br />
                &gt;YOU HAVE {attempts} ATTEMPTS LEFT
                <br />
                ISA-9000
                <br />
                &gt;SET FILE/PROTECTION = OWNER:KHAN ACCOUNTS
              </p>
              <p className="txt hp__cp" onClick={startNextPuzzle}>
                <br />
                <br />
                &gt;CONTINUE
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalAttemptsScreen;
