import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import startVideo from "../../assets/gameAssets/PlayerGameStart.mp4";
import Loading from "../../components/loading/loading.component";

function Spyislandanimation() {
  const [played, setplayed] = useState(false);

  const [loading, setloading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setloading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (played) {
    return <Redirect to="/isa" />;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <video
          autoPlay
          muted
          onEnded={() => {
            setplayed(true);
          }}
          id="transitionVideo"
        >
          <source src={startVideo} type="video/mp4" />
        </video>
      )}
    </>
  );
}

export default Spyislandanimation;
