import "./App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ObservationPuzzle from "./components/observationPuzzle/observationPuzzle.component";
import WardenGameStart from "./components/wardenGameStart/wardenGameStart.component";
import PlayerGameStart from "./components/playerGameStart/playerGameStart.component";
import Puzzle1 from "./components/LogicPuzzles/PuzzleV1/Puzzle";
import Puzzle2 from "./components/LogicPuzzles/PuzzleV2/Puzzle";
import Puzzle3 from "./components/LogicPuzzles/PuzzleV3/Puzzle";
import WaitingRoom from "./components/waitingRoom/waitingRoom.component";
import ObservationPuzzleInstruction from "./pages/observationPuzzleInstruction/observationPuzzleInstruction";
import NextPuzzleChoice from "./pages/nextPuzzleChoice/nextPuzzleChoice";
import Room2 from "./pages/Room2/room2";
import GameIntro from "./pages/room1Entry/gameintro.page";
import RoomOneResult from "./pages/roomOneResult/roomOneResult.page";
import Room2Entry from "./pages/room2Entry/room2Entry.page";
import RoomTwoResult from "./pages/roomTwoResult/roomTwoResult.page";
import Spyislandanimation from "./pages/spyislandAnimation/spyislandanimation.page";
import KeypadPuzzle from "./components/KeypadPuzzle/KeypadPuzzle";
import GeoGuesserGame from "./components/Geoguessr/Game";
import Room3Entry from "./pages/room3Entry/room3Entry.page";
import RoomThreeResult from "./pages/roomThreeResults/roomThreeResult.page";
import RoomCodeExpired from "./pages/roomCodeExpired/roomCodeExpired.page";
import OverallResults from "./pages/overallResult/overallResult.page";
import PassVideo from "./pages/passVideo/passVideo.page";
import FailVideo from "./pages/failVideo/failVideo.page";
import SomethingWentWrong from "./pages/somethingWentWrong/somethingWentWrong.page";
import GameCreater from "./pages/gameCreater/gameCreater.page";
import ObsPuzzleMouse from "./components/observationPuzzleMouse/obsPuzzleMouse.component";
import DownloadWipe from "./pages/downloadWipe/downloadWipe.page";
import DataToSheet from "./pages/databaseToSheet/dataToSheet.page";

import { default as HackingPuzzleLeft } from "./components/hackingPuzzles/hackingPuzzleLeft/Game";
import { default as HackingPuzzleRight } from "./components/hackingPuzzles/hackingPuzzleRight/Game";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* room 1 */}

          <Route path="/gameIntro">
            <GameIntro />
          </Route>
          <Route path="/roomOneResult">
            <RoomOneResult />
          </Route>
          <Route path="/observationPuzzleInstuctions">
            <ObservationPuzzleInstruction />
          </Route>
          <Route path="/observationPuzzleImg">
            <ObsPuzzleMouse />
          </Route>
          <Route path="/nextPuzzleChoice">
            <NextPuzzleChoice />
          </Route>
          <Route path="/logicGrid1">
            <Puzzle1 />
          </Route>
          <Route path="/logicGrid2">
            <Puzzle2 />
          </Route>
          <Route path="/logicGrid3">
            <Puzzle3 />
          </Route>
          <Route path="/ObservationPuzzle">
            <ObservationPuzzle />
          </Route>

          {/* room 2 */}

          <Route path="/room2Intro">
            <Room2Entry />
          </Route>
          <Route path="/room2">
            <Room2 />
          </Route>
          <Route path="/roomTwoResult">
            <RoomTwoResult />
          </Route>
          <Route path="/keypad">
            <KeypadPuzzle />
          </Route>
          <Route path="/hpl">
            <HackingPuzzleLeft />
          </Route>
          <Route path="/hpr">
            <HackingPuzzleRight />
          </Route>

          {/* room 3 */}

          <Route path="/room3Intro">
            <Room3Entry />
          </Route>
          <Route path="/guessit">
            <GeoGuesserGame />
          </Route>
          <Route path="/roomThreeResult">
            <RoomThreeResult />
          </Route>
          <Route path="/pv34124">
            <PassVideo />
          </Route>
          <Route path="/fv2315">
            <FailVideo />
          </Route>
          <Route path="/overallResults">
            <OverallResults />
          </Route>

          {/* infrastructure */}

          <Route path="/downloadWipe">
            <DownloadWipe />
          </Route>
          <Route path="/gameCreater">
            <GameCreater />
          </Route>
          <Route path="/fill-sheet">
            <DataToSheet />
          </Route>

          <Route path="/roomCodeExpired">
            <RoomCodeExpired />
          </Route>
          <Route path="/wardengameStart">
            <WardenGameStart />
          </Route>
          <Route path="/isa">
            <PlayerGameStart />
          </Route>
          <Route path="/waitingRoom">
            <WaitingRoom />
          </Route>
          <Route path="/error">
            <SomethingWentWrong />
          </Route>
          <Route path="/">
            <Spyislandanimation />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
