import React, { useEffect, useState } from "react";
import UserMarker from "./UserMarker";
import GoogleMapReact from "google-map-react";
import correctLocationMarker from "./assets/images/correct-location.png";
import { setRoomThreeFinishedTrue } from "../../firebase/firebase";
import { database } from "../../firebase/firebaseConfig";

import { Redirect } from "react-router";
import { onValue, ref } from "@firebase/database";

const LocationMarker = ({ lat, lng, style }) => (
  <div lat={lat} lng={lng} style={style}>
    <img
      style={{
        display: "block",
        width: "100%",
      }}
      src={correctLocationMarker}
      alt="Correct location"
    />
  </div>
);

const Result = ({
  apiKey,
  correctLocation,
  userLocation,
  points,
  distance,
  isLastRound,
  setNextRound,
}) => {
  const [room3Finished, setroom3Finished] = useState(false);

  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));

  const config = {
    zoom: 1,
  };

  const handleApiLoaded = (map, maps) => {
    let lineCoordinates = [
      new maps.LatLng(correctLocation.lat, correctLocation.lng),
      new maps.LatLng(userLocation.lat, userLocation.lng),
    ];

    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      scale: 4,
    };

    let path = new maps.Polyline({
      path: lineCoordinates,
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "20px",
        },
      ],
    });

    path.setMap(map);
  };

  useEffect(() => {
    const dbRefRoomFinished = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room3/completed`,
    );

    //  checks on room Three is completed
    onValue(dbRefRoomFinished, (snapshot) => {
      const data = snapshot.val();
      setroom3Finished(data);
    });
  }, []);

  if (room3Finished) {
    return <Redirect to="roomThreeResult" />;
  }

  return (
    <>
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultZoom={config.zoom}
          defaultCenter={correctLocation}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          <LocationMarker
            lat={correctLocation.lat}
            lng={correctLocation.lng}
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              width: "30px",
              height: "30px",
            }}
          />
          <UserMarker
            lat={userLocation.lat}
            lng={userLocation.lng}
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              borderRadius: "50px",
              overflow: "hidden",
            }}
          />
        </GoogleMapReact>
      </div>
      <div className="geo__result-wrapper">
        <p className="geo__result-score">{points} points</p>
        <p className="geo__result-distance">
          Your guess was
          <span className="geo__result-distance-val">
            {Math.floor(distance)} KM
          </span>
          from the correct location.
        </p>
        {!isLastRound ? (
          <button
            onClick={setNextRound}
            className="geo__btn-cta geo__btn--center"
          >
            Play next round
          </button>
        ) : (
          <button
            onClick={() => {
              setRoomThreeFinishedTrue(gameCode);
            }}
            className="geo__btn-cta geo__btn--center"
          >
            See Your Results
          </button>
        )}
      </div>
    </>
  );
};

export default Result;
