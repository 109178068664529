import generateSymbolColumns from "./generateSymbolColumns";

const generateGame = (puzzle) => {
  let gameContent = {
    left: [],
    right: [],
  };

  const { symbols, specialSymbols, goalWord, words } = puzzle;

  const symbolsColumns = generateSymbolColumns(symbols);

  const generateGame = (symbolsColumns) => {
    let gameElements = {
      left: symbolsColumns.left.slice(),
      right: symbolsColumns.right.slice(),
    };

    addWords([goalWord, ...words], gameElements);
    addSpecialSymbols(specialSymbols, gameElements);

    return gameElements;
  };

  const addWords = (allWords, gameElements) => {
    allWords.forEach(({ word, position }) => {
      let wordArray = word.split("");
      let startIndex = position.index;
      let i = startIndex;
      let col = position.col.toLowerCase();

      wordArray.forEach((char) => {
        gameElements[col][i] = {
          type: "WORD",
          value: char,
          word: word,
        };
        i++;
      });
    });
  };

  const addSpecialSymbols = (specialSymbols, gameElements) => {
    specialSymbols.forEach(({ string, position, action }) => {
      let arr = string.split("");
      let startIndex = position.index;
      let i = startIndex;
      let col = position.col.toLowerCase();

      arr.forEach((char) => {
        gameElements[col][i] = {
          type: "SPECIAL_SYMBOL",
          string,
          value: char,
          action,
        };
        i++;
      });
    });
  };

  gameContent = generateGame(symbolsColumns);

  return gameContent;
};

export default generateGame;
