import React, { useEffect, useState } from "react";

import "./waitingRoom.styles.scss";

import { ref, onValue } from "firebase/database";
import { setGiveGameIntroTrue } from "../../firebase/firebase";
import { Redirect } from "react-router";
import { database } from "../../firebase/firebaseConfig";

function WaitingRoom() {
  const gameCode = JSON.parse(localStorage.getItem("GAME_CODE"));
  const warden = JSON.parse(localStorage.getItem("WARDEN"));

  const [giveGameIntro, setGiveGameIntro] = useState(false);

  const [team, setteam] = useState([]);

  useEffect(() => {
    const dbRefPlayersJoined = ref(database, `Rooms/${gameCode}/players`);

    onValue(dbRefPlayersJoined, (snapshot) => {
      setteam(snapshot.val());
    });
  }, []);

  useEffect(() => {
    const dbRefGameIntro = ref(
      database,
      `Rooms/${gameCode}/RoomStates/room1/gameIntro/introPlayed`,
    );

    onValue(dbRefGameIntro, (snapshot) => {
      setGiveGameIntro(snapshot.val());
    });
  }, []);

  if (giveGameIntro) {
    return <Redirect to="/gameIntro" />;
  }

  return (
    <>
      {warden ? (
        <div className={`waitingRoomHeader`}>
          <h1>
            GO TO: <span> SPYISLAND.RUNAWAY.GAMES</span>
          </h1>
          <h1>
            ENTER GAME CODE: <span>{gameCode}</span>
          </h1>
        </div>
      ) : (
        ""
      )}
      <div className="waitingRoom">
        <h1 className="waitingRoom__heading">SPY CANDIDATES</h1>

        <div className="playerNames">
          {team?.map((item, i) => {
            return <h1 key={Date.now + i}>{`${i + 1}. ${item}`}</h1>;
          })}
        </div>

        {warden ? (
          <button
            className="gameBeginBtn"
            onClick={() => {
              setGiveGameIntroTrue(gameCode);
            }}
          >
            Start
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default WaitingRoom;
