import React, { useState, useReducer, useEffect, useRef } from "react";
import code from "./assets/audio/code.mp3";
import cultureFMSpyIsland from "./assets/audio/cultureFM-spy-island.mp3";
import djJuanLatinPop from "./assets/audio/DJ-juan-latin-pop.mp3";
import HITPopFMSpyIslandRadio from "./assets/audio/HIT-popFM-spy-island-radio.mp3";
import houseFMSpyIsland from "./assets/audio/houseFM-spy-island.mp3";
import libertyJazzFM from "./assets/audio/liberty-jazzFM.mp3";
import professorKhansFinalMessage from "./assets/audio/professor-khans-final-message.mp3";
import radioAfrodita from "./assets/audio/radio-afrodita.mp3";
import radioHAMBURG from "./assets/audio/radio-HAMBURG.mp3";
import rocktoneRadio from "./assets/audio/rocktone-radio.mp3";
import spyBluesFM from "./assets/audio/spy-bluesFM.mp3";
import spyClassique from "./assets/audio/spy-classique.mp3";
import spyHacksWithSpySly from "./assets/audio/spy-hacks-with-spy-sly.mp3";
import truthRadioNewsSpyIsland from "./assets/audio/truth-radio-news-spy-island.mp3";
import yachtJamsFMSpyIsland from "./assets/audio/yacht-jamsFM-spy-island.mp3";
import "./assets/css/style.css";

import volUp from "../../assets/radio/volumeup.png";
import volDown from "../../assets/radio/volumedown.png";

const RadioPuzzle = () => {
  const audioFiles = [
    spyClassique,
    professorKhansFinalMessage,
    code,
    cultureFMSpyIsland,
    djJuanLatinPop,
    HITPopFMSpyIslandRadio,
    houseFMSpyIsland,
    libertyJazzFM,
    radioAfrodita,
    radioHAMBURG,
    rocktoneRadio,
    spyBluesFM,
    spyHacksWithSpySly,
    truthRadioNewsSpyIsland,
    yachtJamsFMSpyIsland,
  ];

  const [volume, setvolume] = useState(1);

  const [showSlider, setshowSlider] = useState(false);

  const audioRefs = useRef([]);
  const intervalRef = useRef();
  const durationRef = useRef(0);
  const [frequency, dispatch] = useReducer(frequencyReducer, {
    left: 1,
    right: 1,
  });
  const [turnOnRadio, setTurnOnRadio] = useState(false);

  function handleVolUp() {
    const newVolume = volume + 0.1;

    if (newVolume <= 1) setvolume(newVolume);
  }

  function handleVolDown() {
    const newVolume = volume - 0.1;

    if (newVolume >= 0) setvolume(newVolume);
  }

  function frequencyReducer(state, action) {
    let newState;
    const key = action.payload.key;

    switch (action.type) {
      case "INCREMENT":
        if (state[key] < 99) {
          newState = {
            ...state,
            [key]: state[key] + 1,
          };
        } else {
          newState = {
            ...state,
            [key]: 1,
          };
        }
        break;
      case "DECREMENT":
        if (state[key] > 1) {
          newState = {
            ...state,
            [key]: state[key] - 1,
          };
        } else {
          newState = {
            ...state,
            [key]: 99,
          };
        }
        break;
      default:
        newState = {
          ...state,
        };
    }
    return newState;
  }

  const setPower = (buttonType) => {
    if (buttonType === "ON") {
      setTurnOnRadio(true);
    } else {
      setTurnOnRadio(false);
    }
  };

  const generateRandomInt = (lower, upper) => {
    return Math.floor(Math.random() * (upper - 1 - lower + 1) + lower);
  };

  const addToRefs = (el) => {
    if (el && !audioRefs.current.includes(el)) {
      audioRefs.current.push(el);
    }
  };

  const handleMouseDown = (actionType, key) => {
    dispatch({
      type: actionType,
      payload: {
        key,
      },
    });

    intervalRef.current = setInterval(() => {
      durationRef.current = durationRef.current + 1;
      if (durationRef.current === 8) {
        durationRef.current = 0;
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
          durationRef.current = durationRef.current + 1;
          if (durationRef.current === 14) {
            durationRef.current = 0;
            clearInterval(intervalRef.current);
            intervalRef.current = setInterval(() => {
              dispatch({
                type: actionType,
                payload: {
                  key,
                },
              });
            }, 100);
          } else {
            dispatch({
              type: actionType,
              payload: {
                key,
              },
            });
          }
        }, 200);
      } else {
        dispatch({
          type: actionType,
          payload: {
            key,
          },
        });
      }
    }, 300);
  };

  const handleMouseUp = () => {
    if (turnOnRadio) {
      clearInterval(intervalRef.current);
      getRadioStation();
    }
  };

  const stopAudio = (playingAudioIndex) => {
    audioRefs.current.forEach((audio, index) => {
      if (playingAudioIndex !== index) {
        audio.currentTime = 0;
        audio.pause();
      }
    });
  };

  const getRadioStation = () => {
    if (turnOnRadio) {
      let audioIndex = 0;
      if (frequency.left === 1 && frequency.right === 1) {
        // Spy Classique
        audioIndex = 0;
      } else if (frequency.left === 42 && frequency.right === 12) {
        // Professor Khans Final Message
        audioIndex = 1;
      } else if (frequency.left === 34 && frequency.right === 86) {
        // Code
        audioIndex = 2;
      } else {
        // Rand
        const randIndex = generateRandomInt(4, 15);
        audioIndex = randIndex;
      }

      let playPromise = audioRefs.current[audioIndex].play();

      if (playPromise !== undefined) {
        playPromise.then((_) => {
          stopAudio(audioIndex);
        });
      }
    }
  };

  useEffect(() => {
    if (turnOnRadio) {
      let audioIndex = 0;
      if (frequency.left === 1 && frequency.right === 1) {
        // Spy Classique
        audioIndex = 0;
      } else {
        const randIndex = generateRandomInt(4, 15);
        audioIndex = randIndex;
      }
      let playPromise = audioRefs.current[audioIndex].play();

      if (playPromise !== undefined) {
        playPromise.then((_) => {
          stopAudio(audioIndex);
        });
      }
    } else {
      stopAudio();
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [turnOnRadio]);

  useEffect(() => {
    const audios = document.querySelectorAll(".audiofile");
    audios.forEach((aud) => (aud.volume = volume));

    setshowSlider(true);

    setTimeout(() => {
      setshowSlider(false);
    }, 1500);
  }, [volume]);

  return (
    <>
      {showSlider ? (
        <input
          type="range"
          min="1"
          max="100"
          value={volume * 100}
          class="slider"
          id="myRange"
          onChange={(e) => setvolume(e.target.value)}
        ></input>
      ) : (
        ""
      )}

      {
        <div className="volControls">
          <div className="slider__soundOn" onClick={handleVolUp}>
            <img className="slider__soundImg" src={volUp} alt="" />
          </div>
          <div className="slider__soundOff" onClick={handleVolDown}>
            <img className="slider__soundImg" src={volDown} alt="" />
          </div>
        </div>
      }

      <div className="rp-radio-wrapper">
        <div className="rp-radio">
          <div className="rp-controls">
            <div className="rp-controls--left">
              <div className="rp-controls__buttons">
                <button
                  className="rp-btn rp-controls__button"
                  onMouseDown={() => handleMouseDown("INCREMENT", "left")}
                  onMouseUp={handleMouseUp}
                  disabled={!turnOnRadio}
                >
                  <svg fill="none" stroke="#FFFFFF" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </button>
                <button
                  className="rp-btn rp-controls__button"
                  onMouseDown={() => handleMouseDown("DECREMENT", "left")}
                  onMouseUp={handleMouseUp}
                  disabled={!turnOnRadio}
                >
                  <svg fill="none" stroke="#FFFFFF" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20 12H4"
                    />
                  </svg>
                </button>
              </div>
              <div
                className={`rp-controls__frequency-display ${
                  turnOnRadio
                    ? "rp-controls__frequency-display--on"
                    : "rp-controls__frequency-display--off"
                }`}
              >
                <div className="rp-frequency">
                  {turnOnRadio
                    ? frequency.left.toString().padStart(2, "0")
                    : ""}
                </div>
              </div>
            </div>

            <div className="rp-power-buttons">
              <button
                className={`rp-btn rp-power-button rp-power-button--left ${
                  turnOnRadio
                    ? "rp-power-button--default"
                    : "rp-power-button--off"
                }`}
                onClick={() => setPower("OFF")}
              >
                off
              </button>
              <button
                className={`rp-btn rp-power-button rp-power-button--right ${
                  !turnOnRadio
                    ? "rp-power-button--default"
                    : "rp-power-button--on"
                }`}
                onClick={() => setPower("ON")}
              >
                on
              </button>
            </div>

            <div className="rp-controls--right">
              <div
                className={`rp-controls__frequency-display ${
                  turnOnRadio
                    ? "rp-controls__frequency-display--on"
                    : "rp-controls__frequency-display--off"
                }`}
              >
                <div className="rp-frequency">
                  {turnOnRadio
                    ? frequency.right.toString().padStart(2, "0")
                    : ""}
                </div>
              </div>
              <div className="rp-controls__buttons">
                <button
                  className="rp-btn rp-controls__button"
                  onMouseDown={() => handleMouseDown("INCREMENT", "right")}
                  onMouseUp={handleMouseUp}
                  disabled={!turnOnRadio}
                >
                  <svg fill="none" stroke="#FFFFFF" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </button>
                <button
                  className="rp-btn rp-controls__button"
                  onMouseDown={() => handleMouseDown("DECREMENT", "right")}
                  onMouseUp={handleMouseUp}
                  disabled={!turnOnRadio}
                >
                  <svg fill="none" stroke="#FFFFFF" viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M20 12H4"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {audioFiles.map((audio, i) => (
          <audio
            src={audio}
            className="audiofile"
            ref={addToRefs}
            type="audio/mpeg"
            key={`audio${i}`}
            preload="auto"
          />
        ))}
      </div>
    </>
  );
};

export default RadioPuzzle;
