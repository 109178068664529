import React, { useState, useEffect, useReducer, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const Feedback = ({
  entry,
  resetEntry,
  userInput,
  goalWord,
  attempts,
  currentPuzzle,
  handleAttempts,
  handleSolvedPuzzle,
  updateCurrentPuzzleState,
}) => {
  const delay = useRef();
  const [feedbackEffect, setFeedbackEffect] = useState({
    action: "",
  });
  const [isFound, setIsFound] = useState(false);
  const [feedback, dispatch] = useReducer(feedbackReducer, []);

  function feedbackReducer(state, action) {
    let newState = [];

    switch (action.type) {
      case "START":
        for (let i = 0; i < 15; i++) {
          newState.push(<br key={`br${i + 1}`} />);
        }
        break;
      case "SYMBOL":
        newState = [...state];
        newState.splice(0, 2);

        newState.push(
          <span key={uuidv4()} style={{ display: "block" }}>
            &gt; {action.payload.userInput}
          </span>,
          <span key={uuidv4()} style={{ display: "block" }}>
            &gt; Error
          </span>,
        );

        break;
      case "WORD":
        newState = [...state];

        if (action.payload.userInput !== goalWord) {
          const count = match(action.payload.userInput, goalWord);
          const goalWordLength = action.payload.goalWordLength;
          newState.splice(0, 3);

          newState.push(
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; {action.payload.userInput}
            </span>,
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; Entry Denied
            </span>,
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; {count}/{goalWordLength} correct
            </span>,
          );

          if (attempts > 0) {
            /*************************************************/
            if (feedbackEffect === "DECREASE") {
              setFeedbackEffect((state) => ({ ...state })); // re-render
            } else {
              setFeedbackEffect({
                action: "DECREASE",
              });
            }
            /*************************************************/
          }
        } else {
          newState.splice(0, 5);

          newState.push(
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; {userInput.value}
            </span>,
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; Exact match!
            </span>,
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; Please wait
            </span>,
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; While system
            </span>,
            <span key={uuidv4()} style={{ display: "block" }}>
              &gt; is accessed
            </span>,
          );

          setIsFound(true);
        }
        break;

      case "SPECIAL_SYMBOL":
        let specialStringAction = "";

        if (action.payload.action === "REMOVE_DUD") {
          specialStringAction = "Dud removed!";
        } else if (action.payload.action === "ATTEMPTS_REFILL") {
          specialStringAction = "New attempt added!";
        }

        newState = [...state];
        newState.splice(0, 2);

        newState.push(
          <span key={uuidv4()} style={{ display: "block" }}>
            &gt; {action.payload.userInput}
          </span>,
          <span key={uuidv4()} style={{ display: "block" }}>
            &gt; {specialStringAction}
          </span>,
        );
        break;
      default:
    }

    return newState;
  }

  function match(str1, str2) {
    let count = 0;
    const obj = str2.split("");
    for (let str of str1) {
      let idx = obj.findIndex((s) => s === str);
      if (idx >= 0) {
        count++;
        obj.splice(idx, 1);
      }
    }
    return count;
  }

  const evaluateUserInput = () => {
    if (userInput.value) {
      dispatch({
        type: userInput.type,
        payload: {
          userInput: userInput.value,
          goalWordLength: userInput.type === "WORD" ? goalWord.length : null,
          action: userInput.action ? userInput.action : null,
        },
      });
    }
  };

  useEffect(() => {
    handleAttempts(feedbackEffect.action);
  }, [feedbackEffect]);

  useEffect(() => {
    if (isFound) {
      delay.current = setInterval(() => {
        handleSolvedPuzzle();
        dispatch({
          type: "START",
        });
        resetEntry();
        setIsFound(false);
      }, 2000);
    }

    return () => {
      clearInterval(delay.current);
    };
  }, [isFound, attempts]);

  useEffect(() => {
    dispatch({
      type: "START",
    });
    resetEntry();
  }, [currentPuzzle]);

  useEffect(() => {
    evaluateUserInput();
  }, [userInput]);

  useEffect(() => {
    updateCurrentPuzzleState({
      feedbackLogs: [...feedback],
    });
  }, [feedback]);

  useEffect(() => {
    dispatch({
      type: "START",
    });
  }, []);

  return (
    <div className="hp__results hp__content">
      <p className="txt hp__feedback">{feedback}</p>
      <p>
        <br />
      </p>
      <p className="txt">
        <span className="hp__entry">&#160;&gt; {entry ? entry : ""}</span>
        <span className="hp__cursor-flash">█</span>
      </p>
    </div>
  );
};

export default Feedback;
