import { child, get, ref, set } from "firebase/database";
import randomatic from "randomatic";
import { database } from "../../firebase/firebaseConfig";

const checkIfRoomExists = async (teamCode) => {
  const dbRefCurrentTeam = ref(database);
  const snapshot = await get(child(dbRefCurrentTeam, `Rooms/${teamCode}`));

  if (snapshot.exists()) {
    return true;
  }
  return false;
};

const createNewTeam = async (teamCode, scheduledDate, scheduledTime) => {
  // check if room already exists
  if (await checkIfRoomExists(teamCode)) {
    return false;
  }

  // put data
  const dbRefNewTeam = ref(database, `Rooms/${teamCode}`);

  await set(dbRefNewTeam, {
    wardenEmail: "",
    players: [],
    state: {},
    gameStarted: false,
    scheduledDate,
    scheduledTime,
    totalTimeTaken: "",
    timeBonusPoints: "",
    overallScore: "",
    RoomStates: {
      room1: {
        inControl: 1,
        gameIntro: {
          introPlayed: false,
        },
        roomStatus: {
          room1Started: false,
        },
        logicGrid1: {
          levelCompleted: false,
          result: null,
          score: 0,
        },
        logicGrid2: {
          levelCompleted: false,
          result: null,
          score: 0,
        },
        logicGrid3: {
          levelCompleted: false,
          result: null,
          score: 0,
        },
        observationPuzzle: {
          instructionsWatched: false,
          currentQuestion: 0,
          currentAnsResult: false,
          showFeedback: false,
          score: 0,
          tookHelp: false,
          videoWatchedTwice: false,
          levelCompleted: false,
        },
        nextPuzzleChoice: {
          medium: false,
          veryHard: false,
        },
        startTime: null,
        finishTime: null,
        completed: false,
        totalScore: 0,
      },
      room2: {
        roomStarted: false,
        hackingPuzzleLeft: {
          inControl: 1,
          attempts: 3,
          solved: false,
          currentPuzzle: 1,
          showPuzzleSolved: false,
          showGlobalAttempts: false,
          levelCompleted: false,
          penalty: false,
          attemptsRefillsPuzzle1: 3,
          attemptsRefillsPuzzle2: 3,
          attemptsRefillsPuzzle3: 3,
        },
        hackingPuzzleRight: {
          inControl: 1,
          attempts: 3,
          solved: false,
          showPuzzleSolved: false,
          showGlobalAttempts: false,
          currentPuzzle: 1,
          levelCompleted: false,
          penalty: false,
          attemptsRefillsPuzzle1: 3,
          attemptsRefillsPuzzle2: 3,
          attemptsRefillsPuzzle3: 3,
        },
        secretHintUsed: false,
        startTime: null,
        finishTime: null,
        completed: false,
        totalScore: 0,
      },
      room3: {
        inControl: 1,
        roomStarted: false,
        result: {},
        totalScore: 0,
        round: 1,
        toRoom3Intro: false,
        startTime: null,
        finishTime: null,
        completed: false,
        showResult: false,
        showNextRound: false,
        showPassVideo: false,
        showFailVideo: false,
      },
    },
  });

  return true;
};

export const createRooms = async (roomCount, dateAndTime) => {
  let roomsCreated = 0;
  const roomCodes = [];

  const time = new Date(dateAndTime).getTime() / 1000;
  const date = dateAndTime.split("T")[0];

  while (roomsCreated < roomCount) {
    const roomCode = randomatic("A0", 5);

    // checkout if roomCode has Zeroes and O(s)
    if (roomCode.includes("0") || roomCode.includes("O")) continue;

    // IF ensures that the requried number of rooms are created and roomCode collisions are skipped;
    if (await createNewTeam(roomCode, date, time)) {
      roomsCreated++;
      roomCodes.push({ code: roomCode });
    }
  }

  return roomCodes;
};
