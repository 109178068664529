import React from "react";
const MapControls = ({
  activateMap,
  showControls,
  increaseSize,
  decreaseSize,
  handleStickyMap,
  handleMap,
  smallScreen,
  hideCloseMapBtn,
}) => {
  if (smallScreen && !hideCloseMapBtn) {
    return (
      <div className="geo__map-controls">
        <button
          onClick={handleMap}
          type="button"
          className="geo__map-control"
          style={{ background: "black" }}
        >
          <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiIGZpbGw9IiNGRkYiLz48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PC9zdmc+"
            alt="Sticky map"
          />
        </button>
      </div>
    );
  }

  if (activateMap && showControls) {
    return (
      <div className="geo__map-controls">
        <button
          onClick={increaseSize}
          type="button"
          className="geo__map-control geo__map-control--increase-size"
        >
          <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIj48cGF0aCBkPSJNNDYyIDI1Ni4wMDFMMzA3LjQzIDEwMS42OTN2MTE4LjMwMkg1MHY3Mi4wMWgyNTcuNDN2MTE4LjMwMnoiLz48L3N2Zz4="
            alt="Increase size"
          />
        </button>
        <button
          onClick={decreaseSize}
          type="button"
          className="geo__map-control geo__map-control--decrease-size"
        >
          <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIj48cGF0aCBkPSJNNDYyIDI1Ni4wMDFMMzA3LjQzIDEwMS42OTN2MTE4LjMwMkg1MHY3Mi4wMWgyNTcuNDN2MTE4LjMwMnoiLz48L3N2Zz4="
            alt="Decrease size"
          />
        </button>
        <button
          onClick={handleStickyMap}
          type="button"
          className="geo__map-control"
        >
          <img
            src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMyIiB3aWR0aD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIyIDExLjk5NlYyYzAtMS4xLS45LTItMi0yaC04Yy0xLjEgMC0yIC45LTIgMnY5Ljk5NkM3LjM4NiAxMy45MjMgNiAxNi43MyA2IDE4YzAgMS4yMTkgMS4wMTYgMiAyIDJoNnYxMGEyIDIgMCAwMDQgMFYyMGg2Yy45ODQgMCAyLS43ODEgMi0yIDAtMS4yNy0xLjM4Ni00LjA3Ny00LTYuMDA0ek0xMS41IDE2Yy40NTgtLjUyMSAxLjU0MS0xLjI5MiAyLjUtMlY0aDR2MTBjLjk1OS43MDggMi4wNDIgMS40NzkgMi41IDJoLTl6Ii8+PC9zdmc+"
            alt="Close map"
          />
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default MapControls;
